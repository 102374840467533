import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from 'material-ui';

const styles = (theme) => ({
  container: {
    paddingBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '2rem',
    },
  },
});

const Insurance = ({
  classes,
  fileName,
  title,
  height,
  maxWidth,
  width,
}) => {
  useEffect(() => { document.title = title; }, [title]);

  return (
    <Grid container className={classes.container} justify="center">
      <iframe title={title} src={`${window.location.origin}/${fileName}`} height={height} width={width} style={{ maxWidth }} />
    </Grid>
  );
};

Insurance.defaultProps = {
  fileName: 'Protection_Plan_Brochure.pdf',
  height: '100%',
  maxWidth: '1200px',
  title: 'Protection Plan',
  width: '100%',
}

Insurance.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fileName: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
};

export default withStyles(styles)(Insurance);
