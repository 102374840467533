import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { Button, Grid, Typography, withStyles } from 'material-ui';
import { Link } from 'react-router-dom';
import PhotoCard from '../../components/PhotoCard';
import Title from '../../components/Title';
import kioskPhoto from '../../images/photos/kiosk.jpg';
import movingPhoto from '../../images/photos/movingCouple.jpg';
import phonePhoto from '../../images/photos/phone.jpg';

const styles = (/* theme */) => ({
  pageContainer: {
    gap: '2em',
  },
});

const Specials = ({ classes, photoCards, title }) => {
  useEffect(() => { document.title = title; }, [title]);

  return (
    <Grid className={classes.pageContainer} container>
      <Title>
        <Typography type="display4">RED DOT STORAGE</Typography>
        <Typography type="display2">SPECIALS</Typography>
      </Title>
      <Grid item xs={12}>
        <div className="photo-cards-mosaic">
          <div className="photo-cards-mosaic-container">
            <div className="photo-cards-container">
              {photoCards.map((props) => <PhotoCard {...props} />)}
            </div>
          </div>
        </div>
      </Grid>
      <Grid className="footer" container justify="center">
        <Title>
          <Typography className="footerText" type="display2">THIS IS WORRY-FREE SELF STORAGE</Typography>
        </Title>
        <Link to="/selectFacility"><Button color="secondary" raised size="large">Our Locations</Button></Link>
      </Grid>
    </Grid>
  );
};

Specials.defaultProps = {
  title: 'Specials',
  photoCards: [
    {
      alt: 'Rental Kiosk',
      backgroundImage: kioskPhoto,
      key: 'MoveInSpecial',
      paragraphs: [
        'To help you get the best deal available, we offer move-in specials at select locations. Find the location near you for details. You can also visit our 24/7 self-service kiosks* or give us a call.',
        <em>*At most locations</em>,
      ],
      title: 'Move-In Special',
    },
    {
      alt: 'Couple moving a box',
      backgroundImage: movingPhoto,
      key: 'CustomerReferral',
      paragraphs: [
        "We appreciate your partnership with us and want to reward you for spreading the love. Refer a friend as a new customer and we'll give you both 50% off your next month's rent!",
      ],
      title: 'Customer Referral Program',
    },
    {
      alt: 'A customer on her phone',
      backgroundImage: phonePhoto,
      key: 'MilitaryDiscount',
      paragraphs: [
        'For our respected military personnel and veterans, we want to extend our thank you for your service and a 10% discount on monthly self storage rent.',
      ],
      title: 'Military Discount Program',
    },
  ],
}

Specials.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  photoCards: PropTypes.arrayOf(PropTypes.shape(PhotoCard.propTypes)),
  title: PropTypes.string,
};

export default withStyles(styles)(Specials);
