import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { TextField } from 'redux-form-material-ui';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { resetPasswordThunk } from '../reducer';
import { getResetError, getResetting } from '../selectors';
import { required } from '../../../utils/validations';
import { StrengthPasswordField } from '../../../components/Form/strengthPasswordField';

const styles = (theme) => ({
  field: {
    maxWidth: '100%',
    width: '300px',
  },
  paper: {
    position: 'absolute',
    padding: '16px',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  submitButton: {
    marginTop: 40,
  },
});

class ResetPasswordForm extends Component {
  onSubmit(values) {
    this.props.actions.resetPassword(values);
  }

  render() {
    const {
      resetError,
      handleSubmit,
      resetting,
      classes,
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="login-form">
          <div>
            <Field
              name="password"
              component={StrengthPasswordField}
            />
          </div>
          <div>
            <Field
              name="password_confirmation"
              component={TextField}
              label="Confirm new password"
              type="password"
              validate={[required]}
              className={classes.field}
            />
          </div>
          <div className={classes.submitButton}>
            <Button
              type="submit"
              disabled={resetting}
              raised
              color="secondary"
            >
              CHANGE MY PASSWORD
            </Button>
          </div>
          {resetError && <p className="app-error">{resetError}</p>}
        </form>
      </div>
    );
  }
}

ResetPasswordForm.defaultProps = {
  resetError: undefined,
  resetting: false,
}

ResetPasswordForm.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetError: PropTypes.node,
  resetting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  resetError: getResetError(state),
  resetting: getResetting(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    resetPassword: resetPasswordThunk,
  }, dispatch),
});

// Decorate with redux-form
export default reduxForm({
  form: 'loginForm',
})(withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm))));

