import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GeneralForm from '../../components/Form';
import { sendMessageThunk } from './reducer';
import {
  required as requiredValidation,
  email as emailValidation,
  minLength,
} from '../../utils/validations';
import Captcha from '../../components/Form/Captcha';
import { phoneMask, phoneFormat } from '../../constants';

const fields = [{
  fullWidth: true,
  label: 'Name',
  name: 'name',
  required: true,
  validate: requiredValidation,
}, {
  fullWidth: true,
  name: 'tel',
  label: 'Phone Number',
  mask: phoneMask,
  pipe: phoneFormat,
  validate: minLength(11, 'Phone Number must contain at least 10 digits', /\D/g),
}, {
  fullWidth: true,
  label: 'Email',
  name: 'email',
  required: true,
  validate: [requiredValidation, emailValidation],
}, {
  fullWidth: true,
  inputProps: { inputProps: { maxLength: 250 } },
  label: 'Message (250 character limit)',
  multiline: true,
  name: 'e-content',
  rows: 5,
}, {
  custom: (
    <Field
      className="marginDense"
      component={Captcha}
      key="g-recaptcha-response"
      name="g-recaptcha-response"
      sitekey="6LetBq8cAAAAAB8efZPz-OkOrgWk6BXhj9ZARM03"
      validate={requiredValidation}
    />
  ),
}];

const mapStateToProps = (/* state */) => ({
  buttonTitle: 'Send',
  fields,
  isCreate: true,
  margin: 'dense',
  shrinkLabels: true,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    create: sendMessageThunk,
  }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'acquisitionContactForm',
})(GeneralForm)));

