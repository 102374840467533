import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Drawer,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  Hidden,
  withStyles,
  Grid,
  Collapse,
} from 'material-ui';
import { withRouter } from 'react-router-dom';
import {
  ViewModule as ViewModuleIcon,
  ExpandMore,
  ExpandLess,
  AccountCircle,
  LocalOffer,
  QuestionAnswer,
  LiveHelp,
  Store,
  Security,
  AttachMoney,
  Note,
  CreditCard,
  History,
} from 'material-ui-icons';

import { setDrawerOpen } from '../../containers/AppWrapper/reducer';
import { getAppLocked, getDrawerOpen } from '../../containers/AppWrapper/selectors';
import { getLoggedIn } from '../../containers/Auth/selectors';
import { routePropTypes } from '../../utils/routes';
import './styles.css';

const drawerWidth = 240;

const menus = [{
  id: 'account',
  text: 'My Account',
  loggedIn: true,
  icon: <AccountCircle />,
  action: '/account',
}, {
  id: 'billing',
  text: 'Billing',
  loggedIn: true,
  icon: <AttachMoney />,
  handleClick() { this.setState({ billing: !this.state.billing }); },
  children: [{
    id: 'current-bill',
    text: 'Current Bill',
    icon: <Note />,
    action: '/billing',
  }, {
    id: 'payment-methods',
    text: 'Payment Methods',
    icon: <CreditCard />,
    action: '/billing/paymentMethods',
  }, {
    id: 'billing-history',
    text: 'Billing History',
    icon: <History />,
    action: '/billing/billingHistory',
  }],
}, {
  id: 'units',
  text: 'My Units',
  icon: <ViewModuleIcon />,
  action: '/units',
}, {
  id: 'specials',
  text: 'Specials',
  icon: <LocalOffer />,
  action: '/specials',
}, {
  id: 'insurance',
  text: 'Protection Plan',
  icon: <Security />,
  action: '/protection-plan',
}, {
  id: 'faq',
  text: 'FAQ / Tips',
  icon: <QuestionAnswer />,
  action: '/faq-tips',
}, {
  id: 'contact-us',
  text: 'Contact Us',
  icon: <LiveHelp />,
  action: '/contact-us',
}, {
  id: 'acquisiton',
  text: 'Sell Your Facility',
  icon: <Store />,
  action: '/contact-us/sell-your-facility',
}];

const styles = theme => ({
  drawerPaper: {
    width: 250,
    height: 'auto',
    top: 0,
    zIndex: 1000,
    marginTop: 48,
    maxHeight: 'calc(100% - 48px)',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      marginTop: 64,
      maxHeight: 'calc(100% - 64px)',
    },
    backgroundColor: theme.backgroundColor,
  },
  drawer: {
    flexGrow: 1,
  },
});


class ResponsiveDrawer extends React.Component {
  state = {
    selected: null,
    about: false,
    billing: false,
  };

  componentWillReceiveProps(props) {
    const state = { selected: props.path };
    if (!props.drawerOpen) state.about = false;
    state.billing = state.selected.startsWith('/billing');

    this.setState(state);
  }

  renderMenuItem = (option) => {
    const { appLocked, loggedIn } = this.props;
    const selected = option.action === this.state.selected;
    return (
      <div key={option.id}>
        <MenuItem
          component={option.url && 'a'}
          disabled={appLocked || (option.loggedIn && !loggedIn)}
          href={option.url}
          selected={selected}
          target={option.url && (option.target || 'blank')}
          onClick={() => {
            if (option.action) {
              this.props.history.push(option.action);
              this.props.actions.setDrawerOpen(false);
            }
            if (option.handleClick) {
              option.handleClick.call(this);
            }
          }}
          className={`drawer-menu-item-${selected}`}
        >
          {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
          <ListItemText inset={!!option.icon} primary={option.text} />
          {option.children && (this.state[option.id] ? <ExpandLess /> : <ExpandMore />)}
        </MenuItem>
        {option.children &&
          <Collapse in={this.state[option.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className="child-menu">
              {option.children.map((childOpt) => this.renderMenuItem(childOpt))}
            </List>
          </Collapse>
        }
      </div>
    );
  }
  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <Grid container direction="column" justify="space-between" spacing={0} className={classes.drawer}>
        <Grid item>
          <List>
            {menus.map((option) => this.renderMenuItem(option))}
          </List>
        </Grid>
      </Grid>
    );

    return (
      <Hidden mdUp>
        <Drawer
          type="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={this.props.drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={() => this.props.actions.setDrawerOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    );
  }
}

ResponsiveDrawer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  appLocked: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool,
  loggedIn: PropTypes.bool,
  path: PropTypes.string,
  theme: PropTypes.object.isRequired,
  ...routePropTypes,
};

ResponsiveDrawer.defaultProps = {
  appLocked: false,
  drawerOpen: false,
  loggedIn: false,
  path: '/',
}

const mapStateToProps = (state, ownProps) => ({
  appLocked: getAppLocked(state),
  drawerOpen: getDrawerOpen(state),
  loggedIn: getLoggedIn(state),
  path: ownProps.location.pathname,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setDrawerOpen }, dispatch),
});

const AppDrawer = connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer)
export default withRouter(withStyles(styles, { withTheme: true })(AppDrawer))
