import { fromJS, List } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { stopSubmit } from 'redux-form';
import { goBack, push } from 'react-router-redux';

import { appMsg, setReturnUrl } from '../AppWrapper/reducer';
import {
  createAccountProfile,
  updateAccountProfile,
  createAlternate,
  updateAlternate,
  deleteAlternate,
  createAddress,
  updateAddress,
  deleteAddress,
  createContact,
  updateContact,
  deleteContact,
  updatePassword,
  fetchVehicles,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  fetchCustomerInsurance,
  createCustomerInsurance,
  updateCustomerInsurance,
  deleteCustomerInsurance,
} from '../../services/account';
import { getAccountId, getTouchedAddresses, getTouchedContacts } from '../Auth/selectors';
import { initialState } from '../Auth/reducer';
import { getVehicle, getVehicleByOccupancyId, getVehicles } from './selectors';

export const constants = {
  ACCOUNT_PROFILE_SUCCESS: 'containers/Account/ACCOUNT_PROFILE_SUCCESS',
  UPDATE_PROFILE: 'containers/Account/ACCOUNT_UPDATE_PROFILE',
  CREATE_ADDRESS_SUCCESS: 'containers/Account/CREATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_SUCCESS: 'containers/Account/UPDATE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_SUCCESS: 'containers/Account/DELETE_ADDRESS_SUCCESS',
  CREATE_CONTACT_SUCCESS: 'containers/Account/CREATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_SUCCESS: 'containers/Account/UPDATE_CONTACT_SUCCESS',
  DELETE_CONTACT_SUCCESS: 'containers/Account/DELETE_CONTACT_SUCCESS',
  CREATE_ALTERNATE_SUCCESS: 'containers/Account/CREATE_ALTERNATE_SUCCESS',
  UPDATE_ALTERNATE_SUCCESS: 'containers/Account/UPDATE_ALTERNATE_SUCCESS',
  DELETE_ALTERNATE_SUCCESS: 'containers/Account/DELETE_ALTERNATE_SUCCESS',
  UPDATE_VEHICLE: 'containers/Account/UPDATE_VEHICLE',
  UPDATE_VEHICLE_BY_INDEX: 'containers/Account/UPDATE_VEHICLE_BY_INDEX',
  CLEAR_UNSAVED_VEHICLES: 'containers/Account/CLEAR_UNSAVED_VEHICLES',
  FETCH_VEHICLES_SUCCESS: 'containers/Account/FETCH_VEHICLES_SUCCESS',
  CREATE_VEHICLE_SUCCESS: 'containers/Account/CREATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_SUCCESS: 'containers/Account/UPDATE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_SUCCESS: 'containers/Account/DELETE_VEHICLE_SUCCESS',
  CREATE_INSURANCE_SUCCESS: 'containers/Account/CREATE_INSURANCE_SUCCESS',
  UPDATE_INSURANCE_SUCCESS: 'containers/Account/UPDATE_INSURANCE_SUCCESS',
  DELETE_INSURANCE_SUCCESS: 'containers/Accounts/DELETE_INSURANCE_SUCCESS',
  TOUCH_ADDRESS: 'containers/Account/ADDRESS_TOUCHED',
  TOUCH_CONTACT: 'containers/Account/CONTACT_TOUCHED',
  CLEAR_TOUCHED: 'containers/Account/CLEAR_TOUCHED',
  UPDATE_FETCH_STATUS: 'containers/Account/UPDATE_FETCH_STATUS',
};

/* Actions */
export const accountProfileSuccess = createAction(constants.ACCOUNT_PROFILE_SUCCESS);
export const updateProfile = createAction(constants.UPDATE_PROFILE);
export const createAddressSuccess = createAction(constants.CREATE_ADDRESS_SUCCESS);
export const updateAddressSuccess = createAction(constants.UPDATE_ADDRESS_SUCCESS);
export const deleteAddressSuccess = createAction(constants.DELETE_ADDRESS_SUCCESS);
export const createContactSuccess = createAction(constants.CREATE_CONTACT_SUCCESS);
export const updateContactSuccess = createAction(constants.UPDATE_CONTACT_SUCCESS);
export const deleteContactSuccess = createAction(constants.DELETE_CONTACT_SUCCESS);
export const createAlternateSuccess = createAction(constants.CREATE_ALTERNATE_SUCCESS);
export const updateAlternateSuccess = createAction(constants.UPDATE_ALTERNATE_SUCCESS);
export const deleteAlternateSuccess = createAction(constants.DELETE_ALTERNATE_SUCCESS);
export const updateVehicleById = createAction(constants.UPDATE_VEHICLE);
export const updateVehicleByIndex = createAction(constants.UPDATE_VEHICLE_BY_INDEX);
export const clearUnsavedVehicles = createAction(constants.CLEAR_UNSAVED_VEHICLES);
export const fetchVehiclesSuccess = createAction(constants.FETCH_VEHICLES_SUCCESS);
export const createVehicleSuccess = createAction(constants.CREATE_VEHICLE_SUCCESS);
export const updateVehicleSuccess = createAction(constants.UPDATE_VEHICLE_SUCCESS);
export const deleteVehicleSuccess = createAction(constants.DELETE_VEHICLE_SUCCESS);
export const createInsuranceSuccess = createAction(constants.CREATE_INSURANCE_SUCCESS);
export const updateInsuranceSuccess = createAction(constants.UPDATE_INSURANCE_SUCCESS);
export const deleteInsuranceSuccess = createAction(constants.DELETE_INSURANCE_SUCCESS);
export const addressTouched = createAction(constants.TOUCH_ADDRESS);
export const contactTouched = createAction(constants.TOUCH_CONTACT);
export const clearTouched = createAction(constants.CLEAR_TOUCHED);
export const updateFetchStatus = createAction(constants.UPDATE_FETCH_STATUS);

/* Thunks */
export const createAccountProfileThunk = (input, next) => {
  const { confirm_password: _, ...data } = input;
  return (dispatch) => createAccountProfile(data)
    .then(({ error, result }) => {
      if (error) {
        dispatch(stopSubmit('profileForm', error.error));

        // If just checking to see if User exists...
        if (!data.password) {
          if (error.error.password && !error.error.email) {
            // If only error is missing password then User does not exist. We can continue safely.
            dispatch(updateProfile(data));
            if (next) dispatch(push(next.url, next.state));
            return result;
          } else if (error.error.email && error.error.email === 'Already taken') {
            // If User does exist then redirect to login.
            dispatch(updateProfile(data));
            dispatch(appMsg('Our records indicate you already have an account! Please login below to continue.'));
            const state = (next && next.state) || {};
            dispatch(setReturnUrl({
              url: window.location.pathname,
              state: { ...state, skippable: true },
            }));
            dispatch(push('/'));
            return result;
          }
        }

        throw new Error(error.message);
      } else {
        window.gtag('event', 'sign_up', result.data);
        dispatch(appMsg('Account has been created!', 'success'));
        return result;
      }
    });
}
export const updateAccountProfileThunk = (data, next) =>
  (dispatch) => updateAccountProfile(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('profileForm', error));
        throw new Error(error);
      } else {
        dispatch(updateProfile(result));
        if (next) {
          dispatch(push(next.url, next.state));
        } else {
          dispatch(appMsg('Account has been updated!', 'success'));
        }
      }
    });

export const updateAccountSettingsThunk = (data) =>
  (dispatch) => updatePassword(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('passwordResetForm', error));
        throw new Error(error);
      } else {
        dispatch(accountProfileSuccess(result));
        dispatch(appMsg('Password has been updated!', 'success'));
        dispatch(push('/account'));
      }
    });

/* Customer Insurance */
export const createCustomerInsuranceThunk = (data) =>
  (dispatch, getState) => createCustomerInsurance({
    ...data,
    account_id: getAccountId(getState()),
  }).then(({ result, error }) => {
    if (error) {
      dispatch(appMsg('Error saving vehicle insurance', 'error'));
      return Promise.reject(error);
    }

    dispatch(createInsuranceSuccess(result));
    return result;
  });

export const createCustomerInsuranceFormThunk = ({ redirect, ...data }) =>
  (dispatch) => createCustomerInsurance(data).then(({ result, error }) => {
    if (error) {
      dispatch(stopSubmit('vehicleInsuranceForm', error));
      throw new Error(error);
    } else {
      dispatch(createInsuranceSuccess(result));
      if (redirect) dispatch(push('/account'));
    }

    return result;
  });

export const updateCustomerInsuranceThunk = (data) =>
  (dispatch) => updateCustomerInsurance(data).then(({ result, error }) => {
    if (error) {
      dispatch(appMsg('Error saving vehicle insurance', 'error'));
      return Promise.reject(error);
    }

    dispatch(updateInsuranceSuccess(result));
    return result;
  });

export const updateCustomerInsuranceFormThunk = (data) =>
  (dispatch) => updateCustomerInsurance(data).then(({ result, error }) => {
    if (error) {
      dispatch(stopSubmit('vehicleInsuranceForm', error));
      throw new Error(error);
    } else {
      dispatch(updateInsuranceSuccess(result));
      dispatch(push('/account'));
    }

    return result;
  });

export const assignCustomerInsuranceThunk = (data) =>
  (dispatch) => updateVehicle(data).then(({ result, error }) => {
    if (error) {
      dispatch(appMsg('Error saving vehicle insurance', 'error'));
      return Promise.reject(error);
    }

    dispatch(updateVehicleSuccess(result));
    return result;
  })

export const deleteCustomerInsuranceThunk = (data) =>
  (dispatch) => deleteCustomerInsurance(data)
    .then(({ result, error }) => {
      if (error) {
        throw new Error(error);
      } else {
        dispatch(deleteInsuranceSuccess(data));
      }

      return result;
    });

/* Vehicles */
export const addVehicleThunk = (data) =>
  (dispatch) => {
    dispatch(createVehicleSuccess({ ...data, occupancy_id: data.occupancy_id === 'NO_UNIT' ? null : data.occupancy_id }));
    return Promise.resolve(data);
  };

export const createVehicleThunk = (data) =>
  (dispatch) => createVehicle({ ...data, occupancy_id: data.occupancy_id === 'NO_UNIT' ? null : data.occupancy_id }).then(({ result, error }) => {
    if (error) {
      dispatch(stopSubmit('vehicleForm', error));
      throw new Error(error);
    } else {
      dispatch(createVehicleSuccess(result));
      dispatch(goBack());
    }

    return result;
  });
export const updateVehicleThunk = (data) =>
  (dispatch) => updateVehicle(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('vehicleForm', error));
        throw new Error(error);
      } else {
        dispatch(updateVehicleSuccess(result));
        dispatch(push('/account'));
      }

      return result;
    });
export const updateVehicleOccupancyThunk = (data) =>
  (dispatch, getState) => updateVehicle(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('vehicleOccupancyForm', error));
        throw new Error(error);
      } else {
        const prevVehicle = getVehicleByOccupancyId(getState(), data.occupancy_id);
        if (prevVehicle) {
          const newVehicle = getVehicle(getState(), data.id);
          // If Vehicle associated with Occupancy has changed...
          if (prevVehicle !== newVehicle) {
            const updatedVehicle = prevVehicle.toJS();
            updatedVehicle.occupancy_id = newVehicle.get('occupancy_id');
            dispatch(updateVehicleSuccess(updatedVehicle));
          }
        }
        dispatch(updateVehicleSuccess(result));
      }

      return result;
    });
export const deleteVehicleThunk = (data) =>
  (dispatch) => deleteVehicle(data)
    .then(({ result, error }) => {
      if (error) {
        throw new Error(error);
      } else {
        dispatch(deleteVehicleSuccess(data));
      }

      return result;
    });

/* Alternates */
export const createAlternateThunk = (data) =>
  (dispatch) => createAlternate(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('alternateForm', error));
        throw new Error(error);
      } else {
        dispatch(createAlternateSuccess(result));
        dispatch(push('/account'));
      }
    });
export const updateAlternateThunk = (data) =>
  (dispatch) => updateAlternate(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('alternateForm', error));
        throw new Error(error);
      } else {
        dispatch(updateAlternateSuccess(result));
        dispatch(push('/account'));
      }
    });
export const deleteAlternateThunk = (data) =>
  (dispatch) => deleteAlternate(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('alternateForm', error));
        throw new Error(error);
      } else {
        dispatch(deleteAlternateSuccess(result));
      }
    });

/* Addresses */
export const createAddressThunk = (data, next = { url: '/account' }) =>
  (dispatch) => createAddress(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('addressForm', error));
        throw new Error(error);
      } else {
        dispatch(createAddressSuccess(result));
        if (next) dispatch(push(next.url, next.state));
      }
    });
export const createSavedAddress = (dispatch, data) =>
  createAddress(data)
    .then(({ result, error }) => {
      if (error) {
        throw new Error(error);
      }
      dispatch(updateAddressSuccess(result));
    });
export const updateAddressThunk = (data, next = { url: '/account' }) =>
  (dispatch) => updateAddress(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('addressForm', error));
        throw new Error(error);
      } else {
        dispatch(updateAddressSuccess(result));
        if (next) dispatch(push(next.url, next.state));
      }
    });
export const updateSavedAddress = (dispatch, data) =>
  updateAddress(data)
    .then(({ result, error }) => {
      if (error) {
        throw new Error(error);
      }
      dispatch(updateAddressSuccess(result));
    });
export const deleteAddressThunk = (data) =>
  (dispatch) => deleteAddress(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('addressForm', error));
        throw new Error(error);
      } else {
        dispatch(deleteAddressSuccess(result));
      }
    });

/* Address + Contact */
export const setAddressContactThunk = (data, next) =>
  (dispatch) => {
    const {
      address,
      addressExists,
      contact,
      contactExists,
    } = data;
    dispatch(addressExists ? updateAddressSuccess(address) : createAddressSuccess(address));
    dispatch(addressTouched(address));

    dispatch(contactExists ? updateContactSuccess(contact) : createContactSuccess(contact));
    dispatch(contactTouched(contact));

    if (next) dispatch(push(next.url, next.state));
    return Promise.resolve();
  };

/* Contacts */
export const createContactThunk = (data, next = { url: '/account' }) =>
  (dispatch) => createContact(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('contactForm', error));
        throw new Error(error);
      } else {
        dispatch(createContactSuccess(result));
        if (next) dispatch(push(next.url, next.state));
      }
    });
export const createSavedContact = (dispatch, data) =>
  createContact(data)
    .then(({ result, error }) => {
      if (error) {
        throw new Error(error);
      }
      dispatch(updateContactSuccess(result));
    });
export const updateContactThunk = (data, next = { url: '/account' }) =>
  (dispatch) => updateContact(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('contactForm', error));
        throw new Error(error);
      } else {
        dispatch(updateContactSuccess(result));
        if (next) dispatch(push(next.url, next.state));
      }
    });
export const updateSavedContact = (dispatch, data) =>
  updateContact(data)
    .then(({ result, error }) => {
      if (error) {
        throw new Error(error);
      }
      dispatch(updateContactSuccess(result));
    });
export const deleteContactThunk = (data) =>
  (dispatch) => deleteContact(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('contactForm', error));
        throw new Error(error);
      } else {
        dispatch(deleteContactSuccess(result));
      }
    });

export const transactSavedData = (dispatch, getState) => {
  const state = getState();
  const addresses = getTouchedAddresses(state);
  const contacts = getTouchedContacts(state);
  const vehicles = getVehicles(state);

  let actions = Promise.resolve();
  if (addresses.size > 0) {
    actions = actions.then(() => Promise.all(addresses.toJS().map((address) => {
      if (address.id === 'new') {
        const { id, ...fields } = address;
        return createSavedAddress(dispatch, fields);
      }
      return updateSavedAddress(dispatch, address);
    })));
  }
  if (contacts.size > 0) {
    actions = actions.then(() => Promise.all(contacts.toJS().map((contact) => {
      if (contact.id === 'new') {
        const { id, ...fields } = contact;
        return createSavedContact(dispatch, fields);
      }
      return updateSavedContact(dispatch, contact);
    })));
  }
  if (vehicles && vehicles.size > 0) {
    const accountId = getAccountId(state);
    actions = actions.then(() => Promise.all(vehicles.toJS().map((vehicle, index) =>
      (vehicle.id ? Promise.resolve() : createVehicle({
        ...vehicle,
        owner_id: accountId,
      }).then(({ result }) => {
        if (result) dispatch(updateVehicleByIndex({ index, data: result }));
      })))));
  }

  return actions.then(() => {
    dispatch(clearTouched());
    dispatch(clearUnsavedVehicles());
  });
};
export const transactSavedDataThunk = () => transactSavedData;

export const fetchVehiclesThunk = (id) =>
  (dispatch) => {
    dispatch(updateFetchStatus({ type: 'vehicles', status: true }));
    Promise.all([fetchVehicles(id), fetchCustomerInsurance(id)]).then((results) => {
      dispatch(fetchVehiclesSuccess(results.map(({ result, error }, index) => {
        if (error) {
          dispatch(appMsg(`Error retrieving ${index === 0 ? 'vehicles' : 'vehicle insurance'}`, 'error'));
        }
        return result || [];
      })));
    });
  }

/* Reducer */
export default handleActions({
  [accountProfileSuccess]: (state, action) => state
    .set('userInfo', fromJS(action.payload)),

  [updateProfile]: (state, action) => state
    .mergeDeep({ userData: action.payload }),

  [addressTouched]: (state, action) => state
    .setIn(['touched', 'addresses', action.payload.id], true),

  [contactTouched]: (state, action) => state
    .setIn(['touched', 'contacts', action.payload.id], true),

  [clearTouched]: (state) => state
    .set('touched', initialState.get('touched')),

  [createAddressSuccess]: (state, action) => state
    .mergeDeep({ userInfo: { addresses: [] } })
    .updateIn(['userInfo', 'addresses'], arr => {
      let newArr = arr;
      if (action.payload.default) {
        const originalDefaultIndex = newArr.findIndex(s => s.get('default'));
        if (originalDefaultIndex >= 0) {
          newArr = newArr.setIn([originalDefaultIndex, 'default'], false);
        }
      }
      return newArr.push(fromJS(action.payload));
    }),

  [updateAddressSuccess]: (state, action) => state
    .updateIn(['userInfo', 'addresses'], arr => {
      let newArr = arr;
      if (action.payload.default) {
        const originalDefaultIndex = newArr.findIndex(s => s.get('default'));
        if (originalDefaultIndex >= 0) {
          newArr = newArr.setIn([originalDefaultIndex, 'default'], false);
        }
      }
      let uIndex = newArr.findIndex(s => s.get('id') === action.payload.id);
      if (uIndex < 0) uIndex = newArr.findIndex(s => s.get('id') === 'new');
      return newArr.set(uIndex, fromJS(action.payload));
    }),

  [deleteAddressSuccess]: (state, action) => {
    const deletedAddressIndex = state.getIn(['userInfo', 'addresses']).findIndex(s => s.get('id') === action.payload.id)
    return state
      .deleteIn(['userInfo', 'addresses', deletedAddressIndex]);
  },

  [createContactSuccess]: (state, action) => state
    .mergeDeep({ userInfo: { contacts: [] } })
    .updateIn(['userInfo', 'contacts'], arr => {
      let newArr = arr;
      if (action.payload.default) {
        const originalDefaultIndex = newArr.findIndex(s => s.get('default'));
        if (originalDefaultIndex >= 0) {
          newArr = newArr.setIn([originalDefaultIndex, 'default'], false);
        }
      }
      return newArr.push(fromJS(action.payload));
    }),

  [updateContactSuccess]: (state, action) => state
    .updateIn(['userInfo', 'contacts'], arr => {
      let newArr = arr;
      if (action.payload.default) {
        const originalDefaultIndex = newArr.findIndex(s => s.get('default'));
        if (originalDefaultIndex >= 0) {
          newArr = newArr.setIn([originalDefaultIndex, 'default'], false);
        }
      }
      let uIndex = newArr.findIndex(s => s.get('id') === action.payload.id);
      if (uIndex < 0) uIndex = newArr.findIndex(s => s.get('id') === 'new');
      return newArr.set(uIndex, fromJS(action.payload));
    }),

  [deleteContactSuccess]: (state, action) => {
    const deletedContactIndex = state.getIn(['userInfo', 'contacts']).findIndex(s => s.get('id') === action.payload.id)
    return state
      .deleteIn(['userInfo', 'contacts', deletedContactIndex]);
  },

  [createAlternateSuccess]: (state, action) => state
    .updateIn(['userInfo', 'alternates'], arr => arr.push(fromJS(action.payload))),

  [updateAlternateSuccess]: (state, action) => state
    .updateIn(['userInfo', 'alternates'], arr => {
      const uIndex = arr.findIndex(s => s.get('id') === action.payload.id)
      return arr.set(uIndex, fromJS(action.payload))
    }),

  [deleteAlternateSuccess]: (state, action) => {
    const deletedAlternateIndex = state.getIn(['userInfo', 'alternates']).findIndex(s => s.get('id') === action.payload.id)
    return state
      .deleteIn(['userInfo', 'alternates', deletedAlternateIndex]);
  },

  [createVehicleSuccess]: (state, action) => state
    .updateIn(['vehicles'], (list) => (list ? list.push(fromJS(action.payload)) : new List([fromJS(action.payload)]))),

  [updateVehicleSuccess]: (state, action) => state
    .updateIn(['vehicles'], (list) => {
      if (!list) return list;
      const index = list.findIndex(v => v.get('id') === action.payload.id);
      return index >= 0 ? list.set(index, fromJS(action.payload)) : list;
    }),

  [deleteVehicleSuccess]: (state, action) => state
    .updateIn(['vehicles'], (list) => {
      if (!list) return list;
      const index = list.findIndex(v => v.get('id') === action.payload);
      return index >= 0 ? list.delete(index) : list;
    }),

  [createInsuranceSuccess]: (state, action) => state
    .updateIn(['customerInsurance'], (list) => (list ? list.push(fromJS(action.payload)) : new List([fromJS(action.payload)]))),

  [updateInsuranceSuccess]: (state, action) => state
    .updateIn(['customerInsurance'], (list) => {
      if (!list) return list;
      const index = list.findIndex(v => v.get('id') === action.payload.id);
      return index >= 0 ? list.set(index, fromJS(action.payload)) : list;
    }),

  [deleteInsuranceSuccess]: (state, action) => state
    .updateIn(['customerInsurance'], (list) => {
      if (!list) return list;
      const index = list.findIndex(v => v.get('id') === action.payload);
      return index >= 0 ? list.delete(index) : list;
    }),

  [updateVehicleByIndex]: (state, action) => state
    .updateIn(['vehicles'], list => list && list.set(action.payload.index, fromJS(action.payload.data))),

  [updateVehicleById]: (state, action) => state
    .updateIn(['vehicles'], list => list && list.map(v => (v.get('id') === action.payload.id ? v.mergeDeep(action.payload) : v))),

  [clearUnsavedVehicles]: (state) => state
    .updateIn(['vehicles'], (list) => {
      const newList = list && list.filter(v => v.has('id'));
      return newList && newList.size < list.size ? newList : list;
    }),

  [updateFetchStatus]: (state, action) => state
    .setIn(['fetching', action.payload.type], action.payload.status),

  [fetchVehiclesSuccess]: (state, action) => state
    .update('vehicles', list => (list ? fromJS(action.payload[0]).concat(list) : fromJS(action.payload[0])))
    .update('customerInsurance', list => (list ? fromJS(action.payload[1]).concat(list) : fromJS(action.payload[1])))
    .setIn(['fetching', 'vehicles'], false)
    .setIn(['loaded', 'vehicles'], true),

}, initialState)
