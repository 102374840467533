import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { result } from 'lodash';
import { Typography } from 'material-ui';

import SelectDate from '../../../components/SelectDate';
import Loading from '../../../components/Loading';
import { createReservationThunk } from '../reducer';
import { getUnit, getFacility, getReservation } from '../selectors';

const bodyText1 = `Please click on the date that you would like to start your rental,
then press the "Reserve" button below to confirm your reservation.`;
const bodyText2 = 'Please click the "Reserve" button below to confirm your reservation.';

const SelectDateContainer = ({
  actions,
  facility,
  history,
  pageTitle,
  reservation,
  unit,
}) => {
  const [state, setState] = useState({
    secondaryButton: null,
    secondaryButtonText: '',
  });

  useEffect(() => {
    // If reservation already exists on load, immediately reserve and move on
    if (reservation && !reservation.get('confirmation')) {
      actions.reserve(reservation.toJS());
    }
  }, []);

  useEffect(() => { document.title = pageTitle; }, [pageTitle]);

  const onReserve = useCallback((date) => {
    actions.reserve({
      facility_id: unit.facility_id,
      unit_id: unit.id,
      move_in_date: date.format('YYYY-MM-DD'),
      promotion_id: unit.promotion_id,
    })
  }, [actions.reserve, unit]);

  const onMoveIn = useCallback(() => {
    history.push(`/reservation/${unit.id}/moveIn`);
  }, [history, unit]);

  const acceptButton = useMemo(() => ({
    text: 'Reserve',
    onClick: onReserve,
  }), [onReserve]);

  const currentDateButton = useMemo(() => ({
    text: 'Move-In',
    onClick: onMoveIn,
  }), [onMoveIn]);

  const onChange = useCallback((date) => {
    const isToday = moment().isSame(date, 'day');
    setState(isToday ? {
      secondaryButton: currentDateButton,
      secondaryButtonText: 'Looking to Move-In Right Now?',
    } : {
      secondaryButton: null,
      secondaryButtonText: '',
    });
  }, [currentDateButton]);

  const title = useMemo(() => (
    <Typography type="title" gutterBottom color="textSecondary">
      {`${facility.default_address.city} Store #${facility.store_number}`}
    </Typography>
  ), [facility]);

  return reservation ? <Loading /> : (
    <SelectDate
      acceptButton={acceptButton}
      bodyText={bodyText1}
      selectedBodyText={bodyText2}
      headerText="Move-In Date"
      maxSelectableDays={7}
      onChange={onChange}
      title={title}
      {...state}
    />
  );
}

SelectDateContainer.defaultProps = {
  pageTitle: 'Reserve Unit',
  reservation: null,
};

SelectDateContainer.propTypes = {
  actions: PropTypes.shape({
    reserve: PropTypes.func,
  }).isRequired,
  facility: PropTypes.shape({
    default_address: PropTypes.shape({
      city: PropTypes.string,
    }),
    store_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  history: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  reservation: PropTypes.object,
  unit: PropTypes.shape({
    id: PropTypes.string,
    facility_id: PropTypes.string,
    promotion_id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  unit: result(getUnit(state), 'toJS', null),
  facility: result(getFacility(state), 'toJS', null),
  reservation: getReservation(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    reserve: createReservationThunk,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectDateContainer);
