import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

export const getBilling = (state) => state.containers.billingReducer
export const getMyUnits = (state) => state.containers.unitsReducer
export const getLoaded = (state) => getMyUnits(state).get('loaded');
export const getLoading = (state) => getMyUnits(state).get('loading');
export const getUpdating = (state) => getMyUnits(state).get('updating');
export const getError = (state) => getMyUnits(state).get('error');
export const getAllFacilities = (state) => getMyUnits(state).get('data');
export const getFacilities = createSelector(
  getAllFacilities,
  (facilities) => (facilities || new List()).filter(f => f.get('units').size > 0),
);
export const getOccupiedFacilities = createSelector(
  getAllFacilities,
  (facilities) => (facilities || new List()).filter(f => f.get('units').filter(u => u.get('system_status') !== 'reserved').size > 0),
);
export const getOccupiedUnits = createSelector(
  getAllFacilities,
  (facilities) => new List((facilities || new List()).reduce((units, f) =>
    units.concat(f.get('units').reduce((fUnits, u) => {
      if (u.get('system_status') !== 'reserved' && !u.get('moved_out')) {
        fUnits.push(u);
      }
      return fUnits;
    }, [])), [])),
);
export const getFacilitiesCount = (state) => getFacilities(state).size;
export const getFacilityUnits = (state, facId) => ((getFacilities(state) || []).find(s => s.get('id') === facId) || new Map()).get('units');
export const getFacilityOccupiedUnits = (state, facId) => (getFacilityUnits(state, facId) || new List()).filter(u => u.get('system_status') !== 'reserved');

export const getSelectedFacilityId = (state) => (getBilling(state) || new Map()).get('selectedFacilityId') || (getFacilities(state).first() || new Map()).get('id');
export const getSelectedUnitId = (state) => getBilling(state).get('selectedUnitId') || (getFacilityOccupiedUnits(state, getSelectedFacilityId(state)).first() || new Map()).get('id');

export const getSelectedFacility = (state) => (getFacilities(state) || []).find(s => s.get('id') === getSelectedFacilityId(state));
export const getSelectedUnit = (state) =>
  (getFacilityUnits(state, getSelectedFacilityId(state)) || []).find(s => s.get('id') === getSelectedUnitId(state));

export const getUnitById = createSelector(
  getFacilities,
  (state, id) => id,
  (state, id, field) => field || 'id',
  (facilities, id, field) => {
    let unit = null;
    const facility = facilities.find((f) => {
      unit = f.get('units').find((u) => u.get(field) === id);
      return !!unit;
    });
    return unit && unit.set('facility_id', facility.get('id'));
  },
);
