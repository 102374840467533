import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import {
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from 'material-ui';
import { result } from 'lodash';
import { UsaStates } from 'usa-states';

import GeneralForm from '../../components/Form';
import SelectField from '../../components/Form/selectField';
import { setAddressContactThunk } from './reducer';
import {
  getAddress,
  getContact,
  getUserInfo,
} from '../Auth/selectors';
import { phoneMask, phoneFormat, zipMask } from '../../constants';
import {
  minLength as minLengthValidation,
  required as requiredValidation,
  zipCode as zipCodeValidation,
} from '../../utils/validations';

const { states } = new UsaStates();
const fields = [{
  name: 'address.street',
  label: 'Address 1',
  required: true,
  validate: [requiredValidation],
}, {
  name: 'address.street_2',
  label: 'Address 2',
}, {
  name: 'address.city',
  label: 'City',
  required: true,
  validate: [requiredValidation],
}, {
  custom: (
    <FormControl margin="normal" key="address.state">
      <InputLabel className="required" htmlFor="address.state" required shrink>State</InputLabel>
      <Field
        name="address.state"
        component={SelectField}
        native
        validate={requiredValidation}
      >
        <option />
        {
          states.map(item => (
            <option key={item.abbreviation} value={item.abbreviation}>{item.name}</option>
          ))
        }
      </Field>
    </FormControl>
  ),
  name: 'address.state',
}, {
  name: 'address.zip',
  label: 'Zip',
  required: true,
  mask: zipMask,
  validate: [requiredValidation, zipCodeValidation],
}, {
  custom: (
    <FormControl margin="normal" key="address.label">
      <InputLabel className="required" htmlFor="address.label" required shrink>Type</InputLabel>
      <Field
        name="address.label"
        component={SelectField}
        validate={requiredValidation}
      >
        <MenuItem value="home">Home</MenuItem>
        <MenuItem value="work">Work</MenuItem>
      </Field>
    </FormControl>
  ),
  name: 'address.label',
}, {
  custom: <Typography align="center" style={{ fontSize: '30px', marginTop: '1em', width: '100%' }} type="display2" gutterBottom>Phone Number</Typography>,
}, {
  name: 'contact.phone',
  label: 'Phone',
  required: true,
  mask: phoneMask,
  pipe: phoneFormat,
  validate: [requiredValidation, minLengthValidation(11, 'Phone Number must contain at least 10 digits', /\D/g)],
}, {
  custom: (
    <FormControl margin="normal" key="contact.label">
      <InputLabel className="required" htmlFor="contact.label" required shrink>Type</InputLabel>
      <Field
        name="contact.label"
        component={SelectField}
        validate={requiredValidation}
      >
        <MenuItem value="home">Home</MenuItem>
        <MenuItem value="work">Work</MenuItem>
        <MenuItem value="mobile">Mobile</MenuItem>
      </Field>
    </FormControl>
  ),
  name: 'contact.label',
}];

const mapStateToProps = (state, ownProps) => {
  let addressId = parseInt(ownProps.match.params.addressId, 10);
  if (Number.isNaN(addressId)) {
    ({ addressId } = ownProps.match.params);
  }

  let contactId = parseInt(ownProps.match.params.contactId, 10);
  if (Number.isNaN(contactId)) {
    ({ contactId } = ownProps.match.params);
  }

  const initValues = {
    address: { default: true, label: 'home' },
    contact: { default: true },
  };

  const userInfo = getUserInfo(state);
  if (userInfo) {
    const address = result(getAddress(state, addressId), 'toJS', null);
    if (address) initValues.address = address;

    const contact = result(getContact(state, contactId), 'toJS', null);
    if (contact) initValues.contact = contact;
  }

  return ({
    fields,
    initialValues: initValues,
    shrinkLabels: true,
  });
}

let boundFunctions = {};
const setAddressContactWithId = (addressId, contactId, addressExists, contactExists, data, next) =>
  setAddressContactThunk({
    address: { ...data.address, id: addressId },
    addressExists,
    contact: { ...data.contact, id: contactId },
    contactExists,
  }, next);

const mapDispatchToProps = (dispatch, ownProps) => {
  const { addressId, contactId } = ownProps.match.params;
  const aId = addressId === 'create' ? 'new' : addressId;
  const cId = contactId === 'create' ? 'new' : contactId;
  const key = `${addressId}_${contactId}`;

  if (!boundFunctions[key]) {
    boundFunctions = {
      [key]: setAddressContactWithId.bind(
        null,
        aId !== 'new' ? parseInt(aId, 10) : aId,
        cId !== 'new' ? parseInt(cId, 10) : cId,
        addressId !== 'create',
        contactId !== 'create',
      ),
    };
  }

  const create = boundFunctions[key];
  const update = boundFunctions[key];

  return {
    actions: bindActionCreators({
      create,
      update,
      cancel: ownProps.onCancel,
    }, dispatch),
  };
};

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'contactForm',
})(GeneralForm)));

