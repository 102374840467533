import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Route, withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { result, findIndex, endsWith } from 'lodash';
import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
  withStyles,
} from 'material-ui';
import { NavigateNext, NavigateBefore } from 'material-ui-icons';

import { getLoggedIn, getUserInfo } from '../Auth/selectors';
import ProfileForm from '../Account/AccountProfile/profileForm';
import ContactForm from '../Account/addressContactForm';
// import BusinessForm from './Business/businessForm';
// import LienForm from './Lien/lienForm';
import { fetchUnit, fetchFacility } from './reducer';
import { getError, getReservation, getUnit, getFacilityLoaded, getFacility, getFacilityLoading } from './selectors';
import Loading from '../../components/Loading';
import UnitIcon from '../../components/UnitIcon';
import SelectDate from './SelectDate';
import MoveIn from './MoveIn';
import CompleteRental from './CompleteRental';
import CompleteReservation from './CompleteReservation';
import Home from './Home';
import { currency } from '../../utils/format';
import { setReturnUrl } from '../AppWrapper/reducer';

import './styles.css';

const styles = (theme) => ({
  unitBlock: {
    width: 800,
    padding: '15px 30px 10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      padding: 8,
    },
  },
  col1: {
    width: '16%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  col2: {
    width: '28%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      textAlign: 'center',
    },
  },
  col3: {
    width: '28%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  col4: {
    width: '28%',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      width: '50%',
    },
  },
  contentContainer: {
    padding: '0.5em',
    width: '100%',
  },
  loginLink: {
    paddingTop: '8px',
    textAlign: 'center',
  },
  navigationContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '12px 6px !important',
    },
  },
  navigationBarContent: {
    marginTop: '12px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '8px',
      width: '0.75em',
    },
  },
  navigationIcon: {
    [theme.breakpoints.down('xs')]: {
      width: '0.75em',
      height: '0.75em',
    },
  },
  prevIcon: {
    marginRight: '-40px',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  header: {
    borderBottom: '1px solid lightgrey',
  },
  facilityTitle: {
    fontWeight: 'bold',
  },
});

const steps = [
  {
    path: 'userInfo',
    Element: ProfileForm,
    title: 'User Info',
    editable: true,
    props: {
      buttonTitle: 'Next',
      disablePristine: true,
      includeQueryParams: true,
      pageTitle: 'User Info',
    },
  },
  {
    path: 'contactInfo/:addressId/:contactId',
    Element: ContactForm,
    title: 'Contact Info',
    editable: true,
    props: {
      buttonTitle: 'Next',
      disablePristine: true,
      pageTitle: 'Contact Info',
      title: 'Address',
    },
  },
  {
    path: 'selectDate',
    Element: SelectDate,
    title: 'Select Date',
  },
  {
    path: 'completeReservation',
    Element: CompleteReservation,
    title: 'Reservation',
  },
  /*
  {
    path: 'business',
    Element: BusinessForm,
    title: 'Business',
    editable: true,
  },
  {
    path: 'lien',
    Element: LienForm,
    title: 'Lien',
    editable: true,
  }, */
  {
    path: 'moveIn',
    Element: MoveIn,
    title: 'Move-In',
    back: {
      url: 'selectDate',
      state: { isReservation: true },
    },
  },
  {
    path: 'completeRental',
    Element: CompleteRental,
    title: 'Rental Complete',
    props: {
      unitId: true,
    },
  },
];

const reservationSteps = steps.slice(0, 4);
const moveInSteps = steps.slice(0, 2).concat(steps.slice(-2));

class Reservation extends Component {
  constructor(props) {
    super(props);

    this.isReservation = (props.history.location.state || {}).isReservation;
    this.maxStepIndex = -1;
    this.resolvePaths(props.userInfo ? props.userInfo.toJS() : {}, this.isReservation);
  }

  componentDidMount() {
    const {
      actions,
      facilityLoaded,
      loggedIn,
      unit,
    } = this.props;
    const { unitId } = this.props.match.params;

    if (!unit || unit.id.toString() !== unitId) {
      actions.fetchUnit(unitId);
    } else if (loggedIn && !facilityLoaded) {
      actions.fetchFacility(unit.facility_id);
    }
  }

  shouldComponentUpdate(props) {
    // If unit ID changes, fetch new unit and reset state
    const locationState = props.history.location.state || {};
    const { isReservation } = locationState;
    const { unitId } = props.match.params;
    if (props.unit) {
      if (props.unit.id.toString() !== unitId) {
        props.actions.fetchUnit(unitId);
        this.maxStepIndex = -1;
        this.isReservation = isReservation;
        return false;
      } else if (props.loggedIn && !props.facilityLoaded && !props.facilityLoading) {
        // If User comes directly to this page it may still be logging in on first render.
        // In that happens, fetch Occupancy Group info once User is logged in.
        props.actions.fetchFacility(props.unit.facility_id);
      }
    }

    // If address / contact is added or state has changed, regenerate paths
    const stepsChanged = !!isReservation !== !!this.isReservation;
    const userInfoChanged = props.userInfo && (
      !this.props.userInfo
      || props.userInfo.get('addresses') !== this.props.userInfo.get('addresses')
      || props.userInfo.get('contacts') !== this.props.userInfo.get('contacts')
      || !props.userInfo.get('email') !== !this.props.userInfo.get('email')
    );
    if (userInfoChanged || stepsChanged) {
      this.resolvePaths(props.userInfo ? props.userInfo.toJS() : {}, isReservation);
      if (stepsChanged) {
        this.maxStepIndex = -1;
        this.isReservation = isReservation;
      }
      if (userInfoChanged && this.maxStepIndex >= 0) {
        const currStep = this.steps[this.maxStepIndex];
        if (currStep.resolvedPath) {
          const nextStep = this.findNextStep(this.maxStepIndex + 1);
          props.history.replace(`${props.match.url}/${currStep.resolvedPath || currStep.path}`, { ...locationState });
          props.history.push(`${props.match.url}/${nextStep.resolvedPath || nextStep.path}`, { ...locationState });
          return false;
        }
      }
    }

    return true;
  }

  resolvePaths(userInfo, isReservation) {
    // Resolve paths using primary address / contact
    const replacementValues = [
      userInfo.addresses &&
      (userInfo.addresses.find((address) => address.default) || userInfo.addresses[0]),
      userInfo.contacts &&
      (userInfo.contacts.find((contact) => contact.default) || userInfo.contacts[0]),
    ];
    this.steps = (isReservation ? reservationSteps : moveInSteps).map((step) => {
      if (step.path.indexOf(':addressId') > -1 || step.path.indexOf(':contactId') > -1) {
        return {
          ...step,
          resolvedPath: step.path
            .replace(':addressId', replacementValues[0] ? replacementValues[0].id : 'create')
            .replace(':contactId', replacementValues[1] ? replacementValues[1].id : 'create'),
          // If we have a replacementValue, consider this step complete
          // (used to determine if we can skip forward)
          complete: !!(replacementValues[0] && replacementValues[1]),
        };
      }
      return step;
    });
    this.steps[0].complete = !!userInfo.email;
  }

  obtainStepClass = (index, stepIndex) => {
    if (index === stepIndex) {
      return 'active';
    } else if (index < stepIndex) {
      return 'done';
    } else if (index === this.maxStepIndex) {
      return 'next';
    }
    return 'non-done';
  }

  findNextStep = (startIndex) => {
    for (let i = startIndex; i < this.steps.length; i += 1) {
      if (!this.steps[i].hidden && (this.steps[i].editable || i >= this.maxStepIndex)) {
        return this.steps[i];
      }
    }
    return this.steps[startIndex];
  }

  findPreviousStep = (startIndex) => {
    for (let i = startIndex; i >= 0; i -= 1) {
      if (this.steps[i].editable && !this.steps[i].hidden) return this.steps[i];
    }
    return null;
  }

  findMaxStepIndex = () => {
    while (
      this.maxStepIndex >= 0
      && this.steps[this.maxStepIndex].complete
      && this.maxStepIndex < this.steps.length - 1) {
      this.maxStepIndex += 1;
    }
  }

  isLocation = (endString) => (
    endsWith(this.props.location.pathname, endString)
  )

  renderSetupBar = (stepIndex) => {
    const {
      classes,
      history,
      match,
    } = this.props;

    const locationState = history.location.state || {};
    const prevStep = this.findPreviousStep(stepIndex - 1);
    const nextStep = stepIndex < this.maxStepIndex ? this.findNextStep(stepIndex + 1) : null;

    return (
      <Grid container>
        {
          stepIndex >= 0 && (
            <IconButton
              className={`${classes.navigationBarContent} ${classes.prevIcon}`}
              color="secondary"
              onClick={prevStep ? () => {
                history.push(`${match.url}/${prevStep.resolvedPath || prevStep.path}`, { ...locationState, skippable: false });
              } : undefined}
              style={{ visibility: prevStep ? 'visible' : 'hidden' }}
              title="Previous"
            >
              <NavigateBefore className={classes.navigationIcon} />
            </IconButton>
          )
        }
        <ol className="steps">
          {
            stepIndex >= 0 ? this.steps.map((s, index) => {
              if (s.hidden) return null;

              let item = (
                <li key={`${s.path}_item`} className={this.obtainStepClass(index, stepIndex)}>
                  {s.title}
                </li>
              );
              // Make previously visited steps into Link buttons for navigation
              if (
                index <= this.maxStepIndex
                && index !== stepIndex
                && (s.editable || index === this.maxStepIndex)
              ) {
                item = <Link key={s.path} to={{ pathname: `${match.url}/${s.resolvedPath || s.path}`, state: { ...locationState, skippable: false } }}>{item}</Link>
              }
              return item;
            }) : null
          }
        </ol>
        {
          stepIndex >= 0 && (
            <IconButton
              className={classes.navigationBarContent}
              color="secondary"
              onClick={nextStep ? () => {
                history.push(`${match.url}/${nextStep.resolvedPath || nextStep.path}`, { ...locationState, skippable: false });
              } : undefined}
              style={{ visibility: nextStep ? 'visible' : 'hidden' }}
              title="Next"
            >
              <NavigateNext className={classes.navigationIcon} />
            </IconButton>
          )
        }
      </Grid>
    );
  }
  renderUnit = () => {
    const {
      classes,
      facility,
      moveInDate,
      unit,
    } = this.props;

    const facilityInfo = facility && facility.toJS();
    const displayDate = this.isReservation ? moveInDate : undefined;
    const isReserved = !!moveInDate || unit.system_status === 'reserved';
    return (
      <Paper>
        <Grid alignItems="center" container className={classes.unitBlock}>
          <Grid className={classes.header} item xs={12}>
            <Grid alignItems="baseline" container justify="center" spacing={16}>
              <Grid item>
                <Typography className={classes.facilityTitle} type="title">{facilityInfo.site_code || facilityInfo.store_number}:</Typography>
              </Grid>
              <Grid item>
                <Typography type="subheading">{facilityInfo.full_address}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.col1}>
            <UnitIcon key={`${unit.width}-${unit.length}`} width={unit.width} length={unit.length} />
            <Typography type="subheading" align="center">Unit {unit.unit_number}</Typography>
          </Grid>
          <Grid item className={classes.col2}>
            <Typography align="center" type="title">{`${unit.width}' x ${unit.length}'`}</Typography>
            <Typography align="center" color="textSecondary">{unit.unit_type_name || unit.unit_type}</Typography>
            <Typography align="center" color="textSecondary">{unit.width * unit.length} sq. ft.</Typography>
          </Grid>
          <Grid item className={classes.col3}>
            <Typography type="headline" align="center">{currency(unit.move_in_monthly_rate)}</Typography>
            <Typography color="textSecondary" align="center">per month</Typography>
            <Typography color="secondary" align="center">{unit.promotion_description}</Typography>
          </Grid>
          <Grid item className={classes.col4}>
            {
              this.maxStepIndex === this.steps.length - 1 || isReserved ? (
                <div>
                  <Hidden smDown>
                    <Typography type="headline" align="center"><b>Move-In Date</b></Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography type="title" align="center"><b>Move-In Date</b></Typography>
                  </Hidden>
                  <Typography color="secondary" align="center">{moment(displayDate).format('MMMM D, YYYY')}</Typography>
                </div>
              ) : (
                <Link to={`/facilities/${unit.facility_id}`}>
                  <Button
                    raised
                    fullWidth
                    color="secondary"
                  >
                    Change
                  </Button>
                </Link>
              )
            }
          </Grid>
        </Grid>
      </Paper>
    )
  }

  render() {
    const {
      actions,
      classes,
      error,
      history,
      location,
      loggedIn,
      match,
      moveInDate,
      unit,
    } = this.props;

    if (!unit || unit.id.toString() !== match.params.unitId) {
      if (error) {
        return (
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
            spacing={24}
          >
            <Typography type="display1" align="center">Unit could not be loaded! Please check your internet connection and refresh the page to try again.</Typography>
          </Grid>
        );
      }

      return (
        <Loading />
      );
    }

    const isHome = location.pathname.endsWith(match.url);
    const stepIndex = findIndex(
      this.steps,
      s => location.pathname.endsWith(s.resolvedPath || s.path),
    );

    // If URL does not match current workflow, redirect
    const locationState = history.location.state || {};
    if (!isHome && stepIndex < 0 && this.steps.length > 0) {
      this.maxStepIndex = 0;
      this.findMaxStepIndex();
      const currStep = this.steps[this.maxStepIndex];
      history.replace(`${match.url}/${currStep.resolvedPath || currStep.path}`, { ...locationState });
      return (
        <Loading />
      );
    }

    this.maxStepIndex = Math.max(stepIndex, this.maxStepIndex);
    this.findMaxStepIndex();

    const loginLink = <Link className="link" onClick={() => actions.setReturnUrl(true)} to="/">Login</Link>;

    return (
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        direction="column"
        wrap="nowrap"
        spacing={24}
      >
        <Grid item>
          { this.renderUnit() }
        </Grid>
        <Grid item className={classes.navigationContainer}>
          { this.renderSetupBar(stepIndex) }
          {!loggedIn && <Typography className={classes.loginLink} type="caption">Already have an account? {loginLink}</Typography>}
        </Grid>
        <Grid className={classes.contentContainer} xs={12} md={10} lg={8} xl={6}>
          {
            this.steps.map(({
              back,
              complete,
              editable,
              Element,
              path,
              props,
              resolvedPath,
            }, index) => {
              let next;
              // If this is a form step, set the route to redirect to next (on create / save)
              if (editable && index < this.steps.length - 1) {
                if (complete === false && resolvedPath) {
                  next = null;
                } else {
                  const nextStep = this.findNextStep(index + 1);
                  next = {
                    url: `${match.url}/${nextStep.resolvedPath || nextStep.path}`,
                    state: { ...locationState },
                  };
                }
              }

              // Allow navigation back to reservation workflow if unit is not already reserved
              let backRoute;
              if (unit.system_status !== 'reserved' && !moveInDate && back) {
                backRoute = {
                  ...back,
                  url: `${match.url}/${back.url}`,
                };
              }

              let stepProps = props;
              if (stepProps && stepProps.unitId) {
                stepProps = { ...props, ...match.params };
              }

              return (
                <Route
                  key={path}
                  path={`${match.url}/${path}`}
                  render={(routeProps) => (
                    <Element
                      {...routeProps}
                      {...stepProps}
                      back={backRoute}
                      skip={complete && locationState.skippable}
                      next={next}
                    />
                  )}
                />
              );
            })
          }
          { isHome ? <Home onClick={(url, state) => { history.push(`${match.url}/${url}`, state); }} /> : null }
        </Grid>
      </Grid>
    );
  }
}

Reservation.defaultProps = {
  error: null,
  facility: null,
  facilityLoading: false,
  facilityLoaded: false,
  loggedIn: false,
  moveInDate: null,
  unit: null,
};

Reservation.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.object,
  facility: PropTypes.object,
  facilityLoading: PropTypes.bool,
  facilityLoaded: PropTypes.bool,
  location: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  moveInDate: PropTypes.string,
  unit: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: getError(state),
  facility: getFacility(state),
  facilityLoading: getFacilityLoading(state),
  facilityLoaded: getFacilityLoaded(state),
  loggedIn: getLoggedIn(state),
  moveInDate: getReservation(state) && getReservation(state).get('move_in_date'),
  unit: result(getUnit(state), 'toJS', null),
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFacility,
    fetchUnit,
    setReturnUrl,
  }, dispatch),
});

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reservation)));
