import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { push, replace } from 'react-router-redux';
import { stopSubmit } from 'redux-form';
import { result as parseResult } from 'lodash';
import moment from 'moment';

import { appMsg, setReturnUrl } from '../AppWrapper/reducer';
import { fetchUnits } from '../MyUnits/reducer';
import { fetchFacility as fetchFacilityAction } from '../Facility/reducer';
import { transactSavedData, updateVehicleById } from '../Account/reducer';
import {
  createReservation,
  createMoveIn,
  fetchMoveInCosts,
} from '../../services/reservation';
import { createPayment } from '../../services/billing';
import {
  getFacility,
  getBusinessData,
  getLien,
  getPaymentMethod,
  getUnit,
  getMoveInCosts,
} from './selectors';
import { getLoggedIn } from '../Auth/selectors';
import { getFacility as getFacilityById } from '../SearchMap/selectors';
import { updateFacility } from '../SearchMap/reducer';

export const constants = {
  FETCH_UNIT: 'containers/Reservation/FETCH_UNIT',
  FETCH_UNIT_ERROR: 'containers/Reservation/FETCH_UNIT_ERROR',
  FETCH_UNIT_SUCCESS: 'containers/Reservation/FETCH_UNIT_SUCCESS',
  FETCH_FACILITY: 'containers/Reservation/FETCH_FACILITY',
  FETCH_FACILITY_ERROR: 'containers/Reservation/FETCH_FACILITY_ERROR',
  FETCH_FACILITY_SUCCESS: 'containers/Reservation/FETCH_FACILITY_SUCCESS',
  FETCH_MOVE_IN_COSTS: 'containers/Reservation/FETCH_MOVE_IN_COSTS',
  FETCH_MOVE_IN_COSTS_ERROR: 'containers/Reservation/FETCH_MOVE_IN_COSTS_ERROR',
  FETCH_MOVE_IN_COSTS_SUCCESS: 'containers/Reservation/FETCH_MOVE_IN_COSTS_SUCCESS',
  CREATE_PAYMENT_SUCCESS: 'containers/Reservation/CREATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_SUCCESS: 'containers/Reservation/UPDATE_PAYMENT_SUCCESS',
  CREATE_RESERVATION_SUCCESS: 'containers/Reservation/CREATE_RESERVATION_SUCCESS',
  CREATE_MOVEIN_SUCCESS: 'containers/Reservation/CREATE_MOVEIN_SUCCESS',
  UPDATE_BUSINESS_SUCCESS: 'containers/Reservation/UPDATE_BUSINESS_SUCCESS',
  UPDATE_LIEN_SUCCESS: 'containers/Reservation/UPDATE_LIEN_SUCCESS',
  SAVE_MOVE_IN_STATE: 'containers/Reservation/SAVE_MOVE_IN_STATE',
};

export const fetchUnit = createAction(constants.FETCH_UNIT);
export const fetchUnitError = createAction(constants.FETCH_UNIT_ERROR);
export const fetchUnitSuccess = createAction(constants.FETCH_UNIT_SUCCESS);
export const fetchFacility = createAction(constants.FETCH_FACILITY);
export const fetchFacilityError = createAction(constants.FETCH_FACILITY_ERROR);
export const fetchFacilitySuccess = createAction(constants.FETCH_FACILITY_SUCCESS);
export const fetchMoveInCost = createAction(constants.FETCH_MOVE_IN_COSTS);
export const fetchMoveInCostError = createAction(constants.FETCH_MOVE_IN_COSTS_ERROR);
export const fetchMoveInCostSuccess = createAction(constants.FETCH_MOVE_IN_COSTS_SUCCESS);
export const createPaymentSuccess = createAction(constants.CREATE_PAYMENT_SUCCESS);
export const updatePaymentSuccess = createAction(constants.UPDATE_PAYMENT_SUCCESS);
export const createReservationSuccess = createAction(constants.CREATE_RESERVATION_SUCCESS);
export const createMoveInSuccess = createAction(constants.CREATE_MOVEIN_SUCCESS);
export const updateBusinessSuccess = createAction(constants.UPDATE_BUSINESS_SUCCESS);
export const updateLienSuccess = createAction(constants.UPDATE_LIEN_SUCCESS);
export const saveMoveInState = createAction(constants.SAVE_MOVE_IN_STATE);

const initialState = fromJS({
  unitData: null,
  facilityData: null,
  facilityLoading: false,
  facilityLoaded: false,
  businessData: { isBusiness: false, tax_exempt: false },
  lienData: { hasLien: false },
  reservationData: null,
  moveInData: null,
  moveInState: null,
  moveInCosts: null,
  error: null,
  moveInCostError: null,
  loading: false,
  loaded: false,
});

export default handleActions({
  [fetchUnit]: () => initialState
    .set('loading', true),

  [fetchUnitSuccess]: (state, action) => state
    .set('unitData', fromJS(action.payload))
    .set('loading', false)
    .set('loaded', true),

  [fetchUnitError]: (state, action) => state
    .set('error', action.payload)
    .set('unitData', null)
    .set('loading', false),

  [fetchFacility]: (state) => state
    .set('facilityLoaded', false)
    .set('facilityLoading', true),

  [fetchFacilitySuccess]: (state, action) => state
    .update('facilityData', (facilityData) => {
      // If facilityData is not populated, set it
      if (!facilityData) {
        return fromJS(action.payload.facility);
      }

      // Otherwise, combine payment_methods
      const currPaymentMethods = facilityData.get('payment_methods').toJS();
      const { facility } = action.payload;
      facility.payment_methods = facility.payment_methods.concat(currPaymentMethods);
      return fromJS(facility);
    })
    .set('facilityLoading', false)
    .set('facilityLoaded', action.payload.loaded),

  [fetchFacilityError]: (state, action) => state
    .set('facilityLoading', false)
    .set('error', action.payload),

  [fetchMoveInCost]: (state) => state
    .set('loading', true),

  [fetchMoveInCostSuccess]: (state, action) => state
    .set('moveInCosts', fromJS(action.payload))
    .set('loading', false),

  [fetchMoveInCostError]: (state, action) => state
    .set('moveInCostError', action.payload)
    .set('loading', false),

  [createReservationSuccess]: (state, action) => state
    .set('reservationData', fromJS(action.payload)),

  [createMoveInSuccess]: (state, action) => state
    .set('moveInData', fromJS(action.payload && action.payload.moveIn)),

  [updateBusinessSuccess]: (state, action) => state
    .set('businessData', fromJS(action.payload)),

  [updateLienSuccess]: (state, action) => state
    .set('lienData', fromJS(action.payload)),

  [createPaymentSuccess]: (state, action) => state
    .updateIn(['facilityData', 'payment_methods'], arr => arr.push(fromJS(action.payload))),

  [updatePaymentSuccess]: (state, action) => state
    .updateIn(['facilityData', 'payment_methods'], (arr) => arr.map((payment) => {
      if (payment.get('id') === action.payload.id) {
        return fromJS(action.payload.payment);
      }
      return payment;
    })),

  [saveMoveInState]: (state, action) => state
    .set('moveInState', fromJS(action.payload)),

}, initialState)


/* Thunks */
export const createPaymentThunk = (data) =>
  (dispatch, getState) => {
    const facility = getFacility(getState()).toJS();
    const paymentData = {
      ...data,
      authnet_client_key: facility.authnet_client_key,
      authnet_login: facility.authnet_login,
      facility_id: facility.id,
    };

    return createPayment(paymentData, !getLoggedIn(getState()))
      .then(({ result, error }) => {
        if (error) {
          dispatch(stopSubmit('paymentForm', error));
          // eslint-disable-next-line
          throw new Error(error._error);
        }

        window.gtag('event', 'add_payment_info', {
          payment_type: paymentData.profile_type,
          items: [{
            item_list_id: paymentData.facility_id,
            item_list_name: facility.site_code || facility.store_number,
            item_id: result.id,
            item_name: result.line,
            item_category: 'PaymentMethod',
          }],
        });

        dispatch(createPaymentSuccess(result));
        dispatch(appMsg('Payment method has been added!', 'success'));
      })
  };

export const savePayment = (dispatch, data) => createPayment(data)
  .then(({ result, error }) => {
    if (error) {
      // eslint-disable-next-line
      throw new Error(error._error);
    }

    dispatch(updatePaymentSuccess({ payment: result, id: data.id }));
    return result;
  })

export const createReservationThunk = (data) =>
  (dispatch, getState) => {
    dispatch(appMsg(null));

    if (!getLoggedIn(getState())) {
      // User is not logged in yet; save reservation data and redirect to login
      dispatch(createReservationSuccess(data));
      dispatch(setReturnUrl(true));
      dispatch(push('/'));
      return Promise.resolve();
    }

    return transactSavedData(dispatch, getState)
      .then(() => createReservation(data))
      .then(({ result, error }) => {
        if (error) {
          // eslint-disable-next-line
          dispatch(appMsg({ message: `Can't reserve: ${error._error}`, duration: false }));
          // throw new Error(error);
        } else {
          const unit = getUnit(getState());
          const facility = getFacilityById(getState(), data.facility_id);
          window.gtag('event', 'add_to_cart', {
            currency: 'USD',
            value: unit.get('move_in_monthly_rate'),
            expected_move_in_date: data.move_in_date,
            items: [{
              item_id: data.unit_id,
              item_name: unit.get('unit_number'),
              item_category: 'Unit',
              item_category2: unit.get('unit_type_name') || unit.get('unit_type'),
              item_variant: `${unit.get('width')} x ${unit.get('length')}`,
              coupon: data.promotion_id,
              item_list_id: data.facility_id,
              item_list_name: facility && (facility.get('site_code') || facility.get('store_number')),
              price: unit.get('move_in_monthly_rate'),
            }],
          });

          dispatch(createReservationSuccess(result));
          dispatch(fetchFacilityAction({ facility_id: data.facility_id, force: true }));
          if (facility) {
            dispatch(updateFacility({
              facility_id: data.facility_id,
              data: { available_units_count: facility.get('available_units_count') - 1 },
            }));
          }
          dispatch(fetchUnits());
          // Use replace here so that User cannot use back button to return to "SelectDate" page
          dispatch(replace(`/reservation/${data.unit_id}/completeReservation`, { isReservation: true }));
        }
      });
  }

export const getChargeItems = (data, charges, unit, facility) => (
  charges && charges.map((charge) => {
    if (charge.description.startsWith('Monthly charge')) {
      return {
        coupon: unit && unit.promotion_id,
        item_id: data.unit_id,
        item_name: charge.description,
        item_category: 'Unit',
        item_category2: unit && (unit.unit_type_name || unit.unit_type),
        item_variant: `${unit && unit.width} x ${unit && unit.length}`,
        item_list_id: data.facility_id,
        item_list_name: facility && (facility.site_code || facility.store_number),
        price: charge.amount,
      };
    } else if (charge.description.endsWith('Protection Plan')) {
      return {
        item_id: data.insurance_id,
        item_name: charge.description,
        item_category: 'Protection Plan',
        price: charge.amount,
      };
    }

    const amount = parseFloat(charge.amount);
    return {
      item_name: charge.description,
      item_category: amount < 0 ? 'Discount' : 'Fee',
      discount: amount < 0 ? amount * -1 : undefined,
      price: amount > 0 ? amount : undefined,
    };
  })
);

/* Thunks */
export const createMoveInThunk = (data) =>
  (dispatch, getState) => {
    dispatch(appMsg(null));

    const state = getState();
    let savePaymentPromise;
    if (data.payment_id && data.payment_id.startsWith('new_')) {
      savePaymentPromise = savePayment(dispatch, getPaymentMethod(state, data.payment_id).toJS());
    } else {
      savePaymentPromise = Promise.resolve();
    }

    return savePaymentPromise
      .then((payment) => (payment ? { ...data, payment_id: payment.id } : data))
      .then((moveInData) => createMoveIn(moveInData))
      .then(({ result, error }) => {
        if (error) {
          // eslint-disable-next-line
          throw new Error(error._error);
        } else {
          const unit = parseResult(getUnit(state), 'toJS', null);
          const facility = parseResult(getFacilityById(state, data.facility_id), 'toJS', null);
          const moveInCosts = parseResult(getMoveInCosts(state), 'toJS', null);
          const value = Number(moveInCosts && moveInCosts.charges.reduce(
            (accumulator, currentValue) =>
              accumulator + parseFloat(currentValue.amount),
            0.0,
          )).toFixed(2);

          window.gtag('event', 'purchase', {
            currency: 'USD',
            transaction_id: result.occupancy_id,
            payment_id: data.payment_id,
            vehicle_id: data.vehicle_id,
            value,
            coupon: data.promotion_code,
            move_in_date: moment().format('YYYY-MM-DD'),
            items: getChargeItems(data, moveInCosts && moveInCosts.charges, unit, facility),
          });

          // Include data from all completed forms
          dispatch(createMoveInSuccess({
            moveIn: result,
            business: parseResult(getBusinessData(state), 'toJS', null),
            lien: parseResult(getLien(state), 'toJS', null),
          }));

          // If vehicle_id was provided, update Vehicle with returned occupancy_id
          if (data.vehicle_id) {
            dispatch(updateVehicleById({ id: data.vehicle_id, occupancy_id: result.occupancy_id }));
          }

          // Update Facility available units
          dispatch(fetchFacilityAction({ facility_id: data.facility_id, force: true }));

          // If Unit was not already reserved remove it from count of available units
          if (unit && unit.id === data.unit_id && unit.system_status !== 'reserved') {
            if (facility) {
              dispatch(updateFacility({
                facility_id: data.facility_id,
                data: { available_units_count: facility.available_units_count - 1 },
              }));
            }
          }

          dispatch(fetchUnits());

          // Use replace here so that user cannot use back button to return to "MoveIn" page
          dispatch(replace(`/reservation/${data.unit_id}/completeRental`));
        }
      })
      .catch((err) => {
        const msg = err && err.message && typeof err.message === 'string' ? `Can't move-in: ${err.message}` : 'Can\'t move-in!';
        dispatch(appMsg({ message: msg, duration: false }));
        throw err;
      })
  }

/* Thunks */
export const fetchMoveInCostsThunk = (data) =>
  (dispatch) => fetchMoveInCosts(data)
    .then(({ result, error }) => {
      if (error) {
        const { _error } = error;
        if (_error && data.promotion_code) {
          dispatch(fetchMoveInCostError(_error));
        } else {
          dispatch(appMsg('Can\'t retrieve move-in costs!', 'error'));
        }
      } else {
        dispatch(fetchMoveInCostSuccess(result));
      }
    })

export const updateBusinessThunk = (data, next) =>
  (dispatch) => {
    dispatch(updateBusinessSuccess(data));
    if (next) dispatch(push(next));
    return Promise.resolve();
  }

export const updateLienThunk = (data, next) =>
  (dispatch) => {
    dispatch(updateLienSuccess(data));
    if (next) dispatch(push(next));
    return Promise.resolve();
  }
