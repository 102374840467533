import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from 'material-ui';
import PasswordResetForm from './passwordResetForm';

const AccountSettings = ({ title }) => {
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => { document.title = title; }, [title]);

  return (
    <div>
      <PasswordResetForm showPassword={showPassword} title="Reset Password">
        <FormControlLabel checked={showPassword} control={<Checkbox />} label="Show Passwords" onChange={(e, checked) => setShowPassword(checked)} />
      </PasswordResetForm>
    </div>
  );
};
AccountSettings.defaultProps = {
  title: 'Change Password',
};
AccountSettings.propTypes = {
  title: PropTypes.string,
};

export default AccountSettings;
