import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { signLease as signLeaseService } from '../../services/lease';

export const constants = {
  FETCH_UNITS: 'containers/MyUnits/FETCH_UNITS',
  FETCH_UNITS_ERROR: 'containers/MyUnits/FETCH_UNITS_ERROR',
  FETCH_UNITS_SUCCESS: 'containers/MyUnits/FETCH_UNITS_SUCCESS',
  UPDATE_UNIT: 'containers/MyUnits/UPDATE_UNIT',
  MOVE_OUT: 'containers/MyUnits/MOVE_OUT',
  MOVE_OUT_ERROR: 'containers/MyUnits/MOVE_OUT_ERROR',
  MOVE_OUT_SUCCESS: 'containers/MyUnits/MOVE_OUT_SUCCESS',
  CANCEL_MOVE_OUT: 'containers/MyUnits/CANCEL_MOVE_OUT',
  CANCEL_MOVE_OUT_SUCCESS: 'containers/MyUnits/CANCEL_MOVE_OUT_SUCCESS',
  CANCEL_MOVE_OUT_ERROR: 'containers/MyUnits/CANCEL_MOVE_OUT_ERROR',
  SIGN_LEASE: 'containers/MyUnits/SIGN_LEASE',
  UPDATE_LEASE_ENVELOPE_ID: 'containers/MyUnits/UPDATE_LEASE_ENVELOPE_ID',
};

export const fetchUnits = createAction(constants.FETCH_UNITS);
export const fetchUnitsError = createAction(constants.FETCH_UNITS_ERROR);
export const fetchUnitsSuccess = createAction(constants.FETCH_UNITS_SUCCESS);
export const updateUnit = createAction(constants.UPDATE_UNIT);

export const moveOut = createAction(constants.MOVE_OUT);
export const moveOutError = createAction(constants.MOVE_OUT_ERROR);
export const moveOutSuccess = createAction(constants.MOVE_OUT_SUCCESS);

export const cancelMoveOut = createAction(constants.CANCEL_MOVE_OUT);
export const cancelMoveOutError = createAction(constants.CANCEL_MOVE_OUT_ERROR);
export const cancelMoveOutSuccess = createAction(constants.CANCEL_MOVE_OUT_SUCCESS);

export const signLease = createAction(constants.SIGN_LEASE);
export const updateLeaseEnvelopeId = createAction(constants.UPDATE_LEASE_ENVELOPE_ID);

const initialState = fromJS({
  data: [],
  error: null,
  units: {},
  loaded: false,
  loading: false,
  updating: false,
});

export default handleActions({
  [fetchUnits]: (state) => state
    .set('loading', true)
    .set('loaded', false)
    .set('error', null),

  [fetchUnitsSuccess]: (state, action) => state
    .set('data', fromJS(action.payload))
    // Override server state with local lease state
    .update('data', (facilities) => (
      facilities && facilities.map((f) => (
        f.update('units', (units) => units && units.map(u => {
          const unitOverrides = state.getIn(['units', u.get('id')]);
          return unitOverrides ? u.mergeDeep(unitOverrides) : u;
        }))
      ))
    ))
    .set('loaded', true)
    .set('loading', false),

  [updateUnit]: (state, action) => state
    .update('data', (facilities) => (
      facilities && facilities.map((f) => (
        f.update('units', (units) => units && units.map(u => (u.get('id') === action.payload.id ? u.mergeDeep(action.payload) : u)))
      ))
    )),

  [fetchUnitsError]: (state, action) => state
    .set('data', fromJS([]))
    .set('error', action.payload)
    .set('loading', false),

  [moveOut]: (state) => state
    .set('updating', true),

  [moveOutSuccess]: (state) => state
    .set('updating', false),

  [moveOutError]: (state, action) => state
    .set('error', action.payload)
    .set('updating', false),

  [cancelMoveOut]: (state) => state
    .set('updating', true),

  [cancelMoveOutSuccess]: (state) => state
    .set('updating', false),

  [cancelMoveOutError]: (state, action) => state
    .set('error', action.payload)
    .set('updating', false),

  [signLease]: (state, action) => state
    .setIn(['units', action.payload.unit_id], { lease_envelope_id: action.payload.lease_envelope_id, lease_signed: true })
    .update('data', (facilities) => (
      facilities && facilities.map((f) => {
        const modifiedUnit = f.get('units', []).find(u => u.get('id') === action.payload.unit_id);
        return modifiedUnit ? f.update('units', (units) => units && units.map(u => (u === modifiedUnit ? u.set('lease_envelope_id', action.payload.lease_envelope_id).set('lease_signed', true) : u))) : f;
      })
    )),

  [updateLeaseEnvelopeId]: (state, action) => state
    .update('data', (facilities) => (
      facilities && facilities.map((f) => {
        const modifiedUnit = f.get('units', []).find(u => u.get('id') === action.payload.unit_id);
        return modifiedUnit ? f.update('units', (units) => units && units.map(u => (u === modifiedUnit ? u.set('lease_envelope_id', action.payload.lease_envelope_id) : u))) : f;
      })
    )),
}, initialState);

export const signLeaseThunk = (payload) =>
  (dispatch) => {
    dispatch(signLease(payload));
    return payload.occupancy_id ? signLeaseService(payload) : Promise.resolve();
  }
