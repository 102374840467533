/* eslint-disable no-console */
/* CacheBuster component */
import React from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
}

export default class AppCache extends React.Component {
  state = {
    loading: true,
    isLatestVersion: false,
    refreshCacheAndReload: async () => {
      console.log('Clearing cache and hard reloading...');
      if (caches) {
        const names = await caches.keys();
        await Promise.all(names.map((name) => caches.delete(name)));
      }
      // delete browser cache and hard reload
      window.location.reload();
    },
  };

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch(() => {
        // Couldn't fetch; we don't want app to be broken, so assume current version is valid
        this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    if (loading) {
      return null;
    } else if (!isLatestVersion) {
      refreshCacheAndReload();
      return null;
    }

    return this.props.children;
  }
}
