import React from 'react';
import PropTypes from 'prop-types';
import { Badge, withStyles } from 'material-ui';
import { AcUnit, DirectionsCar, Flare, Lock, Power } from 'material-ui-icons';
import colors from '../../styles/colors';
import Inventory from '../../svgs/inventory_2';
import Thermostat from '../../svgs/thermostat';

const ICON_SIZE = 24;
const ICON_SIZES = {
  sm: ICON_SIZE * 0.5,
  md: ICON_SIZE,
  lg: ICON_SIZE * 2,
  xl: ICON_SIZE * 3,
  '2xl': ICON_SIZE * 4,
}

const styles = (/* theme */) => ({
  topLeft: {
    left: -12,
    top: -12,
  },
});

const UnitImage = (props) => {
  const {
    classes,
    locked,
    size,
    unitType,
  } = props;

  const style = { width: ICON_SIZES[size], height: ICON_SIZES[size], border: '3px solid transparent' };
  if (unitType.startsWith('inside_unit') || unitType.startsWith('parking_covered')) {
    style.borderColor = colors.red;
  }

  const Icon = unitType.startsWith('parking') ? DirectionsCar : Inventory;
  let img = <Icon color="secondary" style={style} />;

  if (unitType.includes('climate_controlled')) {
    img = <Badge badgeContent={<Thermostat />} classes={{ badge: classes.topLeft }}>{img}</Badge>;
  } else if (unitType === 'heat_only') {
    img = <Badge badgeContent={<Flare />} classes={{ badge: classes.topLeft }}>{img}</Badge>;
  } else if (unitType === 'cooled_only') {
    img = <Badge badgeContent={<AcUnit />} classes={{ badge: classes.topLeft }}>{img}</Badge>;
  } else if (unitType.includes('with_electric')) {
    img = <Badge badgeContent={<Power />} classes={{ badge: classes.topLeft }}>{img}</Badge>;
  }

  if (locked) {
    img = <Badge badgeContent={<Lock />}>{img}</Badge>;
  }

  return img;
};

UnitImage.defaultProps = {
  locked: false,
  size: 'md',
};

UnitImage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  locked: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(ICON_SIZES)),
  unitType: PropTypes.string.isRequired,
};

export default withStyles(styles)(UnitImage);
