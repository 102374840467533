import moment from 'moment';
import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import {
  cancelMoveOut,
  fetchUnits,
  moveOut,
} from '../../services/units';
import {
  constants,
  fetchUnitsSuccess,
  fetchUnitsError,
  moveOutError,
  moveOutSuccess,
  cancelMoveOutError,
  cancelMoveOutSuccess,
  updateUnit,
} from './reducer';

import { appMsg } from '../AppWrapper/reducer';
import { getMagicKey } from '../Auth/selectors';

function* fetchUnitsSaga() {
  const magicKey = yield select(getMagicKey);
  const { result, error } = yield call(fetchUnits, magicKey);
  if (error) {
    yield put(fetchUnitsError(error));
  } else {
    yield put(fetchUnitsSuccess(result));
  }
}

function* moveOutSaga(action) {
  const { result, error } = yield call(moveOut, action.payload);
  if (error) {
    yield put(moveOutError(error));
    // If Move-Out fails, display error message
    // eslint-disable-next-line
    yield put(appMsg(error._error))
  } else {
    window.gtag('event', 'move_out', {
      unit_id: action.payload.unit_id,
      expected_move_out_date: action.payload.date,
    });

    yield put(moveOutSuccess(result));
    // If Move-Out is successful, update move_out_date
    yield put(updateUnit({
      id: action.payload.unit_id,
      move_out_date: moment(action.payload.date).format(moment.HTML5_FMT.DATE),
    }));
  }
}

function* cancelMoveOutSaga(action) {
  const { result, error } = yield call(cancelMoveOut, action.payload);
  if (error) {
    yield put(cancelMoveOutError(error));
    // If Cancel Move-Out fails, display error message
    // eslint-disable-next-line
    yield put(appMsg(error._error))
  } else {
    window.gtag('event', 'move_out', {
      unit_id: action.payload.unit_id,
      expected_move_out_date: null,
    });

    yield put(cancelMoveOutSuccess(result));
    // If Cancel Move-Out is successful, clear move_out_date
    yield put(updateUnit({
      id: action.payload.unit_id,
      move_out_date: null,
    }));
  }
}

function* unitsSaga() {
  yield takeLatest(constants.FETCH_UNITS, fetchUnitsSaga);
  yield takeEvery(constants.MOVE_OUT, moveOutSaga);
  yield takeEvery(constants.CANCEL_MOVE_OUT, cancelMoveOutSaga);
}

export default unitsSaga;
