import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { signLease } from '../../services/lease';
import { getMagicKey } from '../Auth/selectors';

export const constants = {
  FETCH_LEASE: 'containers/LeaseAgreement/FETCH_LEASE',
  FETCH_LEASE_ERROR: 'containers/LeaseAgreement/FETCH_LEASE_ERROR',
  FETCH_LEASE_SUCCESS: 'containers/LeaseAgreement/FETCH_LEASE_SUCCESS',
  ACCEPT_LEASE_SUCCESS: 'containers/LeaseAgreement/ACCEPT_LEASE_SUCCESS',
  FETCH_NEW_LEASE: 'containers/LeaseAgreement/FETCH_NEW_LEASE',
  GENERATE_LEASE: 'containers/LeaseAgreement/GENERATE_LEASE',
  UPDATE_LEASE_STATUS: 'containers/LeaseAgreement/UPDATE_LEASE_STATUS',
};

export const fetchLease = createAction(constants.FETCH_LEASE);
export const fetchLeaseError = createAction(constants.FETCH_LEASE_ERROR);
export const fetchLeaseSuccess = createAction(constants.FETCH_LEASE_SUCCESS);
export const generateLease = createAction(constants.GENERATE_LEASE);
export const updateLeaseStatus = createAction(constants.UPDATE_LEASE_STATUS);

const initialState = fromJS({
  data: {},
  error: null,
  loaded: false,
});

export default handleActions({
  [fetchLease]: (state) => state
    .set('error', null)
    .set('loaded', false),

  [generateLease]: (state) => state
    .set('error', null)
    .set('loaded', false),

  [fetchLeaseSuccess]: (state, action) => state
    .set('data', fromJS(action.payload))
    .set('loaded', true),

  [fetchLeaseError]: (state, action) => state
    .set('error', action.payload),

  [updateLeaseStatus]: (state, action) => state
    .setIn(['data', 'contract_details', 'status'], action.payload),

}, initialState);

export const signLeaseThunk = (payload) =>
  (dispatch, getState) => signLease(payload, getMagicKey(getState())).then(() => {
    dispatch(updateLeaseStatus('signed'));
  });
