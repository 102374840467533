import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { RadioGroup } from 'redux-form-material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Typography } from 'material-ui';
import { UsaStates } from 'usa-states';

import GeneralForm from '../../../components/Form';
import SelectField from '../../../components/Form/selectField';
import { createAccountProfileThunk, updateAccountProfileThunk } from '../reducer';
import { logout } from '../../Auth/reducer';
import { getLoggedIn, getUserInfo } from '../../Auth/selectors';
import { required, email, minLength, equalsField } from '../../../utils/validations';
import { phoneMask, phoneFormat, stateLicenseMask } from '../../../constants';

const { states } = new UsaStates();
const minLength8 = minLength(8);
const minPhoneLength = minLength(11, 'Phone Number must contain at least 10 digits', /\D/g);
const confirmPassword = equalsField('password');

const renderReadOnly = ({ input }) => (
  <Typography style={{ display: 'inline', fontWeight: 'bold' }} type="caption">{input.value}</Typography>
);

const minimumFields = [{
  name: 'first_name',
  label: 'First Name',
  required: true,
  validate: [required],
}, {
  name: 'last_name',
  label: 'Last Name',
  required: true,
  validate: [required],
}, {
  name: 'email',
  label: 'Email',
  required: true,
  fullWidth: true,
  validate: [required, email],
}];

const defaultFields = [
  ...minimumFields,
  {
    custom: (
      <Grid>
        <Typography style={{ display: 'inline' }} type="caption">An email has been sent to &quot;</Typography>
        <Field component={renderReadOnly} name="unconfirmed_email" />
        <Typography style={{ display: 'inline' }} type="caption">&quot; to confirm your change of email address. Please follow the instructions in the email to complete the update.</Typography>
      </Grid>
    ),
    name: 'unconfirmed_email',
    submittable: false,
  },
  {
    custom: (
      <FormControl margin="normal" key="license_state">
        <InputLabel htmlFor="license_state" shrink>State</InputLabel>
        <Field
          name="license_state"
          component={SelectField}
          native
        >
          <option />
          {
            states.map(item => (
              <option key={item.abbreviation} value={item.abbreviation}>{item.name}</option>
            ))
          }
        </Field>
      </FormControl>
    ),
    name: 'license_state',
  },
  {
    name: 'drivers_license_number',
    label: 'Driver\'s License / State ID',
  },
  {
    custom: (
      <FormControl fullWidth key="military" margin="normal">
        <InputLabel className="staticLabel margin-center no-wrap" htmlFor="military">Are you or your spouse active in the US military?</InputLabel>
        <Field
          className="horizontal margin-center"
          component={RadioGroup}
          name="military"
          parse={(value) => value === 'true'}
          validate={required}
        >
          <FormControlLabel control={<Radio />} label="Yes" name="military" value />
          <FormControlLabel control={<Radio />} label="No" name="military" value={false} />
        </Field>
      </FormControl>
    ),
    name: 'military',
  },
  {
    custom: (
      <FormControl fullWidth margin="normal" key="military_branch">
        <InputLabel className="required" htmlFor="military_branch" required shrink>Service Branch</InputLabel>
        <Field
          name="military_branch"
          component={SelectField}
          validate={required}
        >
          <MenuItem value="Air Force">Air Force</MenuItem>
          <MenuItem value="Army">Army</MenuItem>
          <MenuItem value="Coast Guard">Coast Guard</MenuItem>
          <MenuItem value="Marine Corps">Marine Corps</MenuItem>
          <MenuItem value="National Guard">National Guard</MenuItem>
          <MenuItem value="Navy">Navy</MenuItem>
          <MenuItem value="Space Force">Space Force</MenuItem>
        </Field>
      </FormControl>
    ),
    name: 'military_branch',
  },
  {
    fullWidth: true,
    name: 'officer_name',
    label: 'Commanding Officer Name',
    required: true,
    validate: [required],
  },
  {
    name: 'officer_phone',
    label: 'Officer Phone #',
    required: true,
    mask: phoneMask,
    pipe: phoneFormat,
    validate: [required, minPhoneLength],
  },
  {
    name: 'officer_email',
    label: 'Officer Email',
    required: true,
    validate: [required, email],
  },
];

const accountEditFields = defaultFields.map((field) => (field.name === 'first_name' || field.name === 'last_name' ? {
  ...field,
  disabled: true,
  required: false,
  submittable: false,
} : field));
const editFields = accountEditFields.map((field) => (field.name === 'email' ? {
  ...field,
  disabled: true,
  required: false,
  submittable: false,
} : field));

const unmaskedCreateFields = minimumFields.map((field) => (field.name === 'email' ? {
  ...field,
  autoComplete: 'new-password',
} : field)).concat({
  name: 'password',
  label: 'Password',
  autoComplete: 'new-password',
  required: true,
  // Server will return a detailed error message so we only need minimal validation here.
  validate: [required, minLength8],
}, {
  name: 'confirm_password',
  label: 'Confirm Password',
  required: true,
  validate: [required, minLength8, confirmPassword],
});
const maskedCreateFields = unmaskedCreateFields.map((field) => (field.name === 'password' || field.name === 'confirm_password' ? { ...field, password: true } : field));

let boundActions;
const profileForm = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: 'profileForm',
})(GeneralForm);
const selector = formValueSelector('profileForm');

const getFields = createSelector(
  (state) => selector(state, 'license_state'),
  (state, fields) => fields,
  (licenseState, fields) => fields.map(f => (f.name === 'drivers_license_number' ? { ...f, ...stateLicenseMask[licenseState] } : f)),
);

const mapStateToProps = (state, props) => {
  const loggedIn = getLoggedIn(state);
  const userInfo = getUserInfo(state);

  let fields = defaultFields;
  const defaultValues = userInfo ? userInfo.reduce((obj, value, key) => {
    // Only include values relevant to this form (not nested addresses / contacts / alternates)
    if (key === 'id' || fields.findIndex((field) => field.name === key) >= 0) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value;
    }
    return obj;
  }, {}) : {};

  if (loggedIn) {
    fields = props.includeQueryParams ? editFields : accountEditFields;
  } else if (props.isCreate) {
    fields = props.showPassword ? unmaskedCreateFields : maskedCreateFields;
  }

  // If Query Params are passed, auto-submit form on load
  let { skip } = props;
  let forceSubmit = false;
  let emailTextVisible = false;
  if (props.includeQueryParams) {
    const overrideValues = new URLSearchParams(props.location.search);
    fields.find((field) => {
      if (overrideValues.has(field.name)) {
        forceSubmit = true;

        // User came in from G5 but they are already logged in
        if (field.name === 'email' && defaultValues.email && boundActions) {
          // User is logged in under a different email
          if (overrideValues.get(field.name) !== defaultValues.email) {
            // Logout and stop processing
            boundActions.logout({ redirect: false });
            return true;
          }

          // User is logged in under same email
          skip = true;
        }

        defaultValues[field.name] = overrideValues.get(field.name);
      }
      return false;
    });
  } else if (
    loggedIn &&
    defaultValues.unconfirmed_email &&
    defaultValues.unconfirmed_email.toLowerCase() !== defaultValues.email.toLowerCase()) {
    // If User has unconfirmed email remind them to check their email and confirm
    emailTextVisible = true;
  }

  const isMilitary = selector(state, 'military');
  if (typeof defaultValues.military === 'undefined') {
    defaultValues.military = false;
  }

  return {
    autoComplete: props.isCreate ? 'off' : undefined,
    children: props.children,
    fields: getFields(state, fields),
    forceSubmit,
    initialValues: defaultValues,
    isCreate: props.isCreate || !loggedIn,
    shrinkLabels: true,
    skip,
    isVisible: {
      unconfirmed_email: emailTextVisible,
      military_branch: isMilitary,
      officer_name: isMilitary,
      officer_phone: isMilitary,
      officer_email: isMilitary,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  boundActions = bindActionCreators({
    create: createAccountProfileThunk,
    update: updateAccountProfileThunk,
    logout,
  }, dispatch);
  if (ownProps.onCancel) boundActions.cancel = ownProps.onCancel;

  return { actions: boundActions };
};

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(profileForm));

