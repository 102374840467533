import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import Button from 'material-ui/Button';
import { withStyles, Typography } from 'material-ui';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sendResetEmailThunk } from './reducer';
import { getSendError, getSending, getSendComplete } from './selectors';

import { required, email } from '../../utils/validations';

const styles = () => ({
  field: {
    maxWidth: '100%',
    width: '100%',
  },
});

class ForgotPasswordForm extends Component {
  onSubmit(values) {
    this.props.actions.sendResetEmail({
      ...values,
      isFirst: this.props.isFirst,
    });
  }

  render() {
    const {
      sendError,
      sending,
      sendComplete,
      handleSubmit,
      classes,
      onClose,
    } = this.props;

    if (sendComplete) {
      return (
        <div>
          <h4 className="modal-title">An email has been sent.</h4>
          <Typography paragraph>
            {
              this.props.isFirst ?
              'Please check your email to verify your account before logging in.' :
              'Please check your email for password reset instructions.'
            }
          </Typography>
          <div className="modal-footer">
            <Button color="secondary" onClick={onClose} >OK</Button>
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="left-align-form">
        <h4 className="modal-title">Please enter your email.</h4>
        <div>
          <Field
            name="email"
            component={TextField}
            label="Email"
            validate={[required, email]}
            className={classes.field}
          />
        </div>
        {sendError && <p className="forgot-password-error">{sendError}</p>}
        <div className="modal-footer">
          <Button
            type="submit"
            disabled={sending}
            raised
            color="secondary"
          >
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

ForgotPasswordForm.defaultProps = {
  isFirst: false,
  onClose: undefined,
  sendError: undefined,
  sending: false,
  sendComplete: false,
};

ForgotPasswordForm.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  onClose: PropTypes.func,
  sendError: PropTypes.node,
  sending: PropTypes.bool,
  sendComplete: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  sendError: getSendError(state),
  sending: getSending(state),
  sendComplete: getSendComplete(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    sendResetEmail: sendResetEmailThunk,
  }, dispatch),
});

// Decorate with redux-form
export default reduxForm({
  form: 'forgotPasswordForm',
})(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)));

