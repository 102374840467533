import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchUnit(unitId) {
  return yield call(
    fetchData,
    `/api/customer/v1/units/${unitId}`,
    {
      method: 'GET',
    },
  );
}

export function createReservation(data) {
  return fetchData(
    '/api/customer/v1/reserve',
    {
      method: 'POST',
      body: data,
    },
  );
}

export function createMoveIn(data) {
  return fetchData(
    '/api/customer/v1/move_in',
    {
      method: 'POST',
      body: data,
    },
  );
}

export function fetchMoveInCosts(data) {
  const autoPayQuery = data.auto_pay ? `&auto_pay=${data.auto_pay}` : '';
  const promoCodeQuery = data.promotion_code ? `&promotion_code=${data.promotion_code}` : '';
  return fetchData(
    `/api/customer/v1/units/${data.unit_id}/moveincosts?insurance_id=${data.insurance_id}${autoPayQuery}${promoCodeQuery}`,
    {
      method: 'GET',
    },
  );
}
