import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { TextField } from 'redux-form-material-ui';

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  );
};

TextMaskCustom.defaultProps = {
  inputRef: undefined,
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.object,
};

const MaskedTextField = props => {
  const {
    mask,
    pipe,
    style,
    ...other
  } = props;
  return (
    <TextField
      {...other}
      InputProps={{
        ...other.InputProps,
        inputComponent: TextMaskCustom,
      }} // eslint-disable-next-line
      inputProps={{ mask, pipe, style }}
    />
  );
};

MaskedTextField.defaultProps = {
  pipe: undefined,
  style: undefined,
};

MaskedTextField.propTypes = {
  mask: PropTypes.func.isRequired,
  pipe: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
};

export default MaskedTextField;
