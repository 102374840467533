import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from 'material-ui';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ input, meta, ...props }) => (
  // Necessary to wrap this in order to trigger 'onChange' event for some reason
  <React.Fragment>
    <ReCAPTCHA {...input} {...props} />
    {meta.submitFailed && meta.invalid && <FormHelperText error>{meta.error}</FormHelperText>}
  </React.Fragment>
);
Captcha.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.node,
  }).isRequired,
};

export default Captcha;
