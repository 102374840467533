import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Hidden,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from 'material-ui';
import {
  Link,
  Route,
  withRouter,
} from 'react-router-dom';
import moment from 'moment';

import PaymentForm from './PaymentMethods/paymentForm';
import Loading from '../../components/Loading';
import CurrentBill from './CurrentBill';
import MakePayment from './CurrentBill/makePayment';
import {
  getAccountBalance,
  getExpectedMonthlyCharge,
  getInvoices,
  getLineItems,
  getLoading,
  getLoaded,
} from './selectors';
import {
  getLoaded as getUnitsLoaded,
  getOccupiedFacilities,
  getSelectedFacility,
  getSelectedUnit,
  getError,
} from '../MyUnits/selectors';
import { fetchUnits } from '../MyUnits/reducer';
import { fetchPaymentAuthorizationMessageThunk, updateSelectedFacilityUnit } from './reducer';
import UnitIcon from '../../components/UnitIcon';
import { currency } from '../../utils/format';
import { routePropTypes } from '../../utils/routes';
import { getAccountId } from '../Auth/selectors';
import './styles.css';

const styles = (theme) => ({
  /*
    Column logic:
    >= 1280 px, display all 4 columns on one line
    600 - 960 px UI switches to vertical layout, so we can still display on one line
    < 600 px or 960 - 1280 px switch to 2 column layout to save space
  */

  col1: {
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '16%',
    },
  },
  col2: {
    width: '50%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '28%',
    },
  },
  col3: {
    alignSelf: 'center',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '28%',
    },
  },
  col4: {
    alignSelf: 'center',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '28%',
    },
  },
  container: {
    height: '100%',
  },
  facilityTitle: {
    fontWeight: 'bold',
  },
  gutterBottom: {
    marginBottom: '3em',
  },
  header: {
    borderBottom: '1px solid lightgrey',
  },
  helpText: {
    textDecoration: 'underline',
    cursor: 'help',
  },
  statusMessage: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  tooltip: {
    alignItems: 'baseline',
    columnGap: '1em',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    maxWidth: '335px',
  },
  unitBlock: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 800,
    padding: '15px 30px 10px',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 8,
    },
  },
});

class BillPay extends Component {
  constructor(props) {
    super(props);

    const qParams = new URLSearchParams(props.location.search);
    this.state = {
      account_id: qParams.get('account_id'),
      occupancy_id: qParams.get('occupancy_id'),
    };

    this.handleCancelPayment = this.handleCancelPayment.bind(this);
  }

  componentDidMount() {
    if (!this.props.unitsLoaded) {
      this.props.actions.fetchUnits();
    } else if (!this.props.loaded && !this.props.loading) {
      this.fetchBilling();
    }

    this.props.actions.fetchPaymentAuthorizationMessage();
  }

  shouldComponentUpdate(props) {
    let shouldUpdate = true;

    if (!props.loaded && !props.loading && props.unitsLoaded && this.fetchBilling(props)) {
      shouldUpdate = false;
    }

    if (props.loaded && props.balance <= 0 && props.unit && !props.unit.get('lease_signed') && !props.unit.get('moved_out')) {
      props.history.push(`/sign-lease?account_id=${this.state.account_id || props.accountId}&occupancy_id=${this.state.occupancy_id || props.unit.get('occupancy_id')}`);
      shouldUpdate = false;
    }

    return shouldUpdate;
  }

  fetchBilling(p) {
    const props = p || this.props;
    const { actions, facilities, unit } = props;
    const occupancyId = this.state.occupancy_id || (unit && unit.get('occupancy_id').toString());

    let unitId;
    const facility = occupancyId && facilities.find(f =>
      f.get('units').find(u => {
        unitId = u.get('id');
        return (u.get('occupancy_id') || '').toString() === occupancyId;
      }));

    return facility && actions.updateSelectedFacilityUnit({
      facility_id: facility.get('id'),
      unit_id: unitId,
    });
  }

  handleCancelPayment() {
    this.props.history.goBack();
  }

  renderUnit() {
    const {
      classes,
      facility,
      lineItems,
      monthlyCharge,
      moveInDate,
      unit,
    } = this.props;

    const monthlyCharges = (lineItems ? lineItems.toJS() : []).map((item) => (
      <React.Fragment>
        <Typography color="primary" noWrap>{item.description}</Typography>
        <Typography color="primary" type="body2" align="right">{currency(item.chg)}</Typography>
      </React.Fragment>
    ));

    return facility && unit && (
      <Paper className={classes.gutterBottom}>
        <Grid container className={classes.unitBlock}>
          <Grid className={classes.header} item xs={12}>
            <Grid alignItems="baseline" container justify="center" spacing={16}>
              <Grid item>
                <Typography className={classes.facilityTitle} type="title">{facility.get('site_code') || `RD${facility.get('store_number')}`}:</Typography>
              </Grid>
              <Grid item>
                <Typography type="subheading">{facility.get('full_address')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.col1}>
            <UnitIcon key={`${unit.get('width')}-${unit.get('length')}`} width={unit.get('width')} length={unit.get('length')} />
            <Typography type="subheading" align="center">Unit {unit.get('unit_number')}</Typography>
          </Grid>
          <Grid item className={classes.col2}>
            <Typography align="center" type="title">{`${unit.get('width')}' x ${unit.get('length')}'`}</Typography>
            <Typography align="center" color="textSecondary">{unit.get('unit_type_name') || unit.get('unit_type')}</Typography>
            <Typography align="center" color="textSecondary">{unit.get('width') * unit.get('length')} sq. ft.</Typography>
          </Grid>
          <Grid item className={classes.col3}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              disableTriggerTouch
              title={monthlyCharges}
            >
              <Typography className={classes.helpText} type="headline" align="center" color="secondary">{currency(monthlyCharge)}</Typography>
            </Tooltip>
            <Typography color="textSecondary" align="center">per month</Typography>
          </Grid>
          <Grid item className={classes.col4}>
            <div>
              <Hidden smDown>
                <Typography type="headline" align="center"><b>Move-In Date</b></Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography type="title" align="center"><b>Move-In Date</b></Typography>
              </Hidden>
              <Typography color="secondary" align="center">{moment.utc(moveInDate).format('MMMM D, YYYY')}</Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  render() {
    const {
      balance,
      classes,
      error,
      loading,
      loaded,
      match,
      unitsLoaded,
    } = this.props;

    if (!unitsLoaded) {
      return error ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={24}
        >
          <Grid item xs={12}>
            <Typography align="center" type="display1" gutterBottom>Link expired.</Typography>
            <Typography align="center" type="display1">Please <Link className="link" to="/">Login</Link> or contact Customer Service at <a className="link" href="tel:9705106860">970-510-6860</a> for assistance.</Typography>
          </Grid>
        </Grid>
      ) : <Loading />;
    }

    const loadingDom = loading ? <Loading /> :
    <Typography type="headline" color="textSecondary" align="center"> No billing data </Typography>

    const content = balance <= 0 ? <Grid className={classes.statusMessage}><Typography align="center" type="display1">Congratulations, you&apos;re all paid up!</Typography></Grid> : (
      <Paper className="app-paper">
        { this.renderUnit() }
        <Route exact path={match.path} component={CurrentBill} />
        <Route exact path={`${match.path}/payments`} component={MakePayment} />
        <Route
          exact
          path={`${match.path}/payments/:itemId`}
          render={(routeProps) => (
            <PaymentForm {...routeProps} onCancel={this.handleCancelPayment} />
          )}
        />
      </Paper>
    );

    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={24}
      >
        <Grid className={classes.container} item xl={6} lg={8} md={10} xs={12}>
          {loaded ? content : loadingDom}
        </Grid>
      </Grid>
    );
  }
}

BillPay.defaultProps = {
  accountId: undefined,
  balance: 0,
  error: undefined,
  facilities: null,
  loading: false,
  loaded: false,
  unitsLoaded: false,
};

BillPay.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actions: PropTypes.shape({
    fetchPaymentAuthorizationMessage: PropTypes.func,
    fetchUnits: PropTypes.func,
    updateSelectedFacilityUnit: PropTypes.func,
  }).isRequired,
  balance: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.any,
  facilities: PropTypes.object,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  unitsLoaded: PropTypes.bool,
  ...routePropTypes,
};

const mapStateToProps = (state) => {
  const invoices = getInvoices(state);
  const moveInDate = invoices && invoices.size > 0 ? invoices.last().get('due_at') : null;

  return {
    accountId: getAccountId(state),
    balance: parseFloat(getAccountBalance(state) || '0'),
    error: getError(state),
    facility: getSelectedFacility(state),
    facilities: getOccupiedFacilities(state),
    lineItems: getLineItems(state),
    loaded: getLoaded(state),
    loading: getLoading(state),
    monthlyCharge: getExpectedMonthlyCharge(state),
    moveInDate,
    unit: getSelectedUnit(state),
    unitsLoaded: getUnitsLoaded(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchPaymentAuthorizationMessage: fetchPaymentAuthorizationMessageThunk,
    fetchUnits,
    updateSelectedFacilityUnit,
  }, dispatch),
});

const billingContainer = connect(mapStateToProps, mapDispatchToProps)(BillPay);
export default withRouter(withStyles(styles)(billingContainer));
