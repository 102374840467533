import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { flatten, find } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import URLSearchParams from 'url-search-params';
import IdleTimer from 'react-idle-timer';

import { MuiThemeProvider } from 'material-ui/styles';
import Reboot from 'material-ui/Reboot';
import { ButtonBase, Grid, IconButton, Modal, Typography, withStyles } from 'material-ui';
import { Business } from 'material-ui-icons';

import Theme from '../../styles/theme';
import AppHeader from '../../components/Header';
import AppDrawer from '../../components/AppDrawer';
import MessageBox from '../../components/MessageBox';
import SearchMap from '../SearchMap';
import { getAccountId, getLoggedIn } from '../Auth/selectors';
import { routePropTypes } from '../../utils/routes';
import { setAppLocked, setReturnUrl, setMapModalOpen } from './reducer';
import { generateLease } from '../LeaseAgreement/reducer';
import {
  alertConfirmSuccessThunk,
  alertInvalidResetTokenThunk,
  alertTimedoutThunk,
  login,
  setMagicKey,
} from '../Auth/reducer';
import { getAppLocked, getMapModalOpen } from './selectors';
import { getAllFacilities } from '../MyUnits/selectors';
import { ScrollContext } from '../../utils/contexts';
import './styles.css';

const messageBoxHeight = 30;
const styles = (theme) => ({
  root: {
    width: '100%',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100vw',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    height: '90%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      padding: 0,
    },
  },
  appBar: {
    position: 'fixed',
    right: 0,
    left: 0,
    zIndex: 1200,
  },
  scrollbarContent: {
    height: '-webkit-fill-available !important',
    marginTop: 48 + theme.spacing.unit,
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64 + theme.spacing.unit,
    },
  },
  main: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    width: '100%',
    minHeight: 'calc(100% - 38px - 48px)',
    display: 'flex',
    marginTop: messageBoxHeight + theme.spacing.unit,
  },
  mainMap: {
    height: 'calc(100% - 38px)',
  },
  messageBox: {
    height: '100%',
  },
  flex: {
    flexDirection: 'column',
  },
  footer: {
    gap: '1em',
  },
  footerBar: {
    backgroundColor: Theme.palette.background.dark,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  footerText: {
    color: 'white',
    fontSize: '16px',
  },
  iconButton: {
    backgroundColor: Theme.palette.secondary.main,
    height: '40px',
    width: '40px',
  },
});

const scrollbars = React.createRef();
class App extends Component {
  componentWillMount() {
    const locationPath = this.props.location.pathname;
    if (
      locationPath.startsWith('/resetPassword') ||
      locationPath.startsWith('/maintenance')
    ) {
      return;
    }

    if (!this.props.loggedIn) {
      if (locationPath !== '/') {
        this.props.actions.setReturnUrl(locationPath);
      }

      // If user confirmation query params are passed don't try to login using stored session
      const qParams = new URLSearchParams(this.props.location.search);
      const token = qParams.get('token');
      if (token || (qParams.has('user_id') && qParams.has('confirmation_token'))) {
        if (token) {
          this.props.actions.setMagicKey(token);
        }

        return;
      }

      /* try to load user from stored token */
      this.props.actions.login();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const confirmSuccess = urlParams.get('account_confirmation_success');
    const invalidResetToken = urlParams.get('invalid_reset_password_token');
    const timedout = urlParams.get('timedout');

    if (confirmSuccess) {
      this.props.actions.alertConfirmSuccess();
    }
    if (invalidResetToken) {
      this.props.actions.alertInvalidResetToken();
    }
    if (timedout) {
      this.props.actions.alertTimedout();
    }
  }

  componentWillReceiveProps(props) {
    // Only perform this check if facilities have changed
    if (props.loggedIn && props.facilities && props.facilities !== this.props.facilities) {
      this.checkUnitsForLease(props.facilities.filter(f => f.get('units').size > 0).toJS());
    }
  }

  onIdle = () => {
    this.props.actions.alertTimedout();
  };

  checkUnitsForLease(facilities) {
    const { pathname } = this.props.location;

    const allUnits = flatten(facilities.map((f) => f.units));
    const unitForLease = find(
      allUnits,
      (u) => u.system_status === 'occupied' && !u.lease_signed && !u.moved_out,
    );

    if (unitForLease) {
      this.props.actions.setAppLocked(true);
      if (!this.prevPath) {
        // If they came directly here from a new browser, pathname will already be "/lease".
        // In that case, redirect to default path.
        this.prevPath = pathname.startsWith('/lease') ? '/' : pathname;
      }

      if (!unitForLease.lease_envelope_id) {
        // Lease has not yet been generated. Generate new Lease before redirecting.
        this.props.actions.generateLease({
          account_id: this.props.accountId,
          updateUnit: true,
          unit_id: unitForLease.id,
          occupancy_id: unitForLease.occupancy_id,
          redirect: true,
        });
        return;
      }

      const nextLease = `/lease/${unitForLease.lease_envelope_id}`;
      if (!pathname.startsWith(nextLease) && !pathname.startsWith('/account')) {
        this.props.history.replace(nextLease);
      }
    } else {
      this.props.actions.setAppLocked(false);
      // Make sure we only navigate back ONCE
      if (pathname.startsWith('/lease') && this.prevPath) {
        this.props.history.replace(this.prevPath);
        this.prevPath = null;
      }
    }
  }

  renderMapModal = () => {
    const { classes, mapModalOpen } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={mapModalOpen}
        onClose={() => this.props.actions.setMapModalOpen(false)}
      >
        <Grid className={classes.modal}>
          <SearchMap
            onCancel={() => this.props.actions.setMapModalOpen(false)}
            title=""
          />
        </Grid>
      </Modal>
    );
  };

  render() {
    const {
      classes,
      location,
      children,
    } = this.props;

    let mainClasses = classes.main;
    let footer = (
      <Grid
        className={classes.footerBar}
        container
      >
        <Grid item xs={12}>
          <Grid alignItems="center" className={classes.footer} container justify="center">
            <Grid item>
              <IconButton className={classes.iconButton} href="https://www.google.com/maps/place/Storage+Rentals+of+America+-+HQ/@26.6894278,-80.0549289,16z/data=!3m2!4b1!5s0x88d8d6f463ab0101:0x3b37000fcdb01ef0!4m6!3m5!1s0x88d8d613d06a98c7:0x37b62b3a202750b8!8m2!3d26.6894278!4d-80.0549289!16s%2Fg%2F11bvcvsf81" rel="noreferrer" target="_blank" title="Red Dot HQ">
                <Business color="primary" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                className={classes.footerText}
                style={{ fontWeight: 'bold' }}
                type="body1"
              >
                Red Dot Storage
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.footerText} type="body1">
                2751 S Dixie Hwy #450, West Palm Beach, FL 33405
              </Typography>
            </Grid>
            <Grid item>
              <ButtonBase href="tel:9705106860">
                <Typography className={`link ${classes.footerText}`} type="body1">
                  970-510-6860
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item>
              <Link to="/contact-us">
                <Typography className={`link ${classes.footerText}`} type="body1">
                  Email Us
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    if (location.pathname.startsWith('/selectFacility')) {
      mainClasses += ` ${classes.mainMap}`;
      footer = null;
    }

    return (
      <MuiThemeProvider theme={Theme}>
        <IdleTimer
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * 60 * 60}
        />
        <Reboot />
        <div className={classes.root}>
          <div id="appFrame" className={classes.appFrame}>
            <div className={classes.appBar}>
              <AppHeader appLocked={this.props.appLocked} />
              <MessageBox />
            </div>
            <AppDrawer />
            <Scrollbars className={classes.scrollbarContent} ref={scrollbars}>
              <ScrollContext.Provider value={scrollbars}>
                <main className={mainClasses}>{children}</main>
              </ScrollContext.Provider>
              {footer}
            </Scrollbars>
            {this.renderMapModal()}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

App.defaultProps = {
  accountId: undefined,
  appLocked: false,
  facilities: undefined,
  loggedIn: false,
  mapModalOpen: false,
};

App.propTypes = {
  accountId: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  appLocked: PropTypes.bool,
  facilities: PropTypes.object,
  loggedIn: PropTypes.bool,
  mapModalOpen: PropTypes.bool,
  ...routePropTypes,
};
const mapStateToProps = (state) => ({
  accountId: getAccountId(state),
  appLocked: getAppLocked(state),
  loggedIn: getLoggedIn(state),
  mapModalOpen: getMapModalOpen(state),
  facilities: getAllFacilities(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      alertConfirmSuccess: alertConfirmSuccessThunk,
      alertInvalidResetToken: alertInvalidResetTokenThunk,
      alertTimedout: alertTimedoutThunk,
      generateLease,
      login,
      setAppLocked,
      setReturnUrl,
      setMapModalOpen,
      setMagicKey,
    },
    dispatch,
  ),
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
