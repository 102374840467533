import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import { ExpandMore } from 'material-ui-icons';
import moment from 'moment';

import { currency } from '../../../utils/format';
import {
  getAccountBalance,
  getDueAt,
  getTransactions,
  getPastDue,
  getAmountDue,
  getExpectedMonthlyCharge,
  getLineItems,
} from '../selectors';
import { routePropTypes } from '../../../utils/routes';

const styles = () => ({
  spaceAround: {
    justifyContent: 'space-around',
  },
  buttonAmount: {
    fontWeight: 'bold',
  },
  buttonText: {
    whiteSpace: 'break-spaces',
  },
  billingItemsContainer: {
    display: 'grid',
    'grid-template-columns': 'auto 1fr auto',
    gap: '1em',
  },
  expansionPanel: {
    paddingBottom: '1em',
  },
  expansionPanelSummary: {
    justifyContent: 'center',
  },
  expansionPanelHeader: {
    paddingLeft: '32px',
  },
});

class CurrentBill extends React.Component {
  componentDidMount() {
    document.title = this.props.title;
  }

  renderDetailItem = (item) => (
    <React.Fragment>
      <Typography color="textSecondary">{item.date}</Typography>
      <Typography color="textSecondary">{item.description}</Typography>
      <Typography type="body2" color="textSecondary" align="right">{item.chg}</Typography>
    </React.Fragment>
  )

  render() {
    const {
      accountBalance,
      amountDue,
      classes,
      dueAt,
      expectedMonthlyCharge,
      lineItems,
      match,
      transactions,
      pastDue,
    } = this.props;

    const paidAmountDue = (amountDue * 1.0) <= 0.0;
    const numAmountDue = parseFloat(amountDue);
    const numAccountBalance = parseFloat(accountBalance);
    const expectedAmountDue = numAmountDue + parseFloat(expectedMonthlyCharge);
    const expectedAccountBalance = numAccountBalance > 0
      ? currency(numAccountBalance) : currency(expectedAmountDue);
    const dueDate = dueAt ? moment.utc(dueAt).format('MM/DD/YYYY') : 'N/A';

    let chargesOwed = null;
    let lastPaidIndex = transactions.findIndex((transaction) =>
      parseFloat(transaction.get('balance_after_transaction')) === 0);
    if (lastPaidIndex < 0) lastPaidIndex = transactions.size;
    if (lastPaidIndex > 0) {
      let prevBalance = 0;
      chargesOwed = transactions.toJS().slice(0, lastPaidIndex).reverse().map((transaction) => {
        let amount = parseFloat(transaction.amount);
        const newBalance = parseFloat(transaction.balance_after_transaction);
        if (newBalance < prevBalance) {
          if (amount > 0) amount *= -1;
        }
        prevBalance = newBalance;
        return {
          chg: currency(amount),
          description: transaction.message,
          date: moment(transaction.submitted_at, moment.HTML5_FMT.DATE).format('MM/DD/YYYY'),
        };
      });
    }

    const upcomingCharges = lineItems.toJS().map((item) => ({
      chg: currency(item.chg),
      description: item.description,
      date: moment(item.submitted_at, moment.HTML5_FMT.DATE).format('MM/DD/YYYY'),
    }));

    const accountBalanceText = (
      <React.Fragment>
        Pay Account Balance (<span className={classes.buttonAmount}>{expectedAccountBalance}</span>)
      </React.Fragment>
    );

    let paymentButtonText = numAccountBalance > 0 ? 'Pay Account Balance' : 'Pay Next Month\'s Rent';
    if (!paidAmountDue) {
      paymentButtonText = numAccountBalance > numAmountDue ? (
        <React.Fragment>
          Pay Amount Due (<span className={classes.buttonAmount}>{currency(amountDue)}</span>)
        </React.Fragment>
      ) : 'Pay Amount Due';
    }

    return (
      <Grid container direction="column" spacing={16}>
        <Typography type="subheading" color={pastDue ? 'secondary' : 'textSecondary'} align="center">{numAccountBalance === 0 && expectedAmountDue > 0 ? "Next Month's Rent" : 'Account Balance'}</Typography>
        <Typography type="display3" color={pastDue ? 'secondary' : 'textSecondary'} align="center" gutterBottom>{expectedAccountBalance}</Typography>

        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            classes={{ content: classes.expansionPanelSummary }}
            expandIcon={<ExpandMore />}
          >
            <Typography className={classes.expansionPanelHeader} type="subheading" color="primary">Itemized Bill</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid className={classes.billingItemsContainer}>
              {
                chargesOwed && (
                  <React.Fragment>
                    {chargesOwed.map((item) => this.renderDetailItem(item))}
                    { numAmountDue > 0 ? (
                      <React.Fragment>
                        <Typography type="body2">{moment().format('MM/DD/YYYY')}</Typography>
                        <Typography type="body2">Total Amount Due</Typography>
                        <Typography type="body2" align="right">{currency(amountDue)}</Typography>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )
              }
              {
                paidAmountDue || numAccountBalance > numAmountDue ? (
                  <React.Fragment>
                    {upcomingCharges.map((item) => this.renderDetailItem(item))}
                    <Typography type="body2" color="secondary">{dueDate}</Typography>
                    <Typography type="body2" color="secondary">Final Account Balance</Typography>
                    <Typography type="body2" color="secondary" align="right">{expectedAccountBalance}</Typography>
                  </React.Fragment>
                ) : null
              }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Typography type="subheading" color="textSecondary" align="center">Billing Date</Typography>
        <Typography type="title" align="center" paragraph>{dueDate}</Typography>

        <Grid item>
          <Grid container spacing={8}>
            <Grid item xs={paidAmountDue || numAccountBalance === numAmountDue ? 12 : 6}>
              <Button
                fullWidth
                raised
                disabled={expectedAmountDue <= 0}
                size="large"
                color="secondary"
                onClick={() => this.props.history.push(`${match.url}/payments`, { amountDuePayment: !paidAmountDue })}
              >
                <Typography className={classes.buttonText} type="subheading" color="inherit">
                  {paymentButtonText}
                </Typography>
              </Button>
            </Grid>
            {!paidAmountDue && numAccountBalance > numAmountDue &&
            <Grid item xs={6}>
              <Button
                fullWidth
                raised
                size="large"
                color="secondary"
                onClick={() => this.props.history.push(`${match.url}/payments`)}
              >
                <Typography className={classes.buttonText} type="subheading" color="inherit">
                  {accountBalanceText}
                </Typography>
              </Button>
            </Grid>
          }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CurrentBill.defaultProps = {
  accountBalance: 0,
  amountDue: 0,
  dueAt: null,
  expectedMonthlyCharge: 0,
  lineItems: [],
  title: 'Billing',
  transactions: [],
  pastDue: false,
};

CurrentBill.propTypes = {
  accountBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountDue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object.isRequired,
  dueAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  expectedMonthlyCharge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.object.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({
    chg: PropTypes.string,
    description: PropTypes.string,
    submitted_at: PropTypes.string,
  })),
  title: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.string,
    balance_after_transaction: PropTypes.string,
    message: PropTypes.string,
    submitted_at: PropTypes.string,
  })),
  pastDue: PropTypes.bool,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  amountDue: getAmountDue(state),
  accountBalance: getAccountBalance(state),
  dueAt: getDueAt(state),
  expectedMonthlyCharge: getExpectedMonthlyCharge(state),
  lineItems: getLineItems(state),
  pastDue: getPastDue(state),
  transactions: getTransactions(state),
});

const mapDispatchToProps = () => ({
});

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CurrentBill)));
