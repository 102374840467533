import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GeneralForm from '../../../components/Form';
import { updateAccountSettingsThunk } from '../reducer';
import { getUserInfo } from '../../Auth/selectors';
import { required } from '../../../utils/validations';

const unmaskedFields = [{
  fullWidth: true,
  name: 'current_password',
  label: 'Current Password',
  required: true,
  validate: [required],
}, {
  name: 'password',
  label: 'New Password',
  required: true,
  validate: [required],
}, {
  name: 'password_confirmation',
  label: 'Confirm Password',
  required: true,
  validate: [required],
}];
const maskedFields = unmaskedFields.map((field) => ({ ...field, password: true }));

const mapStateToProps = (state, ownProps) => ({
  initialValues: getUserInfo(state).toJS(),
  fields: ownProps.showPassword ? unmaskedFields : maskedFields,
  shrinkLabels: true,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ update: updateAccountSettingsThunk }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'passwordResetForm',
})(GeneralForm)));

