/* eslint-disable camelcase */
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchLease, generateLease } from '../../services/lease';
import {
  constants,
  fetchLeaseSuccess,
  fetchLeaseError,
} from './reducer';
import { updateLeaseEnvelopeId } from '../MyUnits/reducer';
import { getMagicKey } from '../Auth/selectors';

function* generateLeaseSaga(action) {
  const { updateUnit, unit_id, ...payload } = action.payload;
  if (!payload.occupancy_id) payload.unit_id = unit_id;

  const magicKey = yield select(getMagicKey);
  const { result, error } = yield call(generateLease, payload, magicKey);

  if (error) {
    yield put(fetchLeaseError(error.message));
  } else {
    yield put(fetchLeaseSuccess(result));

    if (updateUnit && unit_id) {
      yield put(updateLeaseEnvelopeId({ unit_id, lease_envelope_id: parseInt(result.id, 10) }));
    }
  }
}

function* fetchLeaseSaga(action) {
  const { envelope_id: envelopeId, ...payload } = action.payload;
  const { result, error } = yield call(fetchLease, envelopeId);

  if (error) {
    yield put(fetchLeaseError(error.message));
  } else if (!result.contract_details) {
    // Lease is outdated. Generate a new one and continue.
    yield call(generateLeaseSaga, { type: constants.GENERATE_LEASE, payload });
  } else {
    yield put(fetchLeaseSuccess(result));
  }
}

function* leaseSaga() {
  yield takeLatest(constants.FETCH_LEASE, fetchLeaseSaga);
  yield takeLatest(constants.GENERATE_LEASE, generateLeaseSaga);
}

export default leaseSaga;
