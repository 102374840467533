/* eslint no-underscore-dangle: 0 */
import { take, put, call, fork, cancel, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';

import {
  constants,
  logout,
  loginSuccess,
  loginError,
  bearerAuthSuccess,
  bearerAuthError,
  userDataSuccess,
} from './reducer';
import {
  authenticate,
  login,
  removeAuthHeader,
  refreshToken,
  refreshBearerToken,
  setAuthTokens,
  removeAuthTokens,
  fetchUser,
} from '../../services/auth';
import { fetchUnits } from '../MyUnits/reducer';
import { fetchFacilities } from '../SearchMap/reducer';
import { setAppLocked } from '../AppWrapper/reducer';

// Expire token in 13 days
const EXPIRES_IN = 13 * 24 * 60 * 60 * 1000;

// Token expires in 5 mins, so refresh every 4
const BEARER_EXPIRES_IN = 4 * 60 * 1000;
const BEARER_RETRY_TIMEOUT = 10 * 1000;
const BEARER_TOKEN_REFRESH_RETRIES = 5;

let config = {};

/**
  PARAM
    data null/{pwd, username}
  PURPOSE
    Run token-based authentication when data is null
    Run un/pwd based authentication when data is provided
*/
function* authorize(data) {
  try {
    const { result, error } = data ? yield call(authenticate, data) : yield call(refreshToken);
    if (error) {
      // Don't show error messgae when login with token
      const refinedError = data ? error._error : '';
      yield put(loginError(refinedError));
    } else {
      config.email = result.email;
      window.gtag('config', process.env.GTAG_ID, config);

      yield put(loginSuccess(result));
      yield put(fetchUnits());
      yield put(fetchFacilities());
    }
  } catch (error) {
    yield put(loginError(error.message));
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
    }
  }
}

function* refreshWhenExpire() {
  while (true) {
    yield call(delay, EXPIRES_IN)
    // console.log('Refreshing Token...');
    const { error } = yield call(refreshToken);
    // authorization failed, either by the server or the user signout
    if (error) {
      // console.log('Error while refreshing token.');
      yield put(logout());
    }
  }
}

function* bearerAuthorization(data) {
  try {
    // console.log('Bearer Auth Start');
    const { result, error } = data ? yield call(login, data) : yield call(refreshBearerToken);
    if (error) {
      // Don't show error message when login with token
      yield put(bearerAuthError(data ? error.message : ''));
    } else {
      // Notify Google Analytics of login
      config.user_id = result.userId;
      window.gtag('config', process.env.GTAG_ID, config);
      window.gtag('event', 'login', result);

      // Set tokens
      yield call(setAuthTokens, result);
      yield put(bearerAuthSuccess(result));

      // Query for detailed User data
      const response = yield call(fetchUser, result.userId);
      if (response.result) {
        yield put(userDataSuccess(response.result));
      }
    }
  } catch (error) {
    // console.log('Bearer Auth Error: %s', error.message);
    yield put(bearerAuthError(error.message));
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
    }
  }
}

// Refresh bearer token 1 minute before expiry.
// If refresh fails, retry once every 10s for 5 times before giving up.
let bearerRefreshDelay;
let tokenRefreshRetriesRemaining;
function* refreshBearerWhenExpire() {
  bearerRefreshDelay = BEARER_EXPIRES_IN;
  tokenRefreshRetriesRemaining = BEARER_TOKEN_REFRESH_RETRIES;

  while (tokenRefreshRetriesRemaining > 0) {
    yield call(delay, bearerRefreshDelay);
    // console.log('Refreshing Bearer Token...');
    const { result, error } = yield call(refreshBearerToken);
    // authorization failed, either by the server or the user signout
    if (error) {
      bearerRefreshDelay = BEARER_RETRY_TIMEOUT;
      tokenRefreshRetriesRemaining -= 1;

      // If token is not found no point in retrying; skip to logout
      if (error.message === 'RefreshToken not found.') {
        tokenRefreshRetriesRemaining = 0;
        break;
      }
      // console.log('Error while refreshing bearer token.');
    } else {
      bearerRefreshDelay = BEARER_EXPIRES_IN;
      tokenRefreshRetriesRemaining = BEARER_TOKEN_REFRESH_RETRIES;
      yield call(setAuthTokens, result);
    }
  }

  // If we're out of refresh attempts logout and force user to login again
  if (tokenRefreshRetriesRemaining === 0) {
    yield put(logout());
  }
}

function* cancelTokenRefresh(task) {
  yield take(constants.BEARER_AUTH_ERROR);
  if (!task.isCancelled()) {
    yield cancel(task);
    yield call(removeAuthTokens);
  }
}

function* authFlowSaga() {
  while (true) {
    const { payload } = yield take(constants.LOGIN);
    yield fork(authorize, payload);
    const tasks = [yield fork(refreshWhenExpire)];

    if (process.env.REACT_APP_RR_API_HOST) {
      yield fork(bearerAuthorization, payload);
      tasks.push(yield fork(refreshBearerWhenExpire));
      tasks.unshift(yield fork(cancelTokenRefresh, tasks[tasks.length - 1]));
    }

    const action = yield take([
      constants.LOGOUT,
      constants.LOGIN_ERROR,
      constants.BEARER_AUTH_ERROR,
    ]);

    // Notify Google Analytics of logout
    config = { user_id: null, email: null };
    window.gtag('config', process.env.GTAG_ID, config);

    for (let i = 0; i < tasks.length; i += 1) {
      yield cancel(tasks[i]);
    }
    yield call(removeAuthHeader);
    yield call(removeAuthTokens);
    yield put(setAppLocked(false));
    if (action.type === constants.LOGOUT && (!action.payload || action.payload.redirect)) {
      yield put(push('/'));
    }
  }
}

function* sagas() {
  yield fork(authFlowSaga);
}
export default sagas;
