import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Hidden,
  Paper,
} from 'material-ui';
import {
  AccountBox,
  SettingsApplications,
} from 'material-ui-icons';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getAccountId, getLoggedIn, getUserInfo } from '../Auth/selectors';
import AccountSettings from './AccountSettings';
import AccountProfile from './AccountProfile';
import AddressForm from './addressForm';
import ContactForm from './contactForm';
import AlternateForm from './alternateForm';
import CustomerInsuranceForm from './RegisterVehicle/insuranceForm';
import VehicleForm from './RegisterVehicle/vehicleForm';
import Loading from '../../components/Loading';
import { appMsg } from '../AppWrapper/reducer';
import { fetchVehiclesThunk, transactSavedDataThunk } from './reducer';
import './styles.css';
import { routePropTypes } from '../../utils/routes';
import { getCustomerInsurances, getFetching, getVehicles } from './selectors';

const menus = [{
  text: 'Profile',
  icon: <AccountBox />,
  url: '/account',
}, {
  text: 'Password',
  icon: <SettingsApplications />,
  url: '/account/settings',
}];

const styles = () => ({
  flex: {
    flex: '0 0 auto',
  },
});

const NOTIFY_USER_CHANGES = {
  addresses: 'Address',
  alternates: 'Alternate',
  contacts: 'Phone Number',
};

const NOTIFY_CHANGES = {
  customerInsurance: 'Auto Insurance',
  vehicles: 'Vehicle',
};

class Account extends Component {
  constructor(props) {
    super(props);

    // If there are any pending changes to userInfo, transact them now so they don't get lost
    props.actions.transactSavedData();
  }

  componentDidMount() {
    this.fetchVehicles();
  }

  shouldComponentUpdate(props) {
    if (this.props.userInfo && props.userInfo) {
      Object.keys(NOTIFY_USER_CHANGES).forEach((group) => {
        const newValues = props.userInfo.get(group);
        const oldValues = this.props.userInfo.get(group);
        if (oldValues && newValues && newValues !== oldValues) {
          if (newValues.size > oldValues.size) {
            props.actions.appMsg(`${NOTIFY_USER_CHANGES[group]} has been added!`, 'success');
          } else if (newValues.size < oldValues.size) {
            props.actions.appMsg(`${NOTIFY_USER_CHANGES[group]} has been deleted!`, 'success');
          } else {
            props.actions.appMsg(`${NOTIFY_USER_CHANGES[group]} has been updated!`, 'success');
          }
        }
      });
    }

    Object.keys(NOTIFY_CHANGES).forEach((propName) => {
      if (props[propName] && this.props[propName] && props[propName] !== this.props[propName]) {
        if (props[propName].size > this.props[propName].size) {
          props.actions.appMsg(`${NOTIFY_CHANGES[propName]} has been added!`, 'success');
        } else if (props[propName].size < this.props[propName].size) {
          props.actions.appMsg(`${NOTIFY_CHANGES[propName]} has been deleted!`, 'success');
        } else {
          props.actions.appMsg(`${NOTIFY_CHANGES[propName]} has been updated!`, 'success');
        }
      }
    });

    return true;
  }

  componentDidUpdate() {
    this.fetchVehicles();
  }

  fetchVehicles() {
    if (this.props.loggedIn && !this.props.vehicles && !this.props.fetchingVehicles) {
      this.props.actions.fetchVehicles(this.props.accountId);
    }
  }

  handleMenuItemClick = (event, selected) => {
    this.props.history.push(selected);
  };

  handleFormCancel = () => {
    this.props.history.goBack();
  };

  renderElement = (Element, elementName, routeProps) => {
    const { itemId } = routeProps.match.params;
    const title = itemId === 'create' ? `Add ${elementName}` : `Edit ${elementName}`;
    return <Element {...routeProps} title={title} onCancel={this.handleFormCancel} />;
  }

  render() {
    const { classes, location, match } = this.props;
    if (!this.props.loggedIn) {
      return (
        <Loading />
      );
    }

    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={24}
      >
        <Hidden smDown>
          <MenuList classes={{ root: classes.flex }}>
            {menus.map((option) => (
              <MenuItem
                key={option.text}
                selected={option.url === location.pathname}
                onClick={event => this.handleMenuItemClick(event, option.url)}
              >
                <ListItemIcon>
                  {option.icon}
                </ListItemIcon>
                <ListItemText inset primary={option.text} />
              </MenuItem>
            ))}
          </MenuList>
        </Hidden>
        <Grid item md={6} xs={12}>
          <Paper className="app-paper">
            <Route exact path="/account" component={AccountProfile} />
            <Route path="/account/settings" component={AccountSettings} />
            <Route path={`${match.url}/addresses/:itemId`} render={(routeProps) => this.renderElement(AddressForm, 'Address', routeProps)} />
            <Route path={`${match.url}/contacts/:itemId`} render={(routeProps) => this.renderElement(ContactForm, 'Phone Number', routeProps)} />
            <Route path={`${match.url}/alternates/:itemId`} render={(routeProps) => this.renderElement(AlternateForm, 'Alternate', routeProps)} />
            <Route path={`${match.url}/insurance/:itemId`} render={(routeProps) => this.renderElement(CustomerInsuranceForm, 'Auto Insurance', routeProps)} />
            <Route path={`${match.url}/vehicles/:itemId`} render={(routeProps) => this.renderElement(VehicleForm, 'Vehicle', routeProps)} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
Account.defaultProps = {
  accountId: null,
  customerInsurance: null,
  userInfo: null,
  vehicles: null,
};
Account.propTypes = {
  accountId: PropTypes.string,
  actions: PropTypes.shape({
    appMsg: PropTypes.func,
    transactSavedData: PropTypes.func,
  }).isRequired,
  customerInsurance: PropTypes.object,
  fetchingVehicles: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
  vehicles: PropTypes.object,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  accountId: getAccountId(state),
  customerInsurance: getCustomerInsurances(state),
  fetchingVehicles: getFetching(state, 'vehicles'),
  loggedIn: getLoggedIn(state),
  userInfo: getUserInfo(state),
  vehicles: getVehicles(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    appMsg,
    fetchVehicles: fetchVehiclesThunk,
    transactSavedData: transactSavedDataThunk,
  }, dispatch),
});

const accountContainer = connect(mapStateToProps, mapDispatchToProps)(Account);
export default withRouter(withStyles(styles)(accountContainer));
