import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { Button, ButtonBase, Checkbox, FormControlLabel, withStyles, Modal, Typography } from 'material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { login, createAccountConfirmationThunk } from './reducer';
import { getLoginError, getLogging } from './selectors';
import { required, email } from '../../utils/validations';
import ProfileForm from '../Account/AccountProfile/profileForm';
import ForgotPasswordForm from './forgotPasswordForm';
import ConfirmAccountForm from './confirmAccountForm';
import { routePropTypes } from '../../utils/routes';

const styles = (theme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '8px',
    width: '100%',
  },
  field: {
    maxWidth: '100%',
    width: '300px',
  },
  large: {
    width: theme.spacing.unit * 62.5,
  },
  modal: {
    position: 'absolute',
    padding: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: '100vw',
  },
  small: {
    width: theme.spacing.unit * 50,
  },
  textCenter: {
    textAlign: 'center',
  },
  container: {
    maxWidth: '355px',
  },
});

class LoginForm extends Component {
  constructor(props) {
    super(props);

    const locationState = props.history.location.state || {};
    const qParams = new URLSearchParams(props.location.search);
    this.state = {
      openCreateAccountModal: !!locationState.createAccount || qParams.get('createAccount') === 'true',
      openPasswordResetModal: !!locationState.resetPassword,
      openConfirmAccountModal: false,
      confirmationValues: {},
      showPassword: false,
      passwordRequired: false,
    };

    if (qParams.has('user_id') && qParams.has('confirmation_token')) {
      this.state.openConfirmAccountModal = true;
      this.state.passwordRequired = qParams.get('password_required') === 'true';
      this.state.confirmationValues = {
        user_id: qParams.get('user_id'),
        token: qParams.get('confirmation_token'),
      };
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onCreateAccount = this.onCreateAccount.bind(this);
    this.onConfirmAccount = this.onConfirmAccount.bind(this);
    this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);
    this.closeCreateAccountModal = this.closeCreateAccountModal.bind(this);
    this.closeConfirmAccountModal = this.closeConfirmAccountModal.bind(this);
  }

  shouldComponentUpdate(props) {
    if (props.loginError && props.loginError !== this.props.loginError && props.loginError.endsWith('You must follow the instructions in the email before your account can be activated')) {
      this.sendConfirmationEmail(props);
    }

    return true;
  }

  onSubmit(values) {
    this.props.actions.login(values);
  }

  onCreateAccount(formValues, response) {
    const confirmationValues = { user_id: response.data.id };
    if (response.data.password_required) confirmationValues.password = formValues.password;

    this.setState({
      newAccountValues: formValues,
      openConfirmAccountModal: true,
      openCreateAccountModal: false,
      confirmationValues,
      passwordRequired: response.data.password_required,
    });
  }

  onConfirmAccount() {
    if (this.state.newAccountValues) {
      this.props.actions.login({
        email: this.state.newAccountValues.email,
        password: this.state.newAccountValues.password,
      });
    }
    this.setState({ openConfirmAccountModal: false });
  }

  sendConfirmationEmail(props) {
    props.actions.createAccountConfirmation(props.formValues)
      .then((result) => this.onCreateAccount(props.formValues, result))
  }

  closeCreateAccountModal() {
    this.setState({ openCreateAccountModal: false });

    const locationState = this.props.history.location.state || {};
    if (locationState.createAccount) {
      this.props.history.goBack();
    }
  }

  closeConfirmAccountModal() {
    this.setState({ openConfirmAccountModal: false });
  }

  render() {
    const {
      formValues,
      loginError,
      handleSubmit,
      logging,
      classes,
    } = this.props;

    const confirmAccountChild = this.state.passwordRequired ? (
      <FormControlLabel
        checked={this.state.showPassword}
        control={<Checkbox />}
        label="Show Password"
        onChange={(e, checked) => {
          this.setState({ showPassword: checked });
        }}
      />
    ) : (
      <Typography type="caption">
        Please check your email and enter the confirmation code
        we sent you in the field above.
      </Typography>
    );

    return (
      <div className={classes.container}>
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className="login-form"
        >
          <div>
            <Field
              name="email"
              component={TextField}
              label="Email"
              validate={[required, email]}
              margin="normal"
              className={classes.field}
            />
          </div>
          <div>
            <Field
              name="password"
              component={TextField}
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              validate={[required]}
              margin="normal"
              className={classes.field}
            />
          </div>
          <div className="forgot-password">
            <FormControlLabel
              checked={this.state.showPassword}
              control={<Checkbox />}
              label="Show Password"
              onChange={(e, checked) => {
                this.setState({ showPassword: checked });
              }}
            />
            <ButtonBase
              onClick={() => this.setState({ openPasswordResetModal: true })}
            >
              Forgot Password?
            </ButtonBase>
          </div>
          <div className={classes.buttonWrapper}>
            <Button color="secondary" disabled={logging} fullWidth raised type="submit" >
              Login
            </Button>
          </div>
          {loginError && <p className="app-error">{loginError}</p>}
          <div className="back-home">
            <a href="https://www.reddotstorage.com">Back Home</a>
          </div>
        </form>
        <Modal
          open={this.state.openPasswordResetModal}
          onClose={() => this.setState({ openPasswordResetModal: false })}
        >
          <div className={`${classes.modal} ${classes.small}`}>
            <ForgotPasswordForm
              initialValues={formValues}
              onClose={() => this.setState({ openPasswordResetModal: false })}
            />
          </div>
        </Modal>
        <Modal
          open={this.state.openConfirmAccountModal}
        >
          <div className={`${classes.modal} ${this.state.passwordRequired ? classes.large : classes.small}`}>
            <Typography
              className={classes.textCenter}
              type="display1"
              gutterBottom
            >
              Confirm Account
            </Typography>
            {
              this.state.passwordRequired &&
              <Typography type="caption" gutterBottom>
                Please check your email and enter the confirmation code
                we sent you in the field below.
              </Typography>
            }
            <ConfirmAccountForm
              initialValues={this.state.confirmationValues}
              onSubmitComplete={this.onConfirmAccount}
              onCancel={this.closeConfirmAccountModal}
              passwordRequired={this.state.passwordRequired}
              showPassword={this.state.showPassword}
            >
              {confirmAccountChild}
            </ConfirmAccountForm>
          </div>
        </Modal>
        <Modal
          open={this.state.openCreateAccountModal}
        >
          <div className={`${classes.modal} ${classes.large}`}>
            <Typography
              className={classes.textCenter}
              type="display1"
              gutterBottom
            >
              Create Account
            </Typography>
            <Typography type="caption" gutterBottom>
              You will receive an email from Red Dot Storage with a code to
              confirm your account. Please follow the instructions in the
              email before logging in for the first time.
            </Typography>
            <ProfileForm
              isCreate
              onSubmitComplete={this.onCreateAccount}
              onCancel={this.closeCreateAccountModal}
              showPassword={this.state.showPassword}
            >
              <FormControlLabel
                checked={this.state.showPassword}
                control={<Checkbox />}
                label="Show Password"
                onChange={(e, checked) => {
                  this.setState({ showPassword: checked });
                }}
              />
            </ProfileForm>
          </div>
        </Modal>
      </div>
    );
  }
}

LoginForm.defaultProps = {
  formValues: undefined,
  loginError: null,
  logging: false,
};

LoginForm.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  logging: PropTypes.bool,
  ...routePropTypes,
};

let selector = () => ({});
const mapStateToProps = (state) => ({
  formValues: selector(state, 'email', 'password'),
  loginError: getLoginError(state),
  logging: getLogging(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    createAccountConfirmation: createAccountConfirmationThunk,
    login,
  }, dispatch),
});

// Decorate with redux-form
const loginForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({
  form: 'loginForm',
})(withStyles(styles)(LoginForm)));
selector = formValueSelector('loginForm');

export default withRouter(loginForm);
