import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions'

export const constants = {
  SET_APP_LOCKED: 'containers/App/SET_APP_LOCKED',
  SET_RETURN_URL: 'containers/App/SET_RETURN_URL',
  SET_DRAWER_OPEN: 'containers/App/SET_DRAWER_OPEN',
  SET_MAP_MODAL_OPEN: 'containers/App/SET_MAP_MODAL_OPEN',
  APP_MESSAGE: 'containers/App/APP_MESSAGE',
}

export const setAppLocked = createAction(constants.SET_APP_LOCKED);
export const setReturnUrl = createAction(constants.SET_RETURN_URL)
export const setDrawerOpen = createAction(constants.SET_DRAWER_OPEN)
export const setMapModalOpen = createAction(constants.SET_MAP_MODAL_OPEN)
export const appMsg = createAction(constants.APP_MESSAGE)

const initialState = fromJS({
  appLocked: false,
  return_url: null,
  drawerOpen: false,
  mapModalOpen: false,
  msg: {
    content: '',
    duration: 6000,
  },
});

export default handleActions({
  [setAppLocked]: (state, action) => state
    .set('appLocked', action.payload),

  [setReturnUrl]: (state, action) => state
    .set('return_url', action.payload),

  [setDrawerOpen]: (state, action) => state
    .set('drawerOpen', action.payload),

  [setMapModalOpen]: (state, action) => state
    .set('mapModalOpen', action.payload),

  [appMsg]: (state, action) => state
    .set('msg', {
      content: action.payload && typeof action.payload === 'object' ? action.payload.message : action.payload,
      duration: action.payload && typeof action.payload.duration !== 'undefined' ? action.payload.duration : 6000,
    }),
}, initialState)
