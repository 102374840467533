import { fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { stopSubmit } from 'redux-form';

import { appMsg } from '../AppWrapper/reducer'
import { confirmAccount, sendResetEmail, resetPassword, removeAuthHeader, sendAccountConfirmation } from '../../services/auth'

export const constants = {
  LOGIN: 'containers/Auth/LOGIN',
  LOGIN_SUCCESS: 'containers/Auth/LOGIN_SUCCESS',
  LOGIN_ERROR: 'containers/Auth/LOGIN_ERROR',
  BEARER_AUTH_SUCCESS: 'containers/Auth/BEARER_AUTH_SUCCESS',
  BEARER_AUTH_ERROR: 'containers/Auth/BEARER_AUTH_ERROR',
  USER_DATA_SUCCESS: 'containers/Auth/USER_DATA_SUCCESS',
  LOGOUT: 'containers/Auth/LOGOUT',
  SEND_RESET_EMAIL_REQUEST: 'containers/Auth/SEND_RESET_EMAIL_REQUEST',
  SEND_RESET_EMAIL_SUCCESS: 'containers/Auth/SEND_RESET_EMAIL_SUCCESS',
  SEND_RESET_EMAIL_ERROR: 'containers/Auth/SEND_RESET_EMAIL_ERROR',
  RESET_PASSWORD_REQUEST: 'containers/Auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'containers/Auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'containers/Auth/RESET_PASSWORD_ERROR',
  ALERT_CONFIRM_SUCCESS: 'containers/Auth/ALERT_CONFIRM_SUCCESS',
  SET_MAGIC_KEY: 'containers/Auth/SET_MAGIC_KEY',
}

export const login = createAction(constants.LOGIN);
export const loginSuccess = createAction(constants.LOGIN_SUCCESS);
export const loginError = createAction(constants.LOGIN_ERROR);
export const bearerAuthSuccess = createAction(constants.BEARER_AUTH_SUCCESS);
export const bearerAuthError = createAction(constants.BEARER_AUTH_ERROR);
export const userDataSuccess = createAction(constants.USER_DATA_SUCCESS);
export const logout = createAction(constants.LOGOUT);

export const sendResetEmailRequest = createAction(constants.SEND_RESET_EMAIL_REQUEST);
export const sendResetEmailSuccess = createAction(constants.SEND_RESET_EMAIL_SUCCESS);
export const sendResetEmailError = createAction(constants.SEND_RESET_EMAIL_ERROR);

export const resetPasswordRequest = createAction(constants.RESET_PASSWORD_REQUEST);
export const resetPasswordSuccess = createAction(constants.RESET_PASSWORD_SUCCESS);
export const resetPasswordError = createAction(constants.RESET_PASSWORD_ERROR);

export const setMagicKey = createAction(constants.SET_MAGIC_KEY);

export const initialState = fromJS({
  touched: {
    addresses: {},
    contacts: {},
  },
  fetching: {},
  accounts: null,
  userId: null,
  userInfo: null,
  userData: null,
  loaded: {},
  logging: null,
  loginError: null,
  loggedIn: false,
  bearerAuthLogging: null,
  bearerAuthError: null,
  bearerAuthSuccess: false,
  sending: false,
  sendError: false,
  sendComplete: false,
  resetting: false,
  resetError: false,
  resetComplete: false,
  vehicles: null,
  customerInsurance: null,
  magicKey: null,
});

export default handleActions({
  [login]: (state) => state
    .set('logging', true)
    .set('loginError', null)
    .set('loggedIn', false)
    .set('bearerAuthLogging', true)
    .set('bearerAuthError', null)
    .set('bearerAuthSuccess', false),

  [logout]: () => initialState,

  [loginSuccess]: (state, action) => {
    let newState = state;
    const userInfo = action.payload;
    state.get('touched').forEach((ids, type) => {
      if (ids.size > 0) {
        let clearTouched = true;
        ids.forEach((_, id) => {
          const value = state
            .getIn(['userInfo', type])
            .find((addr) => addr.get('id') === id);
          // If input is new record and there are no existing records, use it.
          // Otherwise, discard changes made in session (server state takes precedence.)
          if (id === 'new') {
            if (userInfo[type].length === 0) {
              userInfo[type].push(value.toJS());
              clearTouched = false;
            }
            return false;
          }
          return true;
        });

        if (clearTouched) {
          newState = newState.setIn(['touched', type], fromJS([]));
        }
      }
    });

    return newState.set('userInfo', fromJS(userInfo))
      .set('logging', false)
      .set('loggedIn', true)
      .set('magicKey', null);
  },

  [loginError]: (state, action) => state
    .set('logging', false)
    .set('loginError', action.payload),

  [bearerAuthSuccess]: (state, action) => state
    .set('accounts', fromJS(action.payload.accounts))
    .set('userId', action.payload.userId)
    .set('bearerAuthLogging', false)
    .set('bearerAuthSuccess', true),

  [bearerAuthError]: (state, action) => state
    .set('bearerAuthLogging', false)
    .set('bearerAuthError', action.payload),

  [userDataSuccess]: (state, action) => state
    .set('userData', fromJS(action.payload)),

  [sendResetEmailRequest]: (state) => state
    .set('sending', true)
    .set('sendError', null)
    .set('sendComplete', false),

  [sendResetEmailSuccess]: (state) => state
    .set('sending', false)
    .set('sendComplete', true),

  [sendResetEmailError]: (state, action) => state
    .set('sending', false)
    .set('sendError', action.payload)
    .set('sendComplete', false),

  [resetPasswordRequest]: (state) => state
    .set('resetting', true)
    .set('resetError', null)
    .set('resetComplete', false),

  [resetPasswordSuccess]: (state) => state
    .set('resetting', false)
    .set('resetComplete', true),

  [resetPasswordError]: (state, action) => state
    .set('resetting', false)
    .set('resetError', action.payload)
    .set('resetComplete', false),

  [setMagicKey]: (state, action) => state
    .set('magicKey', action.payload),
}, initialState)

/* Thunks */
export const createAccountConfirmationThunk = (data) =>
  (dispatch) =>
    sendAccountConfirmation(data).then(({ result, error }) => {
      if (error) {
        dispatch(appMsg(error.message));
        throw new Error(error.message);
      }

      dispatch(appMsg(result.message));
      return result;
    });

export const confirmAccountThunk = (data) =>
  (dispatch) =>
    confirmAccount(data).then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('confirmAccountForm', error.error));
        if (!error.error) {
          // Alert unexpected error
          dispatch(appMsg(error.message));
        }
        return;
      }

      dispatch(appMsg(result.message));
    });

export const sendResetEmailThunk = (data) =>
  (dispatch) => {
    dispatch(sendResetEmailRequest())
    return sendResetEmail(data)
      .then(({ result, error }) => {
        if (error) {
          dispatch(sendResetEmailError(error._error))
        } else if (result.success) {
          dispatch(sendResetEmailSuccess())
        } else {
          dispatch(sendResetEmailError(result.message))
        }
      })
  }

export const resetPasswordThunk = (formData) =>
  (dispatch) => {
    dispatch(resetPasswordRequest());
    return resetPassword(formData)
      .then(({ result, error }) => {
        if (error) {
          if (error.full_messages) {
            dispatch(resetPasswordError(error.full_messages));
          } else {
            /* eslint no-underscore-dangle: 0 */
            dispatch(resetPasswordError(`${error._error}: Expired or Invalid Token'`));
          }
          removeAuthHeader();
        } else {
          dispatch(resetPasswordSuccess(result));
          dispatch(push('/'));
          dispatch(login({
            email: result.data.email,
            password: formData.password,
          }));
          dispatch(appMsg('Your password has been changed successfully!', 'success'));
        }
      })
  }

export const alertConfirmSuccessThunk = () =>
  (dispatch) => {
    dispatch(logout())
    dispatch(appMsg('Your email address has been confirmed and you can now login!', 'success'))
  }

export const alertInvalidResetTokenThunk = () =>
  (dispatch) => {
    dispatch(logout())
    dispatch(appMsg('Sorry, the reset password token you provided is no longer valid!', 'error'))
  }

export const alertTimedoutThunk = () =>
  (dispatch) => {
    dispatch(logout())
    dispatch(appMsg('Sorry, your session expired. Please sign in again to continue', 'error'))
  }
