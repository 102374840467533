/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { result } from 'lodash';
import GoogleMap from 'google-map-react';
import Fade from 'material-ui/transitions/Fade';
import {
  Grid,
  Hidden,
  Snackbar,
  Tooltip,
  Typography,
  withStyles,
} from 'material-ui';

import Loading from '../../components/Loading';
import {
  getError,
  getFacilities,
  getLoaded,
  getLoading,
  getLocation,
} from './selectors';
import { fetchFacilities, setLocation } from './reducer';
import { setMapModalOpen } from '../AppWrapper/reducer';
import { routePropTypes } from '../../utils/routes';
import './styles.css';
import Title from '../../components/Title';
import MapSearch from '../../components/MapSearch';
import { ScrollContext } from '../../utils/contexts';
import { getDistance } from '../../utils/geographic';

const GoogleMapConfig = {
  key: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: 'places',
};

// Max Radius ~ radius of contiguous US
const MAX_RADIUS = 2291500;

const styles = (/* theme */) => ({
  bodyContent: {
    fontSize: '19px',
    padding: '1em 0',
  },
  fullSize: {
    height: '100%',
    width: '100%',
  },
  insetButton: {
    marginLeft: '-48px',
  },
  sectionContainer: {
    gap: '1em',
  },
  textContainer: {
    'text-align': 'center',
  },
  tooltip: {
    width: 'max-content',
  },
});

class SearchMap extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      geocoderError: false,
    };

    // Bind methods so that 'this' refers to class instance
    this.geocodeAddress = this.geocodeAddress.bind(this);
    this.hideGeocodeError = this.hideGeocodeError.bind(this);
    this.searchLocation = this.searchLocation.bind(this);
  }

  componentDidMount() {
    if (this.props.title) {
      document.title = this.props.title;
    }
  }

  componentWillUnmount() {
    // Store location for next time user returns to this page
    if (this.marker && this.marker.getMap() && this.marker.getPosition()) {
      this.props.actions.setLocation({
        center: this.map.getCenter().toJSON(),
        position: this.marker.getPosition().toJSON(),
        title: this.marker.getTitle(),
        zoom: this.map.getZoom(),
      });
    } else {
      this.props.actions.setLocation(null);
    }
  }

  onMarkerClick(facility) {
    this.props.actions.setMapModalOpen(false);
    this.props.history.push(`/facilities/${facility.id}`);
  }

  getBoundsContainingFacility(location, minRadius = 10000) {
    // Calculate min distance to nearest facility
    let radius = MAX_RADIUS;
    this.props.facilities.find((f) => {
      if (f.location) {
        radius = Math.min(radius, getDistance(location, f.location));
        // Don't zoom in more than minRadius (default: 10 km)
        if (radius <= minRadius) {
          radius = minRadius;
          return true;
        }
      }
      return false;
    });

    // Calculate radius
    const circle = new this.maps.Circle({
      center: location,
      radius,
    });

    // Return bounds
    return circle.getBounds();
  }

  hideGeocodeError() {
    this.setState({ geocoderError: false });
  }

  static contextType = ScrollContext;
  geocodeAddress(searchValue) {
    this.hideGeocodeError();
    if (!searchValue) {
      // If no value was entered, zoom to default location
      this.marker.setMap(null);
      this.map.setZoom(4);
      this.map.setCenter({ lat: 39.975, lng: -94.572 });
    } else {
      // Otherwise, geocode input
      this.geocoder.geocode({ address: searchValue }, (results, status) => {
        if (status === 'OK') {
          // If geocode was successful, place pushpin
          this.marker.setOptions({
            map: this.map,
            position: results[0].geometry.location,
            title: results[0].formatted_address || 'Current Location',
          });

          // Calculate bounds
          const mapView = this.getBoundsContainingFacility(results[0].geometry.location.toJSON());

          // Zoom Map
          this.map.fitBounds(mapView);
        } else {
          // Otherwise, clear pushpin
          this.marker.setMap(null);
          this.setState({ geocoderError: 'Location not Found!' });
        }
      })
    }

    // If we're on Location page, scroll to Map
    if (this.props.location.pathname.startsWith('/selectFacility')) {
      this.context.current.scrollToBottom();
    }
  }

  searchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const loc = { lat: pos.coords.latitude, lng: pos.coords.longitude };
        // Place Pushpin
        this.marker.setOptions({
          map: this.map,
          position: loc,
          title: 'Current Location',
        });

        // Calculate bounds
        const mapView = this.getBoundsContainingFacility(loc);

        // Zoom Map
        this.map.fitBounds(mapView);

        // If we're on Location page, scroll to Map
        if (this.props.location.pathname.startsWith('/selectFacility')) {
          this.context.current.scrollToBottom();
        }
      }, (/* error */) => {
        this.marker.setMap(null);
        this.setState({ geocoderError: 'Location not Found!' });
      });
    } else {
      this.setState({ geocoderError: 'Your browser doesn\'t support geolocation.' });
    }
  }

  initGeocoder(map, maps) {
    this.map = map;
    this.maps = maps;
    this.marker = new maps.Marker();
    this.geocoder = new maps.Geocoder();

    // Zoom to pre-determined location and add pushpin to map
    const loc = this.props.mapLocation;
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.has('sValue')) this.geocodeAddress(urlParams.get('sValue'));
    else if (urlParams.has('geocode')) this.searchLocation();
    else if (loc) {
      this.marker.setOptions({
        map: this.map,
        position: loc.position,
        title: loc.title,
      });
    }
  }

  renderGoogleMap(classes, location) {
    let containerClasses = 'facility-googlemap-wrapper';
    if (location.pathname.startsWith('/selectFacility')) containerClasses += ' location-googlemap-wrapper';
    const loc = this.props.mapLocation || { center: { lat: 39.975, lng: -94.572 }, zoom: 4 };
    return (
      <div className={containerClasses}>
        <div className="app-googlemap">
          <GoogleMap
            defaultCenter={loc.center || loc.position}
            defaultZoom={loc.zoom}
            bootstrapURLKeys={GoogleMapConfig}
            onGoogleApiLoaded={
              ({ map, maps }) => this.initGeocoder(map, maps)
            }
            yesIWantToUseGoogleMapApiInternals
            options={{
              gestureHandling: 'cooperative',
              zoomControlOptions: { position: 1 },
            }}
          >
            {
              this.props.facilities.map((f) => (
                f.location &&
                <div
                  role="presentation"
                  key={f.id}
                  className={`facility-marker${!f.available_units_count || f.available_units_count < 0 ? ' facility-marker-disabled' : ''}`}
                  lat={f.location.lat}
                  lng={f.location.lng}
                  onClick={() => this.onMarkerClick(f)}
                >
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={f.full_address || 'Unknown Address'} placement="top">
                    <div className={classes.fullSize}>
                      <span>{typeof f.available_units_count === 'number' ? f.available_units_count : 'N/A'}</span>
                    </div>
                  </Tooltip>
                </div>
              ))
            }
          </GoogleMap>
          <Snackbar
            autoHideDuration={3000}
            message={this.state.geocoderError}
            onClose={this.hideGeocodeError}
            open={!!this.state.geocoderError}
            transition={Fade}
          />
        </div>
      </div>
    );
  }

  renderLocationHeader = (classes, location) => (
    <Hidden xsDown>
      <Grid className={classes.sectionContainer} container justify="center" style={{ display: location.pathname.startsWith('/selectFacility') ? '' : 'none' }}>
        <Title>
          <Typography type="display4">FIND A LOCATION</Typography>
          <Typography type="display2">NEAR YOU</Typography>
        </Title>
        <Grid className={classes.textContainer} item xs={12} sm={11} md={10} lg={8}>
          <Typography className={classes.bodyContent} type="body1">At Red Dot Storage, we’re committed to delivering the best experience in the self storage industry. Period. We rely on state-of-the-art technology, a streamlined self-rental process, and unmatched customer support to keep our customers happy. And we’re growing. We’ve <Link className="red" to="/sell-your-facility">acquired and retrofitted facilities</Link> over 190 locations and across 18 states – Alabama, Arkansas, Florida, Georgia, Iowa, Illinois, Indiana, Kansas, Kentucky, Louisiana, Michigan, Missouri, Mississippi, Ohio, Pennsylvania, Tennessee, Wisconsin, and West Virginia.</Typography>
          <Typography className={classes.bodyContent} type="body1">Red Dot’s continued growth is a testament to our uncomplicated approach – safe, simple self storage that will see us remain the industry leader in our regions and beyond.</Typography>
        </Grid>
        <Grid className={classes.textContainer} item xs={12} sm={11} md={10} lg={8}>
          <Typography className={`${classes.bodyContent} footerText`} type="display2">FIND A LOCATION NEAR YOU</Typography>
        </Grid>
      </Grid>
    </Hidden>
  )

  render() {
    const {
      actions,
      classes,
      error,
      loaded,
      loading,
      location,
      notLoadedError,
      onCancel,
    } = this.props;

    if (!loaded) {
      if (error) {
        return (
          <div className={classes.fullSize}>
            { this.renderLocationHeader(classes, location) }
            <Typography type="display1" align="center">{notLoadedError}</Typography>
          </div>
        );
      }

      if (!loading) {
        actions.fetchFacilities();
      }

      return (
        <Loading />
      );
    }

    const urlParams = new URLSearchParams(location.search);
    return (
      <div className={classes.fullSize}>
        { this.renderLocationHeader(classes, location) }
        <MapSearch
          defaultSearch={urlParams.get('sValue')}
          onCancel={onCancel}
          onGeocode={this.geocodeAddress}
          onLocate={this.searchLocation}
        />
        { this.renderGoogleMap(classes, location) }
      </div>
    );
  }
}

SearchMap.defaultProps = {
  error: null,
  facilities: null,
  mapLocation: undefined,
  notLoadedError: 'Facilities could not be loaded! Please check your internet connection and reload the page to try again.',
  onCancel: () => {},
  title: 'Facilities',
};

SearchMap.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.object,
  facilities: PropTypes.array,
  mapLocation: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    title: PropTypes.string,
    zoom: PropTypes.number,
  }),
  notLoadedError: PropTypes.string,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  error: getError(state),
  facilities: result(getFacilities(state), 'toJS', null),
  loaded: getLoaded(state),
  loading: getLoading(state),
  mapLocation: getLocation(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFacilities,
    setLocation,
    setMapModalOpen,
  }, dispatch),
});

const searchMap = connect(mapStateToProps, mapDispatchToProps)(SearchMap);
export default withRouter(withStyles(styles)(searchMap));
