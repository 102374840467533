import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import { result } from 'lodash';
import { fetchLease, generateLease, updateLeaseStatus } from './reducer';
import { getError, getLease, getLoaded } from './selectors';
import { getAccountId, getLoggedIn, getUserId } from '../Auth/selectors';
import { getUnitById } from '../MyUnits/selectors';
import { signLeaseThunk } from '../MyUnits/reducer';
import { setReturnUrl } from '../AppWrapper/reducer';
import { getAppLocked } from '../AppWrapper/selectors';
import { updateSelectedFacilityUnit } from '../Billing/reducer';
import { getAccountBalance, getLoading as getBillingLoading } from '../Billing/selectors';
import Loading from '../../components/Loading';
import { routePropTypes } from '../../utils/routes';
import './styles.css';

const styles = (theme) => ({
  centerContent: {
    justifyContent: 'center',
  },
  lastBlock: {
    paddingTop: '2rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1rem',
    },
    position: 'relative',
  },
  pdfContainer: {
    display: 'grid',
    'grid-template-rows': '1fr auto',
    height: '100%',
    width: '100%',
    maxWidth: '1200px',
  },
});

class LeaseAgreement extends Component {
  componentWillMount() {
    const {
      actions,
      history,
      loggedIn,
    } = this.props;

    if (!loggedIn) {
      actions.setReturnUrl(true);
      history.push('/');
    }
  }

  componentDidMount() {
    const {
      actions,
      accountId,
      appLocked,
      insuranceId,
      lease,
      match,
      onAccept,
      title,
      unit,
      unitId,
      userId,
      vehicleId,
    } = this.props;

    if (match && match.params && match.params.envelopeId) {
      // If User must sign Lease, fetch Billing Info to check if they need to make a payment as well
      if (appLocked && unit) {
        document.title = `Sign ${title}`;
        actions.updateSelectedFacilityUnit({
          facility_id: unit.get('facility_id'),
          unit_id: unit.get('id'),
        });
      } else {
        document.title = `View ${title}`;
      }

      // Fetch or Create Lease
      actions.fetchLease({
        account_id: accountId,
        envelope_id: match.params.envelopeId,
        occupancy_id: unit && unit.get('occupancy_id'),
        redirect: true,
      });
    } else if (accountId && userId && unitId) {
      // Check to determine if lease needs to be regenerated
      let metadata = lease && lease.getIn(['contract_details', 'metadata']);
      if (metadata) metadata = JSON.parse(metadata);

      const insuranceIdStr = (insuranceId || '').toString();

      if (!metadata
        || metadata.account_id !== accountId
        || metadata.user_id !== userId
        || metadata.unit_id !== unitId
        || metadata.insurance_id !== insuranceIdStr
        || metadata.vehicle_id !== vehicleId) {
        actions.generateLease({
          account_id: accountId,
          unit_id: unitId,
          insurance_id: insuranceIdStr,
          vehicle_id: vehicleId,
          redirect: !!onAccept,
        });
      }
    }

    // Fire callback when user signs the lease
    window.onLeaseAccepted = () => {
      const signProps = {
        lease_envelope_id: parseInt(this.props.lease.get('id'), 10),
        unit_id: this.props.unitId || (this.props.unit && this.props.unit.get('id')),
      };
      if (this.props.unit) {
        signProps.occupancy_id = this.props.unit.get('occupancy_id');
      }

      window.gtag('event', 'sign_lease', {
        unit_id: signProps.unit_id,
        occupancy_id: signProps.occupancy_id,
        items: [{
          item_id: signProps.lease_envelope_id,
          item_category: 'Lease',
        }],
      });

      // Mark lease signed
      this.props.actions.signLease(signProps);

      // Update lease status
      if (onAccept) {
        this.props.actions.updateLeaseStatus('signed');
      }
    };
  }

  shouldComponentUpdate(props) {
    // If envelope_id changes, delay render until we've updated fetch state
    if (props.match && props.match.params
      && props.match.params.envelopeId !== this.props.match.params.envelopeId) {
      // If User must sign Lease, fetch Billing Info to check if they need to make a payment as well
      if (props.appLocked && props.unit && (!this.props.unit || props.unit.get('id') !== this.props.unit.get('id'))) {
        props.actions.updateSelectedFacilityUnit({
          facility_id: props.unit.get('facility_id'),
          unit_id: props.unit.get('id'),
        });
      }

      // Fetch or Create Lease
      props.actions.fetchLease({
        account_id: props.accountId,
        envelope_id: props.match.params.envelopeId,
        occupancy_id: props.unit && props.unit.get('occupancy_id'),
        redirect: true,
      });
      return false;
    }

    if (props.appLocked && props.billingLoaded
      && props.accountBalance && parseFloat(props.accountBalance) > 0) {
      props.history.replace('/billing');
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    // Remove callback
    delete window.onLeaseAccepted;
  }

  handleBack = () => {
    const {
      history,
      onCancel,
    } = this.props;

    return onCancel ? onCancel() : history.goBack();
  }

  /* eslint-disable react/no-danger */
  renderLeasePDF() {
    const {
      appLocked,
      classes,
      error,
      lease,
      loaded,
      onAccept,
      unitId,
      unit,
      zoom,
    } = this.props;

    let leaseContent;
    const leaseStatus = lease && lease.get('status');
    const contractDetails = lease && lease.get('contract_details');
    const contractStatus = contractDetails && contractDetails.get('status');
    const metadata = JSON.parse((contractDetails && contractDetails.get('metadata')) || '{}');

    const currUnitId = unit ? unit.get('id') : unitId;
    let isSameUnit = !currUnitId
      || !metadata.unit_id
      || currUnitId.toString() === metadata.unit_id.toString();

    // Handle case where previously loaded Lease is old (doesn't have metadata)
    if (unitId && contractDetails && !metadata.unit_id) {
      isSameUnit = false;
    }

    const containerClasses = [classes.pdfContainer];
    if ((!loaded || !isSameUnit) && !error) {
      leaseContent = <Loading />;
    } else if (error) {
      leaseContent = <div className="modal-pdf-container"><Typography type="display1">Lease could not be found!</Typography></div>;
      containerClasses.push(classes.centerContent);
    } else if ((leaseStatus === 2 || contractStatus === 'signed') && ((appLocked && window.onLeaseAccepted) || (unitId && onAccept))) {
      // If waiting for User to sign lease, check to verify it has not already been signed
      leaseContent = <Loading />;
      if (appLocked) window.onLeaseAccepted();
      else if (onAccept) onAccept(lease.get('id'));
    } else {
      const url = lease && lease.getIn(['contract_details', 'contract_pdf_url']);

      const props = {
        src: url,
        height: '100%',
        width: '100%',
      };
      if (unitId) {
        props.sandbox = 'allow-forms allow-same-origin allow-scripts allow-popups';
      }
      if (zoom) {
        props.style = {
          transform: `scale(${zoom})`,
          transformOrigin: 'left top',
        };
        const scale = Math.round((1 / zoom) * 100);
        props.width = `${scale}%`;
        props.height = props.width;
      }

      leaseContent = <iframe title="Lease" {...props} />
    }

    return (
      <Grid className={containerClasses.join(' ')}>
        {leaseContent}
        {
          appLocked ? null : (
            <Grid container justify="flex-end" className={classes.lastBlock}>
              <Button onClick={this.handleBack} color="secondary">
                Back
              </Button>
            </Grid>
          )
        }
      </Grid>
    )
  }

  render() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={24}
        style={this.props.style}
      >
        {this.renderLeasePDF()}
      </Grid>
    );
  }
}

LeaseAgreement.defaultProps = {
  accountBalance: undefined,
  accountId: null,
  appLocked: false,
  billingLoaded: false,
  error: null,
  insuranceId: undefined,
  lease: null,
  loaded: false,
  loggedIn: false,
  onAccept: undefined,
  onCancel: undefined,
  style: { padding: 20 },
  title: 'Lease',
  unit: undefined,
  unitId: undefined,
  userId: null,
  vehicleId: '',
  zoom: undefined,
};

LeaseAgreement.propTypes = {
  accountBalance: PropTypes.string,
  accountId: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  appLocked: PropTypes.bool,
  billingLoaded: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.object,
  insuranceId: PropTypes.number,
  lease: PropTypes.object,
  loaded: PropTypes.bool,
  loggedIn: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
  unit: PropTypes.object,
  unitId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vehicleId: PropTypes.string,
  zoom: PropTypes.number,
  ...routePropTypes,
};

const mapStateToProps = (state, props) => {
  let unit = null;
  if (props.match && props.match.params && props.match.params.envelopeId) {
    unit = getUnitById(state, parseInt(props.match.params.envelopeId, 10), 'lease_envelope_id');
  }

  return {
    accountBalance: getAccountBalance(state),
    appLocked: getAppLocked(state),
    accountId: getAccountId(state),
    billingLoaded: !getBillingLoading(state),
    error: getError(state),
    lease: getLease(state),
    loaded: getLoaded(state),
    loggedIn: getLoggedIn(state),
    unit,
    userId: result(getUserId(state), 'toString'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchLease,
    generateLease,
    setReturnUrl,
    signLease: signLeaseThunk,
    updateLeaseStatus,
    updateSelectedFacilityUnit,
  }, dispatch),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LeaseAgreement));
