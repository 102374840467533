import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const constants = {
  FETCH_FACILITIES: 'containers/SearchMap/FETCH_FACILITIES',
  FETCH_FACILITIES_ERROR: 'containers/SearchMap/FETCH_FACILITIES_ERROR',
  FETCH_FACILITIES_SUCCESS: 'containers/SearchMap/FETCH_FACILITIES_SUCCESS',
  UPDATE_FACILITY: 'containers/SearchMap/UPDATE_FACILITY',
  SET_LOCATION: 'containers/SearchMap/SET_LOCATION',
};

export const fetchFacilities = createAction(constants.FETCH_FACILITIES);
export const fetchFacilitiesError = createAction(constants.FETCH_FACILITIES_ERROR);
export const fetchFacilitiesSuccess = createAction(constants.FETCH_FACILITIES_SUCCESS);
export const updateFacility = createAction(constants.UPDATE_FACILITY);
export const setLocation = createAction(constants.SET_LOCATION);

const initialState = fromJS({
  data: null,
  error: null,
  loaded: false,
  loading: false,
  location: null,
});

export default handleActions({
  [fetchFacilities]: (state) => state
    .set('loading', true)
    .set('loaded', false)
    .set('error', null),

  [fetchFacilitiesSuccess]: (state, action) => state
    .set('data', fromJS(action.payload))
    .set('loading', false)
    .set('loaded', true),

  [fetchFacilitiesError]: (state, action) => state
    .set('data', null)
    .set('loading', false)
    .set('error', action.payload),

  [updateFacility]: (state, action) => state
    .updateIn(['data'], (facilities) => (
      facilities.map((f) => (f.get('id') === action.payload.facility_id ? f.mergeDeep(action.payload.data) : f))
    )),

  [setLocation]: (state, action) => state
    .set('location', action.payload),
}, initialState)
