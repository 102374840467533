import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  fetchBilling,
} from '../../services/billing';
import {
  constants,
  fetchBillingSuccess,
  fetchBillingError,
} from './reducer';
import { getSelectedUnitId, getFacilitiesCount } from '../MyUnits/selectors'
import { getMagicKey } from '../Auth/selectors';

function* fetchBillingSaga() {
  const facilitiesCount = yield select(getFacilitiesCount);
  if (facilitiesCount > 0) {
    const unitId = yield select(getSelectedUnitId);
    const magicKey = yield select(getMagicKey);
    const { result, error } = yield call(fetchBilling, unitId, magicKey);
    if (error) {
      yield put(fetchBillingError(error));
    } else {
      yield put(fetchBillingSuccess(result));
    }
  } else {
    yield put(fetchBillingError('No facility or unit'));
  }
}

function* billingSaga() {
  yield takeLatest([
    constants.FETCH_BILLING,
    constants.UPDATE_SELECTED_FACILITY,
    constants.UPDATE_SELECTED_UNIT,
    constants.UPDATE_SELECTED_FACILITY_UNIT,
  ], fetchBillingSaga);
}

export default billingSaga;
