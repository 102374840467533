
const passwordStrengthStrings = ['Very Weak', 'Very Weak', 'Weak', 'Weak', 'Good', 'Strong', 'Very Strong'];

export function formatPasswordError(key, errors) {
  const upper = key.replace(/^\w/, c => c.toUpperCase()).replace('_', ' ');
  return `${upper} ${errors[0]} `;
}

const hasReddotPassword = value => (new RegExp(/password|reddot/).test(value));
const hasNumber = value => (new RegExp(/[0-9]/).test(value));
const hasMixed = value => (new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value));
const hasSpecial = value => (new RegExp(/[!#@$%^&*)(+=._\-[\]{};|,.<>/?]/).test(value));

export const strengthValue = value => {
  let strengths = 0;
  if (hasReddotPassword(value)) return 0;

  if (value.length >= 8) strengths += 1;
  if (hasNumber(value)) strengths += 1;
  if (hasSpecial(value)) strengths += 1;
  if (hasMixed(value)) strengths += 1;
  if (strengths >= 4 && value.length >= 12) strengths += 1;
  if (strengths >= 4 && value.length >= 16) strengths += 1;
  return strengths;
};

export const strengthText = value => {
  const strength = strengthValue(value);
  return passwordStrengthStrings[strength];
}

export const strengthColor = value => {
  const strength = strengthValue(value);
  if (strength > 5) return 'blue';
  if (strength > 4) return 'green';
  if (strength > 3) return 'olive';
  return 'red';
};
