/* eslint-disable no-console */
/**
@form general form for this application
@author Ming
@usage  should be decorated with reduxForm, connect, withRouter
        otherwise errors
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import {
  Button,
  CircularProgress,
  Grid,
  SnackbarContent,
  Typography,
  withStyles,
} from 'material-ui';
import MaskedTextField from './maskedTextField';
import { routePropTypes } from '../../utils/routes';
import ThemeStyle from './theme';

const ErrorMsg = 'Item Not Found!';

class GeneralForm extends Component {
  constructor(props) {
    super(props);

    this.state = { submitting: false };
    this.form = React.createRef();
    this.submitButton = React.createRef();
    this.submitted = false;
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.pageTitle || this.props.title) {
      document.title = this.props.pageTitle || this.props.title;
    }
  }

  componentDidUpdate() {
    const { autoSubmit, initialized } = this.props;

    if (autoSubmit && initialized && !this.submitted) {
      if (this.form.current && this.form.current.requestSubmit) {
        this.form.current.requestSubmit();
      } else if (this.submitButton.current) {
        this.submitButton.current.click();
      }
    }
  }

  onSubmit(values) {
    const {
      actions,
      autoSubmit,
      fields,
      forceSubmit,
      history,
      next,
      onSubmitComplete,
      pristine,
    } = this.props;

    // If form has not changed, just navigate to next one
    const doSubmit = forceSubmit || autoSubmit;
    if (pristine && next && (!doSubmit || this.submitted)) {
      history.push(next.url, next.state);
      return;
    }

    // Filter values to submit
    let submitValues = values;
    fields.forEach((field) => {
      if (field.submittable === false && field.name) {
        if (submitValues === values) {
          submitValues = { ...values };
        }
        delete submitValues[field.name];
      }
    })

    this.setState({ submitting: true });
    if (this.isCreate()) {
      actions.create(submitValues, this.props.next)
        .then((response) => onSubmitComplete && onSubmitComplete(submitValues, response))
        .catch((err) => console.error(err))
        .finally(() => this.setState({ submitting: false }));
    } else {
      actions.update(submitValues, this.props.next)
        .then((response) => onSubmitComplete && onSubmitComplete(submitValues, response))
        .catch((err) => console.error(err))
        .finally(() => this.setState({ submitting: false }));
    }
    this.submitted = true;
  }

  isCreate() {
    const { match } = this.props;
    const itemId = match && match.params && match.params.itemId;
    return itemId === 'create' || this.props.isCreate;
  }

  render() {
    const {
      actions,
      autoComplete,
      buttonTitle,
      children,
      classes,
      disabled,
      disablePristine,
      error,
      fields,
      handleSubmit,
      history,
      initialValues: item,
      isVisible,
      margin,
      next,
      pristine,
      shrinkLabels,
      skip,
      template,
      title,
    } = this.props;

    // If we're skipping this form, just navigate to the next one.
    if (skip && next) {
      history.replace(next.url, { ...next.state, skippable: true });
      return null;
    }

    if (!item && !this.isCreate()) {
      return (
        <SnackbarContent
          message={ErrorMsg}
        />
      )
    }
    return (
      <div>
        { title ? <Typography align="center" className={classes.title} type="display2" gutterBottom>{title}</Typography> : null}
        <form autoComplete={autoComplete} onSubmit={handleSubmit(this.onSubmit)} ref={this.form}>
          <Grid container>
            {
              template || fields.map((f) => (
                isVisible[f.name] === false ? null :
                  f.custom ||
                  <Field
                    name={f.name}
                    component={f.mask ? MaskedTextField : TextField}
                    props={f.mask ? { mask: f.mask, pipe: f.pipe } : undefined}
                    autoComplete={f.autoComplete}
                    InputLabelProps={{ className: f.required ? 'required' : undefined, shrink: typeof f.shrink === 'boolean' ? f.shrink : shrinkLabels }}
                    InputProps={f.inputProps}
                    label={f.label}
                    required={f.required}
                    validate={f.validate}
                    margin={f.margin || margin}
                    type={f.password ? 'password' : 'text'}
                    key={f.name}
                    disabled={f.disabled}
                    fullWidth={f.fullWidth}
                    multiline={f.multiline}
                    rows={f.rows}
                  />
              ))
            }
          </Grid>
          {children && <div className={classes.bottomDiv}>{children}</div>}
          <div className={`${classes.bottomDiv} ${classes.row}`}>
            <div className={classes.errorWrapper}>{this.state.submitting || error}</div>
            <div className={classes.buttonWrapper}>
              {
                actions.cancel &&
                <Button
                  raised
                  className={classes.cancel}
                  onClick={actions.cancel}
                  disabled={this.state.submitting}
                >
                  Cancel
                </Button>
              }
              <Button
                buttonRef={this.submitButton}
                type="submit"
                disabled={disabled || this.state.submitting || (pristine && !disablePristine)}
                raised
                color="secondary"
              >
                {this.state.submitting ? <CircularProgress size={24} color="secondary" /> : buttonTitle || (this.isCreate() ? 'Create' : 'Save')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

GeneralForm.propTypes = {
  actions: PropTypes.object.isRequired,
  autoComplete: PropTypes.string,
  autoSubmit: PropTypes.bool,
  buttonTitle: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  disablePristine: PropTypes.bool,
  error: PropTypes.node,
  fields: PropTypes.arrayOf(PropTypes.shape({
    custom: PropTypes.node,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.node,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    multiline: PropTypes.bool,
    name: PropTypes.string,
    password: PropTypes.bool,
    required: PropTypes.bool,
    rows: PropTypes.number,
    shrink: PropTypes.bool,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  })),
  forceSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isCreate: PropTypes.bool,
  isVisible: PropTypes.objectOf(PropTypes.bool),
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  next: PropTypes.shape({
    url: PropTypes.string.isRequired,
    state: PropTypes.object,
  }),
  onSubmitComplete: PropTypes.func,
  pageTitle: PropTypes.string,
  pristine: PropTypes.bool,
  shrinkLabels: PropTypes.bool,
  skip: PropTypes.bool,
  template: PropTypes.node,
  title: PropTypes.node,
  ...routePropTypes,
};

GeneralForm.defaultProps = {
  autoComplete: undefined,
  autoSubmit: false,
  buttonTitle: null,
  disabled: false,
  disablePristine: false,
  error: undefined,
  fields: [],
  forceSubmit: false,
  initialValues: undefined,
  isCreate: false,
  isVisible: {},
  margin: 'normal',
  next: undefined,
  onSubmitComplete: undefined,
  pageTitle: undefined,
  pristine: false,
  shrinkLabels: undefined,
  skip: false,
  template: undefined,
  title: undefined,
};

export default withStyles(ThemeStyle)(GeneralForm);
