import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import { Link } from 'react-router-dom';

import './styles.css';

const styles = () => ({
  row: {
    width: '100%',
    textAlign: 'center',
  },
  menuButton: {
    marginLeft: 20,
    marginRight: 20,
    width: 30,
    verticalAlign: 'middle',
  },
  white: {
    color: 'white',
  },
  title: {
    textDecoration: 'none',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
});

const Maintenance = ({ classes }) => (
  <Grid
    container
    direction="row"
    spacing={24}
    justify="center"
    alignItems="stretch"
    className="maintenance"
  >
    <Grid item className={classes.row}>
      <Grid className="logo" />
    </Grid>
    <Grid item className={classes.row}>
      <Typography className="title1">We are still packing.</Typography>
      <Typography className="title2">Due to site maintenance, will be back shortly.</Typography>
    </Grid>
    <Grid item>
      <Link to="/">
        <Button
          raised
          color="secondary"
          size="large"
        >
          Refresh to Check Status
        </Button>
      </Link>
    </Grid>
    <Grid item className={classes.row}>
      <Grid className="boxes" />
    </Grid>
  </Grid>
);
Maintenance.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Maintenance);
