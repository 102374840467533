import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Checkbox, FormControlLabel, Grid, Modal, withStyles } from 'material-ui';

import GeneralForm from '../../../components/Form';
import MakePaymentTemplate from './makePaymentTemplate'
import { createTransactionThunk } from '../reducer';
import {
  getAccountBalance,
  getAmountDue,
  getDefaultPaymentId,
  getDueAt,
  getExpectedMonthlyCharge,
  getPayments,
  getPaymentAuthorizationMessage,
} from '../selectors';

const mapStateToProps = (state, ownProps) => {
  let paymentId = getDefaultPaymentId(state);
  if (!paymentId) {
    const payments = getPayments(state);
    if (payments.size > 0) {
      paymentId = payments.get(payments.size - 1).get('id').toString();
    }
  }

  const accountBalance = parseFloat(getAccountBalance(state));
  const amountDue = parseFloat(getAmountDue(state));
  const monthlyCharge = parseFloat(getExpectedMonthlyCharge(state));
  const expectedAmountDue = amountDue + monthlyCharge;
  const locationState = ownProps.history.location.state || {};
  let title = 'Amount Due';
  let amount = amountDue;
  let paymentType = 'amount_due';
  // If paying amount due, don't bother to display next billing date
  let dueDate = null;
  if (amountDue <= 0 && accountBalance <= 0) {
    // If invoice has not yet been generated, pay expected amount for next month
    title = 'Next Month\'s Rent';
    amount = expectedAmountDue;
    paymentType = 'upcoming_rent';
    dueDate = getDueAt(state);
  } else if (accountBalance > amountDue && (amountDue <= 0 || !locationState.amountDuePayment)) {
    // If invoice has been generated and they don't have / didn't select amount_due
    // pay account balance
    title = 'Account Balance';
    amount = accountBalance;
    paymentType = 'account_balance';
    dueDate = getDueAt(state);
  }

  return {
    buttonTitle: 'Submit Payment',
    // Disable pre-payment if they have already paid next month's rent
    disabled: ownProps.disabled || amount <= 0,
    disablePristine: !!paymentId,
    initialValues: {
      amount_choice: paymentType,
      payment_id: paymentId,
    },
    pageTitle: 'Pay Bill',
    template: <MakePaymentTemplate amount={amount} dueDate={dueDate} title={title} />,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = bindActionCreators({
    update: createTransactionThunk,
  }, dispatch);
  if (ownProps.history) {
    actions.cancel = () => ownProps.history.goBack();
  }

  return { actions };
};

// Decorate with redux-form
const TransactionForm = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'transactionForm',
})(GeneralForm));

const styles = (theme) => ({
  centerBottomButton: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '16px',
    marginBottom: '-16px',
  },
  linkButton: {
    color: 'blue',
    marginLeft: '1rem',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      transform: 'translate(0, 0)',
      overflowY: 'auto',
      // Add space for floating "Live Chat" button
      paddingBottom: theme.spacing.unit * 10,
    },
  },
});

// Wrap in container to add Payment Authorization
const PaymentForm = ({ classes, paymentAuthorization, ...props }) => {
  const [paymentAuthorized, setPaymentAuthorized] = useState(false);
  const togglePaymentAuthorized = useCallback(() => setPaymentAuthorized((prev) => !prev), []);

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = useCallback(() => setModalOpen((prev) => !prev), []);

  const iframeRef = useRef();
  const iframe = useMemo(() => {
    const file = paymentAuthorization && paymentAuthorization.get('file');
    if (file) {
      const url = URL.createObjectURL(new Blob([file], { type: 'text/html' }));
      if (iframeRef.current && iframeRef.current.src) URL.revokeObjectURL(iframeRef.current.src);
      return <iframe ref={iframeRef} title="Terms & Conditions" src={url} width="100%" height="100%" />
    }
    return '';
  }, [paymentAuthorization]);

  const link = useMemo(() => {
    const file = paymentAuthorization && paymentAuthorization.get('file');
    return file ? <Button className={classes.linkButton} onClick={toggleModal}>Terms & Conditions</Button> : '';
  }, [paymentAuthorization]);
  useEffect(() => () => iframeRef.current && URL.revokeObjectURL(iframeRef.current.src), []);

  return (
    <React.Fragment>
      <TransactionForm disabled={!paymentAuthorized} {...props}>
        <Grid item xs={12}>{link}</Grid>
        <Grid item style={{ marginBottom: '-12px' }} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={paymentAuthorized}
                onChange={togglePaymentAuthorized}
              />
            }
            label={paymentAuthorization && paymentAuthorization.get('message')}
          />
        </Grid>
      </TransactionForm>
      <Modal open={modalOpen}>
        <Grid className={classes.modal}>
          <div className="modal-pdf-container">
            {iframe}
          </div>
          <Button className={classes.centerBottomButton} color="secondary" onClick={toggleModal} raised size="large">Close</Button>
        </Grid>
      </Modal>
    </React.Fragment>
  );
}
PaymentForm.defaultProps = {
  paymentAuthorization: null,
};
PaymentForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  paymentAuthorization: PropTypes.object,
};

const mapStateToPaymentProps = (state) => ({
  paymentAuthorization: getPaymentAuthorizationMessage(state),
});

export default withStyles(styles)(connect(mapStateToPaymentProps)(PaymentForm));
