import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
} from 'material-ui';
import {
  Delete,
  Edit,
  Place,
  Phone,
  Add,
  Person,
  Home,
  Business,
  Smartphone,
} from 'material-ui-icons';
import { result } from 'lodash';
import { conformToMask } from 'react-text-mask';

import Policy from '../../../svgs/policy';
import ProfileForm from './profileForm';
import { getUserInfo } from '../../Auth/selectors';
import { deleteAlternateThunk, deleteAddressThunk, deleteContactThunk, deleteCustomerInsuranceThunk, deleteVehicleThunk } from '../reducer';
import { phoneMask } from '../../../constants'
import { routePropTypes } from '../../../utils/routes';
import { getCustomerInsurances, getVehicles } from '../selectors';
import { getVehicleIcon, getVehicleLabel } from '../RegisterVehicle/vehicleForm';
import { getOccupiedUnits } from '../../MyUnits/selectors';

const styles = () => ({
  addButton: {
    height: '36px',
    width: '36px',
    marginBottom: '2px',
  },
  listItemShadow: {
    boxShadow: '2px 2px 6px 0px darkgrey',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
});

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        open: false,
        busy: false,
        title: '',
        content: '',
        action: undefined,
      },
    };

    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  componentDidMount() {
    document.title = this.props.title;
  }

  getAlternateLine = a => `${a.first_name} ${a.last_name}`;
  getStreet2 = (s) => (s ? `, ${s}` : '');
  getAddressLine = s => `${s.street}${this.getStreet2(s.street_2)}, ${s.city}, ${s.state} ${s.zip}`;
  getPhoneNumber = (phone) => conformToMask(
    phone,
    phoneMask(phone),
    { guide: false },
  ).conformedValue;
  getContactLine = c => `${c.label} ${this.getPhoneNumber(c.phone)}`;

  getInsuranceLine = i => i.get('carrier_name');

  handleDeleteAlternate = alternate => {
    this.setState({
      modal: {
        open: true,
        title: 'Delete Alternate?',
        content: `Are you sure you want to delete Alternate "${this.getAlternateLine(alternate)}"?`,
        action: () => {
          this.setState((state) => ({ modal: { ...state.modal, busy: true } }));
          this.props.actions.deleteAlternateThunk(alternate.id).finally(this.handleDialogClose);
        },
      },
    });
  }

  handleDeleteAddress = address => {
    if (address.default) return;

    this.setState({
      modal: {
        open: true,
        title: 'Delete Address?',
        content: `Are you sure you want to delete Address "${this.getAddressLine(address)}"?`,
        action: () => {
          this.setState((state) => ({ modal: { ...state.modal, busy: true } }));
          this.props.actions.deleteAddressThunk(address.id).finally(this.handleDialogClose);
        },
      },
    });
  }

  handleDeleteContact = contact => {
    if (contact.default) return;

    this.setState({
      modal: {
        open: true,
        title: 'Delete Contact?',
        content: `Are you sure you want to delete Phone Number "${this.getPhoneNumber(contact.phone)}"?`,
        action: () => {
          this.setState((state) => ({ modal: { ...state.modal, busy: true } }));
          this.props.actions.deleteContactThunk(contact.id).finally(this.handleDialogClose);
        },
      },
    });
  }

  handleDeleteInsurance = insurance => {
    this.setState({
      modal: {
        open: true,
        title: 'Delete Auto Insurance?',
        content: `Are you sure you want to delete Insurance "${this.getInsuranceLine(insurance)}"?`,
        action: () => {
          this.setState((state) => ({ modal: { ...state.modal, busy: true } }));
          this.props.actions.deleteCustomerInsuranceThunk(insurance.get('id')).finally(this.handleDialogClose);
        },
      },
    });
  }

  handleDeleteVehicle = vehicle => {
    this.setState({
      modal: {
        open: true,
        title: 'Delete Vehicle?',
        content: `Are you sure you want to delete Vehicle "${getVehicleLabel(vehicle)}"?`,
        action: () => {
          this.setState((state) => ({ modal: { ...state.modal, busy: true } }));
          this.props.actions.deleteVehicleThunk(vehicle.id).finally(this.handleDialogClose);
        },
      },
    });
  }

  handleDialogClose = () => this.setState({ modal: { open: false } });

  render() {
    const { alternates, addresses, contacts } = this.props.userInfo;
    const { customerInsurance, units, vehicles } = this.props;

    return (
      <Grid container direction="column" spacing={24}>
        <Grid item style={{ maxWidth: '100%' }}>
          <ProfileForm />
        </Grid>

        {/* Confirm Delete */}
        <Dialog
          open={this.state.modal.open}
          onClose={!this.state.modal.busy ? this.handleDialogClose : undefined}
        >
          <DialogTitle>{this.state.modal.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.modal.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              raised
              disabled={this.state.modal.busy}
              onClick={this.handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              raised
              color="secondary"
              disabled={this.state.modal.busy}
              onClick={() => this.state.modal.action()}
            >
              {this.state.modal.busy ? <CircularProgress color="secondary" size={24} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Alternates */}
        <Grid item style={{ maxWidth: '100%' }}>
          <Grid container justify="space-between" alignItems="center" spacing={0}>
            <Typography type="title" color="secondary">
              Alternates
            </Typography>
            <Button fab mini className={this.props.classes.addButton} onClick={() => this.props.history.push('/account/alternates/create')} color="primary" title="Add Alternate" >
              <Add className="app-red-svg" />
            </Button>
          </Grid>

          <Divider />
          <List>
            {alternates && alternates.map(alternate => (
              <ListItem
                classes={{ container: this.props.classes.listItemShadow }}
                key={`alternate-${alternate.id}`}
                dense
                button
                onClick={() => this.props.history.push(`/account/alternates/${alternate.id}`)}
              >
                <Person className="app-red-svg" />
                <ListItemText
                  primary={this.getAlternateLine(alternate)}
                  secondary={alternate.email}
                />
                <ListItemSecondaryAction>
                  <Hidden xsDown>
                    <IconButton onClick={() => this.props.history.push(`/account/alternates/${alternate.id}`)} title="Edit">
                      <Edit />
                    </IconButton>
                  </Hidden>
                  <IconButton onClick={() => this.handleDeleteAlternate(alternate)} title="Delete">
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Addresses */}
        <Grid item style={{ maxWidth: '100%' }}>
          <Grid container justify="space-between" alignItems="center" spacing={0}>
            <Typography type="title" color="secondary">
              Addresses
            </Typography>
            <Button fab mini className={this.props.classes.addButton} onClick={() => this.props.history.push('/account/addresses/create')} color="primary" title="Add Address">
              <Add className="app-red-svg" />
            </Button>
          </Grid>

          <Divider />
          <List>
            {addresses && addresses.map(address => {
              let icon = <Place className="app-red-svg" />;
              const addressType = (address.label || '').toUpperCase();
              if (addressType === 'HOME') icon = <Home className="app-red-svg" />;
              else if (addressType === 'WORK') icon = <Business className="app-red-svg" />;

              return (
                <ListItem
                  classes={{ container: this.props.classes.listItemShadow }}
                  key={`address-${address.id}`}
                  dense
                  button
                  onClick={() => this.props.history.push(`/account/addresses/${address.id}`)}
                >
                  {icon}
                  <ListItemText
                    primary={this.getAddressLine(address)}
                    secondary={address.default ? 'Primary' : null}
                  />
                  <ListItemSecondaryAction>
                    <Hidden xsDown>
                      <IconButton onClick={() => this.props.history.push(`/account/addresses/${address.id}`)} title="Edit">
                        <Edit />
                      </IconButton>
                    </Hidden>
                    <IconButton
                      onClick={() => this.handleDeleteAddress(address)}
                      disabled={address.default}
                      title="Delete"
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Grid>

        {/* Contacts */}
        <Grid item style={{ maxWidth: '100%' }}>
          <Grid container justify="space-between" alignItems="center" spacing={0}>
            <Typography type="title" color="secondary">
              Phone Numbers
            </Typography>
            <Button fab mini className={this.props.classes.addButton} onClick={() => this.props.history.push('/account/contacts/create')} color="primary" title="Add Phone Number">
              <Add className="app-red-svg" />
            </Button>
          </Grid>

          <Divider />
          <List>
            {contacts && contacts.map(contact => {
              const contactType = (contact.label || '').toUpperCase();

              return (
                <ListItem
                  classes={{ container: this.props.classes.listItemShadow }}
                  key={`contact-${contact.id}`}
                  dense
                  button
                  onClick={() => this.props.history.push(`/account/contacts/${contact.id}`)}
                >
                  { contactType === 'MOBILE' ? <Smartphone className="app-red-svg" /> : <Phone className="app-red-svg" /> }
                  <ListItemText
                    primary={this.getContactLine(contact)}
                    secondary={contact.default ? 'Primary' : null}
                  />
                  <ListItemSecondaryAction>
                    <Hidden xsDown>
                      <IconButton onClick={() => this.props.history.push(`/account/contacts/${contact.id}`)} title="Edit">
                        <Edit />
                      </IconButton>
                    </Hidden>
                    <IconButton
                      onClick={() => this.handleDeleteContact(contact)}
                      disabled={contact.default}
                      title="Delete"
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Grid>

        {/* Customer Insurance */}
        <Grid item style={{ maxWidth: '100%' }}>
          <Grid container justify="space-between" alignItems="center" spacing={0}>
            <Typography type="title" color="secondary">
              Auto Insurances
            </Typography>
            <Button fab mini className={this.props.classes.addButton} onClick={() => this.props.history.push('/account/insurance/create')} color="primary" title="Add Auto Insurance">
              <Add className="app-red-svg" />
            </Button>
          </Grid>

          <Divider />
          <List>
            {customerInsurance && customerInsurance.map(insurance => (
              <ListItem
                classes={{ container: this.props.classes.listItemShadow }}
                key={`insurance-${insurance.get('id')}`}
                dense
                button
                onClick={() => this.props.history.push(`/account/insurance/${insurance.get('id')}`)}
              >
                <Policy className="app-red-svg" />
                <ListItemText primary={this.getInsuranceLine(insurance)} />
                <ListItemSecondaryAction>
                  <Hidden xsDown>
                    <IconButton onClick={() => this.props.history.push(`/account/insurance/${insurance.get('id')}`)} title="Edit">
                      <Edit />
                    </IconButton>
                  </Hidden>
                  <IconButton
                    disabled={!!vehicles.find(v => v.get('CustomerInsurances').find(ci => ci.get('id') === insurance.get('id')))}
                    onClick={() => this.handleDeleteInsurance(insurance)}
                    title="Delete"
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Vehicles */}
        <Grid item style={{ maxWidth: '100%' }}>
          <Grid container justify="space-between" alignItems="center" spacing={0}>
            <Typography type="title" color="secondary">
              Vehicles
            </Typography>
            <Button fab mini className={this.props.classes.addButton} onClick={() => this.props.history.push('/account/vehicles/create')} color="primary" title="Add Vehicle">
              <Add className="app-red-svg" />
            </Button>
          </Grid>

          <Divider />
          <List>
            {vehicles && vehicles.map(v => {
              const vehicle = v.toJS();
              const unit = vehicle.occupancy_id && units && units.find(u => (u.get('occupancy_id') || '').toString() === vehicle.occupancy_id.toString());
              const Icon = getVehicleIcon(vehicle.type);

              return (
                <ListItem
                  classes={{ container: this.props.classes.listItemShadow }}
                  key={`vehicle-${vehicle.id}`}
                  dense
                  button
                  onClick={(e) => {
                    if (!e.target.href) {
                      this.props.history.push(`/account/vehicles/${vehicle.id}`);
                    }
                  }}
                >
                  <Icon className="app-red-svg" />
                  <ListItemText
                    primary={getVehicleLabel(vehicle)}
                    secondary={unit ? (
                      <React.Fragment>
                        Unit: <Link className="link" to={{ pathname: '/units', state: { unit_id: unit.get('id') } }}>{unit.get('unit_number')}</Link>
                      </React.Fragment>
                    ) : undefined}
                  />
                  <ListItemSecondaryAction>
                    <Hidden xsDown>
                      <IconButton onClick={() => this.props.history.push(`/account/vehicles/${vehicle.id}`)} title="Edit">
                        <Edit />
                      </IconButton>
                    </Hidden>
                    <IconButton
                      disabled={!!unit}
                      onClick={() => this.handleDeleteVehicle(vehicle)}
                      title="Delete"
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
          })}
          </List>
        </Grid>
      </Grid>
    );
  }
}
AccountInfo.defaultProps = {
  title: 'My Account',
}
AccountInfo.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  customerInsurance: PropTypes.object.isRequired,
  title: PropTypes.string,
  userInfo: PropTypes.shape({
    alternates: PropTypes.arrayOf(PropTypes.object),
    addresses: PropTypes.arrayOf(PropTypes.object),
    contacts: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  vehicles: PropTypes.object.isRequired,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  customerInsurance: getCustomerInsurances(state) || [],
  units: getOccupiedUnits(state),
  userInfo: result(getUserInfo(state), 'toJS', { alternates: [], addresses: [], contacts: [] }),
  vehicles: getVehicles(state) || [],
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    deleteAlternateThunk,
    deleteContactThunk,
    deleteAddressThunk,
    deleteCustomerInsuranceThunk,
    deleteVehicleThunk,
  }, dispatch),
});
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AccountInfo)));
