/* eslint-disable import/prefer-default-export */
// import { fromJS } from 'immutable';
// import { createAction, handleActions } from 'redux-actions';
import { stopSubmit } from 'redux-form';
import { goBack } from 'react-router-redux';

import { appMsg } from '../AppWrapper/reducer';
import { sendContactMessage } from '../../services/contactUs';

/* const initialState = fromJS({
  messageError: null,
});

export const constants = {
  SEND_MESSAGE_SUCCESS: 'containers/ContactUs/SEND_MESSAGE_SUCCESS',
} */

/* Actions */
// export const sendMessageSuccess = createAction(constants.SEND_MESSAGE_SUCCESS);

/* Contacts */
export const sendMessageThunk = (data) =>
  (dispatch) => sendContactMessage(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(stopSubmit('helpContactForm', error));
        throw new Error(error);
      } else {
        // dispatch(sendMessageSuccess(result))
        dispatch(appMsg(result, 'success'));
        dispatch(goBack());
      }
    })

/* Reducer
export default handleActions({
  [sendMessageSuccess]: (state, action) => state
    .updateIn(['userInfo', 'contacts'], arr => {
      let newArr = arr;
      if (action.payload.default) {
        const originalDefaultIndex = newArr.findIndex(s => s.get('default'))
        newArr = newArr.setIn([originalDefaultIndex, 'default'], false)
      }
      return newArr.push(fromJS(action.payload))
    }),
}, initialState) */
