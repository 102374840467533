import React from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { result } from 'lodash';

import GeneralForm from '../../../components/Form';
import PaymentFormTemplate from './PaymentForm/template';
import { createPaymentThunk } from '../reducer';
import { getUserInfo } from '../../Auth/selectors';

const mapStateToProps = (state, ownProps) => {
  const userInfo = result(getUserInfo(state), 'toJS', { addresses: [{}] });
  const address = userInfo.addresses[0] || {};
  return {
    disablePristine: true,
    isCreate: true,
    initialValues: {
      profile_type: 'card',
      bankData: { accountType: 'checking' },
      cardData: {
        addressAttributes: {
          country: address.country,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          street_address: address.street,
          city: address.city,
          state: address.state,
          zip: address.zip,
        },
      },
    },
    pageTitle: 'Add Payment Method',
    template: <PaymentFormTemplate {...ownProps} />,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = { create: createPaymentThunk };
  if (ownProps.onCancel) actions.cancel = ownProps.onCancel;
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'paymentForm',
})(GeneralForm)));
