export const getBilling = (state) => state.containers.billingReducer

export const getBillingData = (state) => getBilling(state).get('data');
export const getAmountDue = (state) => getBilling(state).getIn(['data', 'amount_due']);
export const getAccountBalance = (state) => getBilling(state).getIn(['data', 'account_balance']);
export const getDueAt = (state) => getBilling(state).getIn(['data', 'next_bill_due_at']);
export const getPastDue = (state) => getBilling(state).getIn(['data', 'past_due']);
export const getExpectedMonthlyCharge = (state) => getBilling(state).getIn(['data', 'expected_monthly_charge']);
export const getAutoPayId = (state) => getBilling(state).getIn(['data', 'auto_pay_id']);
export const getLineItems = (state) => getBilling(state).getIn(['data', 'line_items']);
export const getLoaded = (state) => getBilling(state).get('loaded');
export const getLoading = (state) => getBilling(state).get('loading');
export const getPayments = (state) => getBilling(state).getIn(['data', 'payment_methods']);
export const getDefaultPayment = (state) => getPayments(state).find(s => s.get('default'));
export const getDefaultPaymentId = (state) => getDefaultPayment(state) && getDefaultPayment(state).get('id').toString();
export const getPayment = (state, id) => getPayments(state).find(s => s.get('id') === id);
export const getTransactions = (state) => getBilling(state).getIn(['data', 'transactions']);
export const getInvoices = (state) => getBilling(state).getIn(['data', 'invoices']);
export const getPaymentAuthorizationMessage = (state) => getBilling(state).get('paymentAuthorizationMessage');
