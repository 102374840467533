import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from 'material-ui';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import PaymentList from '../PaymentMethods/paymentList';

import { getPastDue } from '../selectors';
import { currency } from '../../../utils/format';

const MakePaymentTemplate = props => {
  const {
    amount,
    dueDate,
    pastDue,
    title,
  } = props;

  return (
    <Grid container direction="column" spacing={16}>
      <Typography type="subheading" color={pastDue ? 'secondary' : 'textSecondary'} align="center">{title}</Typography>
      <Typography type="display3" color={pastDue ? 'secondary' : 'textSecondary'} align="center" gutterBottom>{currency(amount)}</Typography>

      {
        dueDate ? (
          <React.Fragment>
            <Typography type="subheading" color="textSecondary" align="center">Billing Date</Typography>
            <Typography type="title" align="center" paragraph>{moment.utc(dueDate).format('MM/DD/YYYY')}</Typography>
          </React.Fragment>
        ) : null
      }

      <Grid item>
        <PaymentList />
      </Grid>
    </Grid>
  )
}

MakePaymentTemplate.defaultProps = {
  amount: 0,
  dueDate: undefined,
  pastDue: false,
  title: null,
};

MakePaymentTemplate.propTypes = {
  amount: PropTypes.number,
  dueDate: PropTypes.string,
  pastDue: PropTypes.bool,
  title: PropTypes.node,
};

const mapStateToProps = (state) => ({
  pastDue: getPastDue(state),
});

export default withRouter(connect(mapStateToProps)(MakePaymentTemplate));
