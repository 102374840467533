import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
} from 'material-ui';
import { Visibility, VisibilityOff } from 'material-ui-icons';
import { strengthText, strengthColor } from '../../utils/password';

const PasswordField = ({
  children,
  className,
  input,
  label,
  margin,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => { setShowPassword(value => !value); }, []);
  const handleMouseDown = useCallback(e => e.preventDefault(), []);

  return (
    <FormControl margin={margin} className={className}>
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Input
        id={input.name}
        name={input.name}
        className="password-input"
        type={showPassword ? 'text' : 'password'}
        onChange={input.onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={handleMouseDown}
              style={{ height: '24px' }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        value={input.value}
      />
      {children}
    </FormControl>
  );
};
PasswordField.defaultProps = {
  className: '',
  label: 'Password',
  margin: 'normal',
};
PasswordField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  label: PropTypes.node,
  margin: PropTypes.string,
};


const StrengthPasswordField = ({ className, input, ...props }) => {
  const [state, setState] = useState({
    passwordStatus: '',
    statusColor: 'black',
  });

  const handleChange = useCallback(e => {
    const password = e.target.value;
    if (password.length === 0) {
      setState({ passwordStatus: 'Required', statusColor: 'red' })
    } else {
      setState({
        passwordStatus: strengthText(password),
        statusColor: strengthColor(password),
      })
    }

    if (input && typeof input.onChange === 'function') {
      input.onChange(password);
    }
  }, [input && input.onChange]);
  const inputProps = useMemo(() => ({ ...input, onChange: handleChange }), [input]);

  return (
    <PasswordField className={`strength-password color-${state.statusColor} ${className}`} input={inputProps} {...props}>
      <FormHelperText>{state.passwordStatus}</FormHelperText>
    </PasswordField>
  );
}
StrengthPasswordField.defaultProps = {
  className: '',
}
StrengthPasswordField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
}

export { PasswordField, StrengthPasswordField };
