/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import Title from '../../components/Title';

const styles = (theme) => ({
  accordionContent: {
    fontSize: '19px',
  },
  accordionTitle: {
    color: theme.palette.primary.main,
    fontSize: '15px',
  },
  bodyContent: {
    fontSize: '19px',
    marginBottom: '1em',
  },
  contentContainer: {
    gap: '1em',
  },
  imageContent: {
    'max-width': '100%',
  },
  pageContainer: {
    gap: '4em',
    paddingBottom: '0.5em',
  },
});

const FAQ = ({
  classes,
  faqs,
  title: pageTitle,
}) => {
  useEffect(() => { document.title = pageTitle; }, [pageTitle]);

  return (
    <Grid className={classes.pageContainer} container>
      <Title>
        <Typography type="display4">WELCOME TO</Typography>
        <Typography type="display2">STORAGE RENTALS OF AMERICA</Typography>
      </Title>
      <Grid className={classes.contentContainer} container justify="center">
        <Grid item xs={12} sm={11} md={10}>
          <Typography className={classes.bodyContent} type="body1">
            Dear Valued Customers,
          </Typography>
          <Typography className={classes.bodyContent} type="body1">
            We are happy to announce that Red Dot Storage has been acquired by Storage Rentals of America (SROA), a leading provider of storage solutions across the United States.
          </Typography>
          <Typography className={classes.bodyContent} type="body1">
            If you are a tenant, you will separately receive a detailed letter from SROA, providing comprehensive information regarding the transition.  In the meantime, please feel free to contact the SROA team with any questions at our customer care center at <a href="tel:1-800-457-5678">1-(800) 457-5678</a> or by email at <a href="mailto:contact@sroa.com">contact@sroa.com</a>. We are here to help!
          </Typography>
          <Typography className={classes.bodyContent} type="body1">
            We look forward to working with you!
          </Typography>
          <Typography className={classes.bodyContent} type="body1">
            Warm regards,
          </Typography>
          <Typography className={classes.bodyContent} type="body1">
            The SROA Team
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={10}>
          <Typography type="display4" style={{ fontSize: '30px' }}>Frequently Asked Questions</Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={10}>
          <Typography className={classes.accordionContent} type="body1">To address some common queries during this management change, here are a few FAQs:</Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={10}>
          {
            faqs.map(({ id, title, paragraphs }) => (
              <ExpansionPanel key={id}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.accordionTitle} type="body1">{title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    paragraphs.map((content, index) => (
                      <Typography className={classes.accordionContent} key={`FAQ_${id}_${index}`} type="body1">{typeof content === 'function' ? content({ classes }) : content}</Typography>
                    ))
                  }
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

FAQ.defaultProps = {
  title: 'SROA FAQ',
  faqs: [
    {
      id: 'RENTAL_AGREEMENT',
      title: 'Will there be changes to my rental agreement?',
      paragraphs: [
        'Customers will need to re-sign their rental agreement with Storage Rentals of America at your current rate. More details will come from their office once the transition is complete.',
      ],
    },
    {
      id: 'GATE_CODE',
      title: 'Will my access code change?',
      paragraphs: [
        'No, your access code will remain the same.',
      ],
    },
    {
      id: 'ACCOUNT',
      title: 'What happens to my Red Dot Account?',
      paragraphs: [
        'Your online Red Dot Storage will no longer exist. You will have to sign up again with Storage Rentals of America along with any AutoPay setup. More details will follow.',
      ],
    },
    {
      id: 'BILL_DUE',
      title: 'My bill is due today. Who do I pay?',
      paragraphs: [
        'All future payments will be made to Storage Rentals of America. During this transition you may not be able to access your account to make payment. During this brief transitional period, you will not receive late charges. Once the transfer is complete you will be notified of your owed balance.',
      ],
    },
    {
      id: 'BILL_PAID',
      title: 'I just paid this month’s bill. What happens?',
      paragraphs: [
        'All payments previously made to Red Dot will reflect in your account and will show that you are paid for the month. No action is needed. All future payments will be made to Storage Rentals of America.',
      ],
    },
    {
      id: 'AUTO_PAY',
      title: 'What happens to my AutoPay?',
      paragraphs: [
        'Along with resigning your rental agreement, AutoPay will have to be setup again with Storage Rentals of America.',
      ],
    },
    {
      id: 'BILLING',
      title: 'Will there be changes to pricing or billing cycles?',
      paragraphs: [
        'No, there will be no immediate changes to pricing or billing cycles. Current customers will remain on their anniversary date.',
      ],
    },
    {
      id: 'CONTACT',
      title: 'Who do I contact for assistance during the transition?',
      paragraphs: [
        <React.Fragment>Please reach out to Storage Rentals of America’s customer service team for any assistance or inquiries. They can be reached by phone at <a href="tel:1-800-457-5678">1-(800) 457-5678</a> or by email at <a href="mailto:contact@sroa.com">contact@sroa.com</a>.</React.Fragment>,
      ],
    },
  ],
};

FAQ.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  faqs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    paragraphs: PropTypes.arrayOf(PropTypes.node),
  })),
  title: PropTypes.string,
};

export default withStyles(styles)(FAQ);
