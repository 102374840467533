import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchLease(envelopeId) {
  return yield call(
    fetchData,
    `/leases/${envelopeId}?fetchLatest=true`,
    { method: 'GET' },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function* generateLease(data, magicKey) {
  const { redirect, ...props } = data;

  return yield call(
    fetchData,
    '/leases',
    {
      method: 'POST',
      body: {
        ...props,
        delivery_method: redirect ? 'embedded' : 'email',
        redirect_url: redirect ? `${window.location.origin}/lease-signed.html` : undefined,
      },
      headers: magicKey ? { 'X-USER-MAGIC-KEY': magicKey } : undefined,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function signLease(data, magicKey) {
  return fetchData(
    '/api/customer/v1/lease',
    {
      method: 'POST',
      body: data,
      headers: magicKey ? { 'X-USER-MAGIC-KEY': magicKey } : undefined,
    },
  );
}
