import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatetimePicker } from 'rc-datetime-picker';

import {
  Button,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import './styles.css';

const styles = (theme) => ({
  rightBlock: {
    padding: 20,
    width: 450,
    [theme.breakpoints.down('xs')]: {
      width: 330,
    },
  },
  continueButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  inlineText: {
    display: 'inline',
  },
});

const SelectDate = ({
  acceptButton,
  bodyText,
  classes,
  defaultValue,
  headerText,
  maxSelectableDays,
  onChange,
  secondaryButton,
  secondaryButtonText,
  selectedBodyText,
  title,
}) => {
  const [state, setState] = useState({
    isSelected: false,
    date: defaultValue || moment(),
    now: moment(),
  });

  const onAcceptClick = useCallback(() => {
    if (acceptButton && acceptButton.onClick) {
      acceptButton.onClick(state.date);
    }
  }, [acceptButton && acceptButton.onClick, state.date]);

  const onSecondaryClick = useCallback(() => {
    if (secondaryButton && secondaryButton.onClick) {
      secondaryButton.onClick(state.date);
    }
  }, [secondaryButton && secondaryButton.onClick, state.date]);

  const onDateChange = useCallback((date) => {
    setState({
      ...state,
      isSelected: true,
      date,
    });
    if (onChange) onChange(date);
  }, [onChange]);

  const textBlock = useMemo(() => (
    <Grid className={classes.rightBlock}>
      {title}
      <Grid style={{ marginBottom: '1rem' }}>
        <Typography className={classes.inlineText} type="headline">
          {state.isSelected ? `${headerText}: ` : `Select ${headerText}`}
        </Typography>
        {
          state.isSelected &&
          <Typography className={classes.inlineText} type="headline" noWrap>
            {state.date.format('MMM D, YYYY')}
          </Typography>
        }
      </Grid>
      <Typography>{state.isSelected && typeof selectedBodyText === 'string' ? selectedBodyText : bodyText}</Typography>
      { acceptButton && (
        <Button
          raised
          fullWidth
          color={state.isSelected ? 'secondary' : 'default'}
          className={classes.continueButton}
          disabled={!state.isSelected || state.date.isBefore(state.now, 'day')}
          onClick={onAcceptClick}
        >
          {acceptButton.text}
        </Button>
      )}
      {
        secondaryButton && (
          <React.Fragment>
            {!!secondaryButtonText && <Typography paragraph type="headline">{secondaryButtonText}</Typography>}
            <Button raised fullWidth color={secondaryButton.color || 'secondary'} onClick={onSecondaryClick}>{secondaryButton.text}</Button>
          </React.Fragment>
        )
      }
    </Grid>
  ), [
    acceptButton,
    bodyText,
    classes,
    secondaryButton,
    headerText,
    state,
    selectedBodyText,
    title,
  ]);

  return (
    <Grid container direction="row" justify="center" spacing={8} className="select-date">
      <Grid item className={state.isSelected ? 'selected' : 'not-selected'}>
        <DatetimePicker
          moment={state.date}
          onChange={onDateChange}
          showTimePicker={false}
          minDate={state.now}
          maxDate={moment().add(maxSelectableDays - 1, 'days')}
        />
      </Grid>
      <Grid item>
        {textBlock}
      </Grid>
    </Grid>
  );
}

SelectDate.defaultProps = {
  acceptButton: null,
  bodyText: '',
  defaultValue: undefined,
  headerText: '',
  maxSelectableDays: 1,
  onChange: null,
  secondaryButton: null,
  secondaryButtonText: '',
  selectedBodyText: undefined,
  title: null,
};

SelectDate.propTypes = {
  acceptButton: {
    text: PropTypes.string,
    onClick: PropTypes.func,
  },
  bodyText: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.object,
  headerText: PropTypes.string,
  maxSelectableDays: PropTypes.number,
  onChange: PropTypes.func,
  secondaryButton: {
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
  },
  secondaryButtonText: PropTypes.string,
  selectedBodyText: PropTypes.string,
  title: PropTypes.node,
};

export default withStyles(styles)(SelectDate);
