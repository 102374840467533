/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import { Link } from 'react-router-dom';
import Title from '../../components/Title';
import packingGeneral from '../../images/faq/packing_trunk.jpg';
import packingSpecifics from '../../images/faq/packing_couple.jpg';

const styles = (theme) => ({
  bodyContent: {
    fontSize: '19px',
  },
  accordionTitle: {
    color: theme.palette.primary.main,
    fontSize: '15px',
  },
  imageContent: {
    'max-width': '100%',
  },
  pageContainer: {
    gap: '4em',
  },
  tipsContainer: {
    gap: '7.5em',
  },
});

const FAQ = ({
  classes,
  faqs,
  tips,
  title: pageTitle,
}) => {
  useEffect(() => { document.title = pageTitle; }, [pageTitle]);

  return (
    <Grid className={classes.pageContainer} container justify="center">
      <Title>
        <Typography type="display4">FREQUENTLY ASKED</Typography>
        <Typography type="display2">QUESTIONS</Typography>
      </Title>
      <Grid item xs={12} sm={11} md={10}>
        {
          faqs.map(({ id, title, paragraphs }) => (
            <ExpansionPanel key={id}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.accordionTitle} type="body1">{title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {
                  paragraphs.map((content, index) => (
                    <Typography className={classes.bodyContent} key={`FAQ_${id}_${index}`} type="body1">{typeof content === 'function' ? content({ classes }) : content}</Typography>
                  ))
                }
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
        }
      </Grid>
      <Title>
        <Typography type="display4">PACKING & STORAGE</Typography>
        <Typography type="display2">TIPS</Typography>
      </Title>
      <Grid item xs={12} sm={11} md={10}>
        <Grid container className={classes.tipsContainer} justify="center">
          {
            tips.map(({
              id,
              items,
              image,
              title,
            }, index) => {
              const text = (
                <React.Fragment>
                  <Typography type="display4">{title}</Typography>
                  <ul>{items.map((item, i) => <li key={`item_${id}_${i}`}><Typography className={classes.bodyContent} type="body1">{item}</Typography></li>)}</ul>
                </React.Fragment>
              );
              const img = <img alt={image.alt} className={classes.imageContent} src={image.src} />;
              return (
                <Grid alignItems="center" container key={id} spacing={24}>
                  <Grid item xs={12} sm={12} md={6}>
                    { index % 2 === 0 ? text : img}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    { index % 2 === 1 ? text : img}
                  </Grid>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
      <Grid className="footer" container justify="center">
        <Title>
          <Typography className="footerText" type="display2">THIS IS WORRY-FREE SELF STORAGE</Typography>
        </Title>
        <Link to="/selectFacility"><Button color="secondary" raised size="large">Our Locations</Button></Link>
      </Grid>
    </Grid>
  );
};

FAQ.defaultProps = {
  title: 'FAQ',
  faqs: [
    {
      id: 'MOVE-IN',
      title: 'When can I move in?',
      paragraphs: [
        'With our 24-hour rental kiosk*, as long as there is a storage unit available that meets your self-storage needs, you can move in any time that works for you.',
        '*24-hour kiosk available at most locations',
      ],
    },
    {
      id: 'MOVE-OUT',
      title: 'How much notice needs to be given before I move out?',
      paragraphs: [
        'We ask that you give us 5 days. That’s it.',
      ],
    },
    {
      id: 'IDENTIFICATION',
      title: 'What type of documentation do I need to provide to rent a space?',
      paragraphs: [
        'We require a government-issued photo ID, like your driver’s license or state ID.',
      ],
    },
    {
      id: 'LEASE',
      title: 'Do I need to sign a long-term lease?',
      paragraphs: [
        'Nope. Our self-storage units rent on a month-to-month basis, and there are no security deposits, move-in fees, or other hidden expenses, either. Red Dot is just simple self storage.',
      ],
    },
    {
      id: 'GATE',
      title: 'Does someone at the office need to let me through the security gates?',
      paragraphs: [
        'Nope. As soon as you sign your rental agreement, you are given a personal security gate code that allows 24-hour access to your unit.',
      ],
    },
    {
      id: 'PAYMENT_METHOD',
      title: 'How do I make a payment?',
      paragraphs: [
        'You can pay in person at our onsite kiosk (located in the rental center), over the phone, by mail via check or money order, or pay online through our website. You also have the convenience of signing up for auto-pay – this means your rent is automatically deducted from your credit card, savings, or checking account monthly.',
      ],
    },
    {
      id: 'ACCEPTED_PAYMENT',
      title: 'What forms of payment do you accept?',
      paragraphs: [
        'We accept personal checks, money orders, and Visa, Mastercard, American Express, and Discover cards.',
      ],
    },
    {
      id: 'BOXES',
      title: 'Do you sell boxes and storage supplies?',
      paragraphs: [
        'Unfortunately, we do not offer packing supplies, hand carts, or dollies at our self-storage facilities.',
      ],
    },
    {
      id: 'UNIT_SIZES',
      title: 'How do I know how much storage space I will need?',
      paragraphs: [
        <React.Fragment>Check out our <Link className="red" to="/unitSizes">Unit Sizes page</Link> or give one of our storage experts a call – we’re here to help 7 days a week.</React.Fragment>,
      ],
    },
    {
      id: 'LOCK',
      title: 'Do I need to bring my own lock?',
      paragraphs: [
        'You can purchase a lock at our onsite vending machine in the rental center. It’s just $12, or you can use your own locking device.',
      ],
    },
    {
      id: 'ILLEGAL_ITEMS',
      title: 'Are there any items that I am NOT able to store?',
      paragraphs: [
        'We do not permit storage of liquids, explosives, flammable liquids, toxic materials, and perishables. If you have questions, reach out and call us. We’re happy to discuss your needs and come up with a storage strategy.',
      ],
    },
    {
      id: 'CONTACT',
      title: 'How do I contact Red Dot?',
      paragraphs: [
        'Select the location you wish to store your belongings or commercial items at, and you’ll have access to the best phone number to reach one of our storage experts. You can also Live Chat with our customer support team by clicking the button in the bottom right of your web browser.',
      ],
    },
    {
      id: 'PROBLEMS',
      title: 'There is a problem with my unit. What can I do?',
      paragraphs: [
        'Give us a call – our customer service team will be able to assist you.',
      ],
    },
  ],
  tips: [
    {
      id: 'GENERAL',
      items: [
        'Rent the smallest amount of space you will need and pack the unit full.',
        'Gather as many boxes as you think you will need, tape, markers, and packing material, like foam shipping noodles, Bubble Wrap, clean packing paper, or towels.',
        'If possible, try to use uniformly sized boxes for easy storage and stable stacking inside your unit.',
        'Make up an inventory of your items and keep it in a safe place at home or in a safe deposit box away from your unit. Consider photographing or video recording the contents of your unit, too.',
        'Prepare your space by placing a protective cover or pallet on the floor of your rental unit and under your goods to guard against dampness.',
        'Plan your storage space. Place items you need to access often in the front of your unit and items you know you will not need in the back.',
        <React.Fragment><span style={{ fontWeight: 'bold' }}>CAUTION!</span> Do not store anything combustible, including paint thinner, gasoline, solvents, or paint.</React.Fragment>,
        'For convenient access inside your unit, leave an aisle down the center and a small space between the walls and your storage boxes or items – this provides good air circulation.',
        'Stack heavier boxes toward the bottom and lighter boxes on top.',
        'Be sure your boxes are strong enough to hold 25 to 30 pounds, and err on the side of less for packing your boxes.',
        'List the contents of your box on all four sides, and number your boxes for inventory. Then, seal each with tape.',
      ],
      image: {
        alt: 'A customer packing boxes into a car',
        src: packingGeneral,
      },
      title: 'GENERAL PRINCIPLES',
    },
    {
      id: 'SPECIFICS',
      items: [
        'Dishes and glasses should be wrapped in paper and packed in sturdy boxes or in a specialized dish-pack system. Store these boxes on top to avoid damage due to heavier weight crushing the contents.',
        'Pictures and mirrors should be wrapped in cardboard or packed in picture/mirror boxes and marked "FRAGILE." Stack them on end and not flat.',
        'Furniture with drawers can be utilized as storage places for pictures, knick-knacks, china, silverware, and small items. Before placing items in drawers, wrap them in tablecloths, towels, or blankets to protect them and prevent breakage.',
        'Larger appliances such as refrigerators and stoves should be thoroughly cleaned and the door(s) left slightly ajar.',
        'Place TVs and other electronics in the rear of your unit.',
        'Shovels, hoes, rakes, and hoses can be stored together in empty trash cans. Stack extra cans inside one another.',
        'Tables that have removable legs should be broken down to save space.',
        'Sofas and loveseats with flat sides can be stored on end to save floor space. Wrap cushions in plastic and place on top of sofas.',
        'Avoid placing sharp or heavy objects on top of upholstered furniture.',
        'Cover stuffed furniture with blankets or use plastic furniture covers to protect against dust.',
        'If you stand mattresses on their side, prop them up so they stand straight.',
        'Wipe any items made of metal like bikes, metal tables, and tools with machine oil to prevent rusting.',
      ],
      image: {
        alt: 'A couple packing their car',
        src: packingSpecifics,
      },
      title: 'ITEM SPECIFICS',
    },
  ],
};

FAQ.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  faqs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    paragraphs: PropTypes.arrayOf(PropTypes.node),
  })),
  tips: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.node),
    title: PropTypes.string,
  })),
  title: PropTypes.string,
};

export default withStyles(styles)(FAQ);
