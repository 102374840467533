import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid,
  Typography,
} from 'material-ui';

import ResetPasswordForm from './resetPasswordForm';
import AppLogo from '../../../components/Logo';

const ResetPassword = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Grid
      container
      direction="column"
      spacing={0}
      justify="center"
      alignItems="center"
      className="minusMargin"
    >
      <div className="login-logo">
        <AppLogo />
      </div>
      <Grid item className="login-title">
        <h1 className="login-heading">Set Your Password</h1>
      </Grid>
      <Grid item className="password-rule">
        <Typography align="center" type="body1">
          {
            `Passwords must be at least 8 characters long and contain upper-case letters,
            lower-case letters, numbers and at least 1 special character. May not contain 'password' or 'reddot'.`
          }
        </Typography>
      </Grid>
      <Grid item>
        <ResetPasswordForm />
      </Grid>
      <div className="login-footer">
        <Typography align="center">Copyright {moment().year()} Red Dot Storage. All Rights Reserved</Typography>
      </div>
    </Grid>
  );
};
ResetPassword.defaultProps = {
  title: 'Reset Password',
};
ResetPassword.propTypes = {
  title: PropTypes.string,
};

export default ResetPassword;
