import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { stopSubmit } from 'redux-form';
import { goBack, push } from 'react-router-redux';

import { appMsg } from '../AppWrapper/reducer';
import {
  createPayment,
  updatePayment,
  deletePayment,
  updateAutoPay,
  createTransaction,
  getPaymentAuthorizationMessage,
} from '../../services/billing';
import { getAmountDue, getPayment, getExpectedMonthlyCharge, getAccountBalance } from './selectors';
import { getSelectedUnitId, getSelectedFacility, getSelectedUnit } from '../MyUnits/selectors';
import { getMagicKey } from '../Auth/selectors';
import { updateUnit } from '../MyUnits/reducer';

export const constants = {
  FETCH_BILLING: 'containers/Billing/FETCH_BILLING',
  FETCH_BILLING_ERROR: 'containers/Billing/FETCH_BILLING_ERROR',
  FETCH_BILLING_SUCCESS: 'containers/Billing/FETCH_BILLING_SUCCESS',
  CREATE_PAYMENT_SUCCESS: 'containers/Billing/CREATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_SUCCESS: 'containers/Billing/UPDATE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_SUCCESS: 'containers/Billing/DELETE_PAYMENT_SUCCESS',
  UPDATE_AUTOPAY_SUCCESS: 'containers/Billing/UPDATE_AUTOPAY_SUCCESS',
  CREATE_TRANSACTION_SUCCESS: 'containers/Billing/CREATE_TRANSACTION_SUCCESS',
  UPDATE_SELECTED_FACILITY: 'containers/Billing/UPDATE_SELECTED_FACILITY',
  UPDATE_SELECTED_UNIT: 'containers/Billing/UPDATE_SELECTED_UNIT',
  UPDATE_SELECTED_FACILITY_UNIT: 'containers/Billing/UPDATE_SELECTED_FACILITY_UNIT',
  FETCH_PAYMENT_AUTHORIZATION_MESSAGE_SUCCESS: 'containers/Account/FETCH_PAYMENT_AUTHORIZATION_MESSAGE_SUCCESS',
};

export const fetchBilling = createAction(constants.FETCH_BILLING);
export const fetchBillingError = createAction(constants.FETCH_BILLING_ERROR);
export const fetchBillingSuccess = createAction(constants.FETCH_BILLING_SUCCESS);
export const createPaymentSuccess = createAction(constants.CREATE_PAYMENT_SUCCESS);
export const updatePaymentSuccess = createAction(constants.UPDATE_PAYMENT_SUCCESS);
export const deletePaymentSuccess = createAction(constants.DELETE_PAYMENT_SUCCESS);
export const updateAutoPaySuccess = createAction(constants.UPDATE_AUTOPAY_SUCCESS);
export const createTransactionSuccess = createAction(constants.CREATE_TRANSACTION_SUCCESS);
export const updateSelectedFacility = createAction(constants.UPDATE_SELECTED_FACILITY);
export const updateSelectedUnit = createAction(constants.UPDATE_SELECTED_UNIT);
export const updateSelectedFacilityUnit = createAction(constants.UPDATE_SELECTED_FACILITY_UNIT);
export const fetchPaymentAuthorizationMessageSuccess =
  createAction(constants.FETCH_PAYMENT_AUTHORIZATION_MESSAGE_SUCCESS);

const initialState = fromJS({
  data: null,
  loading: false,
  loaded: false,
  error: null,
  paymentAuthorizationMessage: null,
  selectedFacilityId: null,
  selectedUnitId: null,
});

function withUnitData(getState, data) {
  return {
    ...data,
    unit_id: getSelectedUnitId(getState()),
  };
}

function isNSFBlocked(getState, paymentId) {
  const payment = getPayment(getState(), parseInt(paymentId, 10)).toJS()
  return payment.nsf_blocked
}

/* Thunks */
export const createPaymentThunk = (data) =>
  (dispatch, getState) => {
    const facility = getSelectedFacility(getState()).toJS();
    const paymentData = {
      ...data,
      authnet_client_key: facility.authnet_client_key,
      authnet_login: facility.authnet_login,
      facility_id: facility.id,
    };

    return createPayment(paymentData, false, getMagicKey(getState()))
      .then(({ result, error }) => {
        if (error) {
          dispatch(stopSubmit('paymentForm', error))
          throw new Error(error);
        } else {
          window.gtag('event', 'add_payment_info', {
            payment_type: paymentData.profile_type,
            items: [{
              item_list_id: paymentData.facility_id,
              item_list_name: facility.site_code || facility.store_number,
              item_id: result.id,
              item_name: result.line,
              item_category: 'PaymentMethod',
            }],
          });

          dispatch(createPaymentSuccess(result));
          dispatch(appMsg('Payment method has been added!', 'success'));
          dispatch(goBack());
        }
      });
  }

export const updatePaymentThunk = (data) =>
  (dispatch) => updatePayment(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(appMsg('Can\'t update default payment method!', 'error'))
        throw new Error(error);
      } else {
        dispatch(updatePaymentSuccess(result))
        dispatch(appMsg(`Successfully set ${result.line} as default payment method!`, 'success'))
      }
    })

export const deletePaymentThunk = (data) =>
  (dispatch) => deletePayment(data)
    .then(({ result, error }) => {
      if (error) {
        dispatch(appMsg('Payment method cannot be removed', 'error'))
      } else {
        dispatch(deletePaymentSuccess(result))
        dispatch(appMsg('A payment method has been removed!', 'success'))
        dispatch(push('/billing/paymentMethods'))
      }
    })

export const createTransactionThunk = (data) =>
  (dispatch, getState) => {
    const state = getState();
    const amountDue = parseFloat(getAmountDue(state));
    const accountBalance = parseFloat(getAccountBalance(state));
    const monthlyCharge = parseFloat(getExpectedMonthlyCharge(state));
    const expectedAmountDue = amountDue + monthlyCharge;

    let isValid = accountBalance > 0;
    if (data.amount_choice === 'upcoming_rent') {
      isValid = expectedAmountDue > 0;
    } else if (data.amount_choice === 'amount_due') {
      isValid = amountDue > 0;
    }

    if (!isValid) {
      dispatch(stopSubmit('transactionForm', {
        _error: 'Unable to submit payment. We will accept your payment after you receive your upcoming invoice.',
      }));
      return Promise.reject();
    }
    if (!data.payment_id) {
      dispatch(stopSubmit('transactionForm', {
        _error: 'Unable to submit payment. You must choose at least one payment method.',
      }));
      return Promise.reject();
    }

    const unit = getSelectedUnit(state);
    const facility = getSelectedFacility(state);
    if (isNSFBlocked(getState, data.payment_id)) {
      const sitePhoneNumber = facility.get('phone_number');
      dispatch(stopSubmit('transactionForm', {
        _error: `You have had three ACH failures. Please contact customer service for assistance in making a payment. ${sitePhoneNumber}`,
      }));
      return Promise.reject();
    }

    const transactData = withUnitData(getState, data);
    return createTransaction(transactData, getMagicKey(state))
      .then(({ result, error }) => {
        if (error) {
          dispatch(stopSubmit('transactionForm', error));
          dispatch(appMsg('Can\'t complete payment!', 'error'));
          throw new Error(error);
        } else {
          window.gtag('event', 'payment', {
            currency: 'USD',
            transaction_id: result.transactions[0].transaction_id,
            payment_id: data.payment_id,
            value: result.transactions[0].amount,
            balance_remaining: result.account_balance,
            items: [{
              item_id: transactData.unit_id,
              item_name: unit.get('unit_number'),
              item_list_id: facility.get('id'),
              item_list_name: facility.get('site_code') || facility.get('store_number'),
            }],
          });

          dispatch(createTransactionSuccess(result));
          dispatch(updateUnit({ id: transactData.unit_id }));
          dispatch(appMsg('Payment submitted.', 'success'));
        }
      });
  }

export const autoPayThunk = (data) =>
  (dispatch, getState) => updateAutoPay(withUnitData(getState, data))
    .then(({ result, error }) => {
      if (error) {
        dispatch(appMsg('Can\'t update autopay status!', 'error'));
        throw new Error(error);
      } else {
        dispatch(updateAutoPaySuccess(result));
        dispatch(appMsg('Autopay status has been updated!', 'success'));
      }
    })

export const fetchPaymentAuthorizationMessageThunk = (data) =>
  (dispatch) => getPaymentAuthorizationMessage(data).then(({ result, error }) => {
    if (error) {
      dispatch(appMsg('Error retrieving Payment Authorization Message', 'error'));
    }
    dispatch(fetchPaymentAuthorizationMessageSuccess(result && result.length ? result[0] : null));
  });

/* Reducers */
export default handleActions({
  [fetchBilling]: (state) => state
    .set('loading', true)
    .set('loaded', false),

  [fetchBillingSuccess]: (state, action) => state
    .set('data', fromJS(action.payload))
    .set('loading', false)
    .set('loaded', true),

  [fetchBillingError]: (state, action) => state
    .set('error', action.payload)
    .set('loading', false)
    .set('loaded', false),

  [createPaymentSuccess]: (state, action) => state
    .updateIn(['data', 'payment_methods'], arr => arr.push(fromJS(action.payload))),

  [updatePaymentSuccess]: (state, action) => state
    .updateIn(['data', 'payment_methods'], arr => {
      let newArr = arr;
      if (action.payload.default) {
        const originalDefaultIndex = newArr.findIndex(s => s.get('default'))
        newArr = newArr.setIn([originalDefaultIndex, 'default'], false)
      }
      const uIndex = newArr.findIndex(s => s.get('id') === action.payload.id)
      return newArr.set(uIndex, fromJS(action.payload))
    }),

  [deletePaymentSuccess]: (state, action) => {
    const deleteIndex = state.getIn(['data', 'payment_methods']).findIndex(s => s.get('id') === action.payload.id)
    let newState = state;
    if (state.getIn(['data', 'payment_methods', deleteIndex, 'default']) === true && action.payload.current_default) {
      const defaultIndex = state
        .getIn(['data', 'payment_methods'])
        .findIndex(s => s.get('id') === action.payload.current_default)
      newState = state.setIn(['data', 'payment_methods', defaultIndex, 'default'], true)
    }
    return newState
      .deleteIn(['data', 'payment_methods', deleteIndex]);
  },

  [updateAutoPaySuccess]: (state, action) => state
    .setIn(['data', 'auto_pay_id'], action.payload.auto_pay_id),

  [createTransactionSuccess]: (state, action) => state
    .set('data', fromJS(action.payload)),

  [updateSelectedFacility]: (state, action) => state
    .set('selectedUnitId', null)
    .set('selectedFacilityId', action.payload)
    .set('loading', true)
    .set('loaded', false),

  [updateSelectedUnit]: (state, action) => state
    .set('selectedUnitId', action.payload)
    .set('loading', true)
    .set('loaded', false),

  [updateSelectedFacilityUnit]: (state, action) => state
    .set('selectedFacilityId', action.payload.facility_id)
    .set('selectedUnitId', action.payload.unit_id)
    .set('loading', true)
    .set('loaded', false),

  [fetchPaymentAuthorizationMessageSuccess]: (state, action) => state
    .set('paymentAuthorizationMessage', action.payload && fromJS(action.payload)),

}, initialState)
