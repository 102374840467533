export const getReservationReducer = (state) => state.containers.reservationReducer;
export const getLoaded = (state) => getReservationReducer(state).get('loaded');
export const getError = (state) => getReservationReducer(state).get('error');
export const getUnit = (state) => getReservationReducer(state).get('unitData');
export const getFacility = (state) => getReservationReducer(state).get('facilityData');
export const getFacilityLoading = (state) => getReservationReducer(state).get('facilityLoading');
export const getFacilityLoaded = (state) => getReservationReducer(state).get('facilityLoaded');
export const getPaymentMethods = (state) => getReservationReducer(state).getIn(['facilityData', 'payment_methods']);
export const getPaymentMethod = (state, id) =>
  (getPaymentMethods(state) || []).find((payment) => payment.get('id') === id);
export const getReservation = (state) => getReservationReducer(state).get('reservationData');
export const getMoveIn = (state) => getReservationReducer(state).get('moveInData');
export const getMoveInCosts = (state) => getReservationReducer(state).get('moveInCosts');
export const getMoveInCostError = (state) => getReservationReducer(state).get('moveInCostError');
export const getMoveInState = (state) => getReservationReducer(state).get('moveInState');
export const getSelectedFacilityId = (state) => getFacility(state).get('id');
export const getBusinessData = (state) => getReservationReducer(state).get('businessData');
export const isBusiness = (state) => getReservationReducer(state).get('businessData').get('isBusiness');
export const getLien = (state) => getReservationReducer(state).get('lienData');
export const hasLien = (state) => getReservationReducer(state).get('lienData').get('hasLien');
