import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { result } from 'lodash';

import GeneralForm from '../../components/Form';
import { createAlternateThunk, updateAlternateThunk, deleteAlternateThunk } from './reducer';
import { getAlternate } from '../Auth/selectors';
import { email, required } from '../../utils/validations';

const fields = [{
  name: 'first_name',
  label: 'First Name',
  required: true,
  validate: [required],
}, {
  name: 'last_name',
  label: 'Last Name',
  required: true,
  validate: [required],
}, {
  fullWidth: true,
  name: 'email',
  label: 'Email',
  validate: [email],
}]

const mapStateToProps = (state, ownProps) => ({
  /* Get Id of item, Try to convert Immutable to JS object or return null */
  initialValues: result(getAlternate(state, parseInt(ownProps.match.params.itemId, 10)), 'toJS', null),
  fields,
  shrinkLabels: true,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = {
    create: createAlternateThunk,
    update: updateAlternateThunk,
    delete: deleteAlternateThunk,
  };
  if (ownProps.onCancel) actions.cancel = ownProps.onCancel;

  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'alternateForm',
})(GeneralForm)));

