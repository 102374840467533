import fetchData from './apiRequest';

export function createAccountProfile(data) {
  return fetchData(
    '/accounts/user',
    {
      method: 'POST',
      body: data,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function updateAccountProfile(data) {
  const { id, ...body } = data;
  return fetchData(
    `/users/${id}`,
    {
      method: 'PATCH',
      body,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function updatePassword(data) {
  return fetchData(
    '/api/customer/v1/auth',
    {
      method: 'PUT',
      body: data,
    },
  );
}

/* Alternates */
export function createAlternate(data) {
  return fetchData(
    '/api/customer/v1/alternates',
    {
      method: 'POST',
      body: data,
    },
  );
}
export function updateAlternate(data) {
  return fetchData(
    `/api/customer/v1/alternates/${data.id}`,
    {
      method: 'PUT',
      body: data,
    },
  );
}
export function deleteAlternate(data) {
  return fetchData(
    `/api/customer/v1/alternates/${data}`,
    {
      method: 'DELETE',
    },
  );
}

/* Address */
export function createAddress(data) {
  return fetchData(
    '/api/customer/v1/addresses',
    {
      method: 'POST',
      body: data,
    },
  );
}
export function updateAddress(data) {
  return fetchData(
    `/api/customer/v1/addresses/${data.id}`,
    {
      method: 'PUT',
      body: data,
    },
  );
}
export function deleteAddress(data) {
  return fetchData(
    `/api/customer/v1/addresses/${data}`,
    {
      method: 'DELETE',
    },
  );
}

/* Contacts */

export function createContact(data) {
  return fetchData(
    '/api/customer/v1/contacts',
    {
      method: 'POST',
      body: data,
    },
  );
}
export function updateContact(data) {
  return fetchData(
    `/api/customer/v1/contacts/${data.id}`,
    {
      method: 'PUT',
      body: data,
    },
  );
}
export function deleteContact(data) {
  return fetchData(
    `/api/customer/v1/contacts/${data}`,
    {
      method: 'DELETE',
    },
  );
}

/* Vehicles */
export function createVehicle(data) {
  return fetchData(
    '/vehicles',
    {
      method: 'POST',
      body: data,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function updateVehicle(data) {
  const { id, ...body } = data;
  return fetchData(
    `/vehicles/${id}`,
    {
      method: 'PATCH',
      body,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function deleteVehicle(id) {
  return fetchData(`/vehicles/${id}`, { method: 'DELETE' }, process.env.REACT_APP_RR_API_HOST);
}

export function fetchVehicles(id) {
  return fetchData(`/vehicles?where=${JSON.stringify({ owner_type: 'Account', owner_id: id })}&include=CustomerInsurances`, { method: 'GET' }, process.env.REACT_APP_RR_API_HOST);
}

/* Customer Insurance */
export function createCustomerInsurance(data) {
  return fetchData(
    '/customer_insurances',
    {
      method: 'POST',
      body: data,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function updateCustomerInsurance(data) {
  const { id, ...body } = data;
  return fetchData(
    `/customer_insurances/${id}`,
    {
      method: 'PATCH',
      body,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function deleteCustomerInsurance(id) {
  return fetchData(`/customer_insurances/${id}`, { method: 'DELETE' }, process.env.REACT_APP_RR_API_HOST);
}

export function fetchCustomerInsurance(id) {
  return fetchData(`/customer_insurances?where=${JSON.stringify({ account_id: id })}`, { method: 'GET' }, process.env.REACT_APP_RR_API_HOST);
}
