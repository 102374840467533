import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  fetchFacility,
} from '../../services/facility';
import {
  constants,
  fetchFacilitySuccess,
  fetchFacilityError,
} from './reducer';
import { getFetchedFacilityId, getFacility } from './selectors'

function* fetchFacilitySaga(action) {
  const facilityId = yield select(getFetchedFacilityId)

  // eslint-disable-next-line
  if (!action.payload.force && action.payload.facility_id == facilityId) {
    const result = yield select(getFacility)
    yield put(fetchFacilitySuccess(result))
  } else {
    const { result, error } = yield call(fetchFacility, action.payload.facility_id);
    if (error) {
      yield put(fetchFacilityError(error));
    } else {
      yield put(fetchFacilitySuccess(result));
    }
  }
}

function* facilitySaga() {
  yield takeLatest(constants.FETCH_FACILITY, fetchFacilitySaga);
}

export default facilitySaga;
