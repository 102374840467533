import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { result } from 'lodash';

import {
  Button,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';

import { getUnit, getFacility, getReservation } from '../selectors';
import { getCityState, getCityStateZip } from '../../../utils/address';
import { routePropTypes } from '../../../utils/routes';

const styles = (theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
  },
  centerBlock: {
    width: '100%',
    textAlign: 'center',
  },
  lastBlock: {
    marginTop: 40,
    textAlign: 'right',
  },
  justifyCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    margin: 12,
    padding: '0px 40px',
  },
});

class CompleteReservation extends Component {
  componentDidMount() {
    document.title = this.props.title;
  }

  handleMoveIn = () => {
    const {
      unit,
    } = this.props
    this.props.history.push(`/reservation/${unit.id}/moveIn`);
  }

  todaySelected = () => {
    const { reservation } = this.props;
    return reservation.move_in_date === moment().format('YYYY-MM-DD')
  }

  render() {
    const {
      classes,
      reservation,
      facility,
    } = this.props;

    return (
      <Grid container spacing={24} className={classes.container}>
        <Grid item sm={12} className={classes.centerBlock}>
          <Typography type="display1" paragraph>Your reservation is complete!</Typography>
          <Typography type="title" color="secondary" gutterBottom>Confirmation #{reservation.confirmation}</Typography>
          <Typography type="headline" gutterBottom>
            Move-In Date: {moment(reservation.move_in_date).format('MMMM D, YYYY')}
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.justifyCenter}>
          <Typography type="title"><b>Location</b></Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography type="subheading"><b>Red Dot Storage #{facility.store_number}</b></Typography>
          <Typography type="subheading"><b>{getCityState(facility.default_address)}</b></Typography>
        </Grid>
        <Grid item sm={6} className={classes.justifyCenter}>
          <Typography type="title"><b>Address</b></Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography type="body2">{facility.street_address}</Typography>
          <Typography type="body2">{getCityStateZip(facility.default_address)}</Typography>
        </Grid>
        <Grid item sm={12} className={classes.centerBlock} color="secondary">
          <Typography type="body1">You will receive an email with full detail on your reservation. If you would like to complete the move-in process now, click Move-In below!</Typography>
        </Grid>
        <Grid item sm={12} className={classes.lastBlock}>
          <Button
            color="secondary"
            onClick={this.handleMoveIn}
          >
            Move-In
          </Button>
          <Button
            raised
            color="secondary"
            className={classes.button}
            onClick={() => this.props.history.push('/')}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    );
  }
}

CompleteReservation.defaultProps = {
  unit: null,
  facility: null,
  reservation: null,
  title: 'Reservation Complete',
};

CompleteReservation.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  unit: PropTypes.object,
  facility: PropTypes.object,
  reservation: PropTypes.object,
  title: PropTypes.string,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  unit: result(getUnit(state), 'toJS', null),
  facility: result(getFacility(state), 'toJS', null),
  reservation: result(getReservation(state), 'toJS', {}),
});

const mapDispatchToProps = () => ({
});

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteReservation));
