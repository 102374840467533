/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
} from 'material-ui';

const styles = (theme) => ({
  cardContainer: {
    padding: '38px',
  },
  cardHeader: {
    color: '#373a3c',
  },
  imageContainer: {
    background: theme.palette.background.light,
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: '6px',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: '20px 25px',
  },
  unitImage: {
    display: 'block',
    height: '100%',
    margin: 'auto',
    // should be set to max native height of provided images
    maxHeight: '400px',
    maxWidth: '100%',
  },
  unitText: {
    marginBottom: '1em',
    marginTop: '1em',
  },
});

const UnitCard = ({
  alt,
  classes,
  image,
  orientation,
  paragraphs,
  title,
}) => (
  <Grid alignItems="center" className={orientation === 'horizontal' ? classes.cardContainer : ''} container justify="space-around">
    {/* Text content */}
    <Grid item style={orientation === 'horizontal' ? { maxWidth: '50%' } : {}} xs>
      <Typography className={`${classes.unitText} ${classes.cardHeader}`} component="p" type="display1">{title}</Typography>
      {
        paragraphs.map((content, index) => <Typography className={`${classes.unitText}`} key={`paragraph_${index}`} type="body1" style={{ fontSize: '19px' }}>{content}</Typography>)
      }
    </Grid>
    {/* Unit image */}
    <Grid className={classes.imageContainer} item>
      <img alt={alt} className={classes.unitImage} src={image} />
    </Grid>
  </Grid>
);

UnitCard.defaultProps = {
  alt: 'Unit',
  image: undefined,
  orientation: 'horizontal',
  paragraphs: [],
  title: '',
};

UnitCard.propTypes = {
  alt: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  image: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  paragraphs: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.string,
};

export default withStyles(styles)(UnitCard);
