import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchFacilities } from '../../services/facility';
import {
  constants,
  fetchFacilitiesSuccess,
  fetchFacilitiesError,
} from './reducer';

function* fetchFacilitiesSaga() {
  const { result, error } = yield call(fetchFacilities);
  if (error) {
    yield put(fetchFacilitiesError(error));
  } else {
    yield put(fetchFacilitiesSuccess(result));
  }
}

function* facilitiesSaga() {
  yield takeLatest(constants.FETCH_FACILITIES, fetchFacilitiesSaga);
}

export default facilitiesSaga;
