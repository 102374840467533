import fiveXfive from './5x5.png';
import fiveXten from './5x10.png';
import fiveXfifteen from './5x15.png';
import tenXten from './10x10.png';
import tenXfifteen from './10x15.png';
import tenXtwenty from './10x20.png';
import tenXtwentyfive from './10x25.png';
import tenXthirty from './10x30.png';

export default [
  {
    alt: '5x5 Storage Unit',
    image: fiveXfive,
    label: '5 x 5',
    paragraphs: [
      'Equivalent to: Regular Closet',
      'This Unit will hold: Personal items, clothes, boxes, skis, children\'s toys, small furniture items, business records and sales rep materials.',
    ],
    title: "UNIT SIZE: 5'X5' (25 SQ. FT.)",
  },
  {
    alt: '5x10 Storage Unit',
    image: fiveXten,
    label: '5 x 10',
    paragraphs: [
      'Equivalent to: Walk-in Closet',
      'This Unit will hold: Small amounts of furniture, sofas, chairs, chest of drawers, boxspring and mattress, business supplies and records. Plus other small items and boxes.',
    ],
    title: "UNIT SIZE: 5'X10' (50 SQ. FT.)",
  },
  {
    alt: '5x15 Storage Unit',
    image: fiveXfifteen,
    label: '5 x 15',
    paragraphs: [
      'Equivalent to: Large Closet',
      'This Unit will hold: Small amounts of furniture, sofas, chairs, chest of drawers, boxspring and mattress, business supplies and records. Plus other small items and boxes.',
    ],
    title: "UNIT SIZE: 5'X15' (75 SQ. FT.)",
  },
  {
    alt: '10x10 Storage Unit',
    image: tenXten,
    label: '10 x 10',
    paragraphs: [
      'Equivalent to: Half of a one-car garage',
      'This Unit will hold: One-bedroom apartment with major appliances, other furniture and supplies.',
    ],
    title: "UNIT SIZE: 10'X10' (100 SQ. FT.)",
  },
  {
    alt: '10x15 Storage Unit',
    image: tenXfifteen,
    label: '10 x 15',
    paragraphs: [
      'Equivalent to: Two-thirds of a one-car garage',
      'This Unit will hold: A two-bedroom apartment or house, including appliances, boxes and miscellaneous items and commercial storage inventory.',
    ],
    title: "UNIT SIZE: 10'X15' (150 SQ. FT.)",
  },
  {
    alt: '10x20 Storage Unit',
    image: tenXtwenty,
    label: '10 x 20',
    paragraphs: [
      'Equivalent to: Standard one-car garage',
      'This Unit will hold: A three-bedroom house with major appliances, plus lots of boxes. Business storage files and inventory ideal for storage racks.',
    ],
    title: "UNIT SIZE: 10'X20' (200 SQ. FT.)",
  },
  {
    alt: '10x25 Storage Unit',
    image: tenXtwentyfive,
    label: '10 x 25',
    paragraphs: [
      'Equivalent to: One-car garage',
      'This Unit will hold: A three-bedroom house with major appliances, lots of boxes plus a home office. Business storage files and inventory ideal for storage racks.',
    ],
    title: "UNIT SIZE: 10'X25' (250 SQ. FT.)",
  },
  {
    alt: '10x30 Storage Unit',
    image: tenXthirty,
    label: '10 x 30',
    paragraphs: [
      'Equivalent to: Standard two-car garage',
      'This Unit will hold: A four-to five-bedroom house with major appliances and room for boxes. Excellent for business storage of files, equipment and permanent records.',
    ],
    title: "UNIT SIZE: 10'X30' (300 SQ. FT.)",
  },
];
