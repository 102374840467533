import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchBilling(unitId, magicKey) {
  return yield call(
    fetchData,
    `/api/customer/v1/billing?unit_id=${unitId}`,
    {
      method: 'GET',
      headers: magicKey ? { 'X-USER-MAGIC-KEY': magicKey } : undefined,
    },
  );
}

export function updatePayment(data) {
  return fetchData(
    `/api/customer/v1/payments/${data.id}`,
    {
      method: 'PATCH',
    },
  );
}
export function deletePayment(data) {
  return fetchData(
    `/api/customer/v1/payments/${data}`,
    {
      method: 'DELETE',
    },
  );
}
export function updateAutoPay(data) {
  return fetchData(
    '/api/customer/v1/auto_pay',
    {
      method: 'PATCH',
      body: data,
    },
  );
}
export function createTransaction(data, magicKey) {
  return fetchData(
    '/api/customer/v1/transactions',
    {
      method: 'POST',
      body: data,
      headers: magicKey ? { 'X-USER-MAGIC-KEY': magicKey } : undefined,
    },
  );
}

export function getPaymentAuthorizationMessage({ where, ...query } = {}) {
  return fetchData(
    `/payment_authorizations/messages?${new URLSearchParams({
      limit: 1,
      order: JSON.stringify([['id', 'DESC']]),
      where: JSON.stringify({ auth_type: 'CP', ...where }),
      ...query,
    })}`,
    { method: 'GET' },
    process.env.REACT_APP_RR_API_HOST,
  );
}

/* Accept.js */

/* Convert Accept.js XmlHttpRequest to Promise */
function createPaymentAsync(data) {
  return new Promise(resolve => {
    window.Accept.dispatchData(data, resolve);
  });
}

/* Parse Accept Errors */
function parseErrors(response) {
  let i = 0
  const result = []

  while (i < response.messages.message.length) {
    result.push(`${response.messages.message[i].code}: ${response.messages.message[i].text}`)
    i += 1;
  }
  return result.join('\n');
}

/*
  Refines card/bank data before pass to Accept.js
  Warning: Accept.js slightly fails when the values are not string(ex: Integer)
    Ensure that they are String.
*/
function refinedData(data) {
  const authData = {
    clientKey: data.authnet_client_key,
    apiLoginID: data.authnet_login,
  }

  const newData = {}
  newData.authData = authData

  if (data.profile_type === 'card') {
    const { addressAttributes, ...cardDataWithoutAddress } = data.cardData;
    newData.cardData = cardDataWithoutAddress
    newData.cardData.cardNumber = newData.cardData.cardNumber.replace(/ /g, '')
  } else {
    newData.bankData = data.bankData
  }
  return newData
}

/* Build body to send to Cobalt */
function buildRequestBody(data, acceptResponse) {
  let body = { profile_type: data.profile_type, facility_id: data.facility_id }
  const { opaqueData } = acceptResponse

  if (data.profile_type === 'card') {
    body = {
      ...body,
      opaqueData,
      card: {
        month: data.cardData.month,
        year: data.cardData.year,
        address_attributes: data.cardData.addressAttributes,
      },
    }
  } else if (data.profile_type === 'ach') {
    body = {
      ...body,
      account: data.bankData.accountNumber,
      routing: data.bankData.routingNumber,
      bank_name: data.bankData.bankName,
      account_holder: data.bankData.nameOnAccount,
      account_type: data.bankData.accountType,
    }
  }

  return body
}

let paymentRecordIndex = 0;
function buildResponse(data) {
  paymentRecordIndex += 1;
  if (data.profile_type === 'card') {
    return {
      id: `new_${paymentRecordIndex}`,
      line: `Card (${data.cardData.cardNumber.substr(-4)})`,
      ...data,
    };
  }

  return {
    id: `new_${paymentRecordIndex}`,
    line: `${data.bankData.bankName} ${data.bankData.accountType}`,
    ...data,
  };
}

export function createPayment(data, bValidateOnly, magicKey) {
  return createPaymentAsync(refinedData(data)).then(response => {
    if (response.messages.resultCode === 'Error') {
      return { error: { _error: parseErrors(response) } }
    }

    // Validate payment without associating with User
    if (bValidateOnly) {
      return { result: buildResponse(data) };
    }

    return fetchData(
      '/api/customer/v1/payments',
      {
        method: 'POST',
        body: buildRequestBody(data, response),
        headers: magicKey ? { 'X-USER-MAGIC-KEY': magicKey } : undefined,
      },
    );
  })
}
