import {
  getAuthHeader,
  extractAuthHeader,
  setAuthHeader,
  getBearerAuthorization,
} from './auth';
import { formatPasswordError } from '../utils/password';

/* TODO: Try https://github.com/paularmstrong/normalizr */
/* eslint no-underscore-dangle: 0 */

/*
  Data downloading using pure JS fetch
  @type: JS object
  { result: resultObj, error: errorObj }
*/

const defaultHeader = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};

const DEFAULT_ERROR_MESSAGE = 'Request was not successful.';

// const serverErrorMsg = 'We have an issue on the server. We are notified.'
/*
  Make Error Message Redux-Form comfortable
  - Move base error to _error key
  - Merge error msgs under each field
  - Format error messages in case of password reset
*/
function refineErrorMsgs(errors) {
  const newErrors = {};

  // Check if it's an hash
  if (Object.prototype.toString.call(errors) === '[object Array]') {
    newErrors._error = errors.join(', ');
  } else {
    Object.keys(errors).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        if (key.indexOf('password') > -1) {
          newErrors[key] = formatPasswordError(key, errors[key]);
        } else {
          newErrors[key] = errors[key].join(', ')
        }
      }
    })
    newErrors._error = newErrors.base;
  }
  return newErrors;
}

async function fetchData(url, opts, host = process.env.REACT_APP_API_HOST) {
  const options = opts;
  const authHeaders = await (
    host === process.env.REACT_APP_API_HOST ? getAuthHeader() : getBearerAuthorization()
  );
  options.headers = { ...options.headers, ...defaultHeader, ...authHeaders };

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  const fetchRequest = new Request(`${host}${url}`, options);
  return fetch(fetchRequest)
    .then(async (response) => {
      // Reset authroize data
      if (host === process.env.REACT_APP_API_HOST) {
        await setAuthHeader(extractAuthHeader(response.headers));

        if (response.status >= 500) {
          window.location.href = '/maintenance';
          // throw new Error(serverErrorMsg);
        }
      }
      // Only redirect to login if session has expired
      // (not if they logged out / haven't logged in)
      if (response.status === 401 && authHeaders && Object.keys(authHeaders).length > 0 && window.location.pathname !== '/') {
        window.location.href = '/?timedout=true';
      }
      return response.json().then((result) => {
        if (response.ok) {
          return { status: response.status, result };
        }
        return {
          status: response.status,
          error: host === process.env.REACT_APP_API_HOST
            ? refineErrorMsgs(result.errors) : result,
        };
      }).catch(() => ({
        status: response.status,
        // Support both old and new style error until we've transitioned away from Redline
        error: { message: DEFAULT_ERROR_MESSAGE, _error: DEFAULT_ERROR_MESSAGE },
      }))
    })
    .catch(error => ({ status: 500, error: { message: error.message, _error: error.message } }))
}

export default fetchData;
