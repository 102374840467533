import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Title = ({ children, className, style }) => (
  <div className={`titleContainer ${className}`} style={style}>
    {
      React.Children.map(children, (child) => React.cloneElement(child, { className: `${child.props.className} titleText` }))
    }
  </div>
);

Title.defaultProps = {
  className: '',
  style: undefined,
};

Title.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Title;
