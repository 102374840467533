import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { result } from 'lodash';

import {
  Button,
  Grid,
  Typography,
  withStyles,
} from 'material-ui';

import { getFacility } from '../selectors';
import { getFacilities } from '../../MyUnits/selectors';
import { getCityState, getCityStateZip } from '../../../utils/address';
import { routePropTypes } from '../../../utils/routes';
import Loading from '../../../components/Loading';

const styles = (theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
  },
  firstBlock: {
    textAlign: 'center',
  },
  lastBlock: {
    marginTop: 40,
    textAlign: 'right',
  },
  justifyCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    margin: 12,
    padding: '0px 40px',
  },
});

class CompleteRental extends Component {
  componentDidMount() {
    document.title = this.props.title;
  }

  goToDashboard = () => {
    this.props.history.push('/');
  }

  render() {
    const {
      classes,
      facility,
      unitId,
    } = this.props;

    const unit = facility.units.find((u) => u.id.toString() === unitId);
    return unit && unit.lease_envelope_id && unit.lease_signed ? (
      <Grid container spacing={24} className={classes.container}>
        <Grid item sm={12} className={classes.firstBlock}>
          <Typography type="display1" paragraph>You are moved in!</Typography>
          <Typography type="title" color="secondary" gutterBottom>Gate Code: {facility.gate_code}#</Typography>
        </Grid>
        <Grid item sm={6} className={classes.justifyCenter}>
          <Typography type="title"><b>Location</b></Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography type="subheading"><b>Red Dot Storage #{facility.store_number}</b></Typography>
          <Typography type="subheading"><b>{getCityState(facility.default_address)}</b></Typography>
        </Grid>
        <Grid item sm={6} className={classes.justifyCenter}>
          <Typography type="title"><b>Address</b></Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography type="body2">{facility.street_address}</Typography>
          <Typography type="body2">{getCityStateZip(facility.default_address)}</Typography>
        </Grid>
        <Grid item sm={12} className={classes.lastBlock}>
          <Button
            raised
            color="secondary"
            className={classes.button}
            onClick={this.goToDashboard}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    ) : <Loading />;
  }
}

CompleteRental.defaultProps = {
  facility: null,
  title: 'Move-In Complete',
};

CompleteRental.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  facility: PropTypes.object,
  title: PropTypes.string,
  unitId: PropTypes.string.isRequired,
  ...routePropTypes,
};

const mapStateToProps = (state) => {
  // If this is the first rental from this facility, it will not yet have gate_id
  const selectedFacility = result(getFacility(state), 'toJS', null);
  // This list should have been updated by the "Move-In" thunk, and WILL have gate_id
  const facilities = result(getFacilities(state), 'toJS', null);
  const updatedFacility = facilities.find((facility) => facility.id === selectedFacility.id);
  return {
    facility: updatedFacility || selectedFacility,
  };
};

const mapDispatchToProps = () => ({
});

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteRental));
