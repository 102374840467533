/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { result } from 'lodash';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  Modal,
  Card,
  CardHeader,
  CardContent,
} from 'material-ui';
import { Warning } from 'material-ui-icons';

import Loading from '../../components/Loading';
import Facility from './Facility';
import { getError, getAllFacilities, getLoaded as getUnitsLoaded, getLoading as getUnitsLoading, getUpdating } from './selectors';
import { fetchUnits } from './reducer';
import { setMapModalOpen, setReturnUrl } from '../AppWrapper/reducer';
import { fetchVehiclesThunk } from '../Account/reducer';
import { getFetching, getLoaded } from '../Account/selectors';
import { getAccountId, getLoggedIn } from '../Auth/selectors';

import './styles.css';

class MyUnits extends Component {
  componentDidMount() {
    document.title = this.props.title;
  }

  facilityList = (facilities) => (
    facilities.map((item) => (
      <Grid item xs={12} sm={8} key={item.gate_code}>
        <Facility error={this.props.error} facility={item} />
      </Grid>
    ))
  )

  countActiveUnits = (facility) => (
    facility.units.filter((u) => !u.moved_out).length
  )

  renderMessageContent = (msg) => (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Typography type="display1" align="center">
          {msg}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Button
          color="secondary"
          fullWidth
          raised
          onClick={() => this.props.actions.setMapModalOpen(true)}
        >
          New Unit
        </Button>
      </Grid>
    </React.Fragment>
  )

  renderMessage = (msg) => (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={24}
    >
      {this.renderMessageContent(msg)}
    </Grid>
  )

  render() {
    const {
      accountId,
      actions,
      error,
      facilities,
      fetchingVehicles,
      loaded,
      loadedVehicles,
      loading,
      loggedIn,
      updating,
    } = this.props;

    if (!loggedIn) {
      return this.renderMessage(<React.Fragment><Link className="link" onClick={() => actions.setReturnUrl(true)} to="/">Login</Link> to view your rented units.</React.Fragment>);
    }

    if (!loaded) {
      if (error) {
        return this.renderMessage('Oops, your rented units could not be retrieved! Please check your internet connection and refresh the page.');
      }

      if (!loading) {
        actions.fetchUnits();
      }

      return (
        <Loading />
      );
    }

    const sroaFacilities = facilities.filter((f) => f.sroa);

    const activeFacilities = facilities.filter((f) => this.countActiveUnits(f) > 0);

    if (activeFacilities.length === 0 && sroaFacilities.length === 0) {
      // Only short-circuit if there are no SROA Facilities to notify customer about.
      return this.renderMessage('You currently have no rented units. Select New Unit below to reserve one today!');
    }

    if (!loadedVehicles && !fetchingVehicles) {
      actions.fetchVehicles(accountId);
    }

    return (
      <Grid container justify="center" alignItems="flex-start" spacing={24}>
        {sroaFacilities.length ? (
          <Grid item xs={12} sm={8}>
            <Card style={{ backgroundColor: '#fff3cd' }}>
              <CardHeader
                avatar={<Warning style={{ height: '2.5rem', width: '2.5rem', color: '#856404' }} />}
                title={
                  <Typography type="title" style={{ color: '#856404' }}>
                    We are happy to announce that Red Dot Storage has been acquired by Storage Rentals of America (SROA), a leading provider of storage solutions across the United States.
                  </Typography>
                }
              />
              <CardContent style={{ paddingTop: 0 }}>
                <Typography variant="paragraph" style={{ color: '#856404' }}>
                  <b>Attention:</b> Your units at:
                  <ul>
                    {sroaFacilities.map((item) => (
                      <li key={item.id}>{item.display_name}</li>
                    ))}
                  </ul>
                  have been transitioned to Storage Rentals of America. For more information about the transition, please visit our <Link className="red" to="/sroa">Transition FAQ</Link> page.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {activeFacilities.length ? this.facilityList(activeFacilities) : this.renderMessageContent('You currently have no rented units. Select New Unit below to reserve one today!')}
        <Modal open={updating}>
          <Loading />
        </Modal>
      </Grid>
    );
  }
}

MyUnits.defaultProps = {
  accountId: null,
  error: null,
  facilities: [],
  fetchingVehicles: false,
  loaded: false,
  loadedVehicles: false,
  loading: false,
  loggedIn: false,
  title: 'My Units',
  updating: false,
};

MyUnits.propTypes = {
  accountId: PropTypes.string,
  actions: PropTypes.shape({
    fetchUnits: PropTypes.func,
    fetchVehicles: PropTypes.func,
    setMapModalOpen: PropTypes.func,
    setReturnUrl: PropTypes.func,
  }).isRequired,
  error: PropTypes.object,
  facilities: PropTypes.array,
  fetchingVehicles: PropTypes.bool,
  loaded: PropTypes.bool,
  loadedVehicles: PropTypes.bool,
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  title: PropTypes.string,
  updating: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  accountId: getAccountId(state),
  error: getError(state),
  facilities: result(getAllFacilities(state), 'toJS', []),
  fetchingVehicles: getFetching(state, 'vehicles'),
  loaded: getUnitsLoaded(state),
  loadedVehicles: getLoaded(state, 'vehicles'),
  loading: getUnitsLoading(state),
  loggedIn: getLoggedIn(state),
  updating: getUpdating(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchUnits,
    fetchVehicles: fetchVehiclesThunk,
    setMapModalOpen,
    setReturnUrl,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyUnits);
