import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem, Paper, TextField, withStyles } from 'material-ui';
import { TextField as ReduxFormTextField } from 'redux-form-material-ui';

const styles = () => ({
  container100: {
    position: 'relative',
    width: '100%',
  },
  container50: {
    position: 'relative',
    width: '50%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

const getSuggestionValue = (suggestion) => suggestion.label;
const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.label, query, { insideWords: true });
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part) => (
          <span key={`${suggestion.label}-${part.text}`} style={{ fontWeight: part.highlight ? 500 : 300 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

const renderInputComponent = (inputProps) => {
  const {
    classes,
    inputRef = () => {},
    ref,
    ...textFieldProps
  } = inputProps;

  const InputField = inputProps.input ? ReduxFormTextField : TextField;
  return (
    <InputField
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...textFieldProps}
    />
  );
}

const AutocompleteField = (props) => {
  const {
    classes,
    containerWidth,
    maxSuggestions,
    suggestions,
    ...inputProps
  } = props;

  const [activeSuggestions, setActiveSuggestions] = useState([]);
  const getSuggestions = useCallback((value = '') => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    if (inputLength === 0) {
      return [];
    }

    // Give priority to items starting with value
    const remainingSuggestions = suggestions.slice(0);
    const items = suggestions.filter((suggestion, index) => {
      const keep = count < maxSuggestions
        && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

      if (keep) {
        count += 1;
        remainingSuggestions.splice(index, 1);
      }

      return keep;
    });

    // If we don't have enough suggestions, add any that include the value somewhere in the string
    return count >= maxSuggestions ? items
      : items.concat(remainingSuggestions.filter((suggestion) => {
        const keep = count < maxSuggestions
          && suggestion.label.toLowerCase().indexOf(inputValue) > 0;

        if (keep) {
          count += 1;
        }

        return keep;
      }));
  }, [maxSuggestions, suggestions]);

  const autoSuggestProps = {
    renderInputComponent,
    suggestions: activeSuggestions,
    onSuggestionsFetchRequested: useCallback(
      ({ value }) => setActiveSuggestions(getSuggestions(value)),
      [getSuggestions],
    ),
    onSuggestionsClearRequested: useCallback(() => setActiveSuggestions([]), []),
    getSuggestionValue,
    renderSuggestion,
    focusInputOnSuggestionClick: false,
  };

  const autoSuggestInputProps = useMemo(() => ({
    ...inputProps,
    classes,
    value: inputProps.input ? inputProps.input.value : inputProps.value,
    onChange: (e, { newValue }) => {
      const { onChange } = inputProps.input ? inputProps.input : inputProps;
      onChange(newValue);
    },
  }), [classes, inputProps]);

  return (
    <Autosuggest
      {...autoSuggestProps}
      inputProps={autoSuggestInputProps}
      theme={{
        container: containerWidth === '100%' ? classes.container100 : classes.container50,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={(opts) => (
        <Paper square {...opts.containerProps}>
          {opts.children}
        </Paper>
      )}
    />
  );
};
AutocompleteField.defaultProps = {
  maxSuggestions: 5,
  containerWidth: '100%',
};
AutocompleteField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  containerWidth: PropTypes.oneOf(['100%', '50%']),
  maxSuggestions: PropTypes.number,
  suggestions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })).isRequired,
};


export default withStyles(styles)(AutocompleteField);
