import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions'

export const constants = {
  FETCH_FACILITY: 'containers/Facility/FETCH_FACILITY',
  FETCH_FACILITY_ERROR: 'containers/Facility/FETCH_FACILITY_ERROR',
  FETCH_FACILITY_SUCCESS: 'containers/Facility/FETCH_FACILITY_SUCCESS',
  UPDATE_RESERVED_UNIT: 'containers/Facility/UPDATE_RESERVED_UNIT',
}

export const fetchFacility = createAction(constants.FETCH_FACILITY)
export const fetchFacilityError = createAction(constants.FETCH_FACILITY_ERROR)
export const fetchFacilitySuccess = createAction(constants.FETCH_FACILITY_SUCCESS)

const initialState = fromJS({
  data: null,
  error: null,
  loading: false,
  loaded: false,
});

export default handleActions({
  [fetchFacility]: (state) => state
    .set('loading', true)
    .set('loaded', false)
    .set('error', null),

  [fetchFacilitySuccess]: (state, action) => state
    .set('data', fromJS(action.payload))
    .set('loading', false)
    .set('loaded', true),

  [fetchFacilityError]: (state, action) => state
    .set('error', action.payload)
    .set('data', null)
    .set('loading', false),

}, initialState)
