import React from 'react';
import { Grid, withStyles } from 'material-ui';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  logo: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
  },
});

const AppLogo = ({ classes, size }) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    spacing={0}
  >
    <Grid item>
      <div className={`${classes.logo} fade-pulse`} style={{ height: size, width: size }} />
    </Grid>
  </Grid>
);
AppLogo.defaultProps = {
  size: 50,
};
AppLogo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  size: PropTypes.number,
};

export default withStyles(styles)(AppLogo);
