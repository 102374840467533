import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchFacilities() {
  return yield call(
    fetchData,
    '/api/customer/v1/facilities',
    {
      method: 'GET',
    },
  );
}

export function* fetchFacility(facilityId) {
  return yield call(
    fetchData,
    `/api/customer/v1/facilities/${facilityId}`,
    {
      method: 'GET',
    },
  );
}

export function* fetchFacilityProtected(facilityId) {
  return yield call(
    fetchData,
    `/api/customer/v1/account/facilities/${facilityId}`,
    {
      method: 'GET',
    },
  );
}
