export const EARTH_RADIUS = 6371e3;

export const degreesToRadians = (value) => value * (Math.PI / 180);
export const radiansToDegreees = (value) => value * (180 / Math.PI);

// Calculate distance (in meters) between 2 points using Great Circle algorithm
export const getDistance = (latLon1, latLon2) => {
  const xDist = degreesToRadians(latLon2.lng - latLon1.lng);
  const yDist = degreesToRadians(latLon2.lat - latLon1.lat);

  const a = (Math.sin(yDist / 2) ** 2)
    + (Math.cos(degreesToRadians(latLon1.lat)) * Math.cos(degreesToRadians(latLon2.lat))
    * (Math.sin(xDist / 2) ** 2));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return c * EARTH_RADIUS;
};
