import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
} from 'material-ui';
import { Checkbox } from 'redux-form-material-ui';
import { result } from 'lodash';
import { UsaStates } from 'usa-states';

import SelectField from '../../components/Form/selectField';
import GeneralForm from '../../components/Form';
import { createAddressThunk, updateAddressThunk } from './reducer';
import { getAddress, getAddresses } from '../Auth/selectors';
import { zipMask } from '../../constants';
import {
  required as requiredValidation,
  zipCode as zipCodeValidation,
} from '../../utils/validations';

const { states } = new UsaStates();
const fields = [{
  name: 'street',
  label: 'Address 1',
  required: true,
  validate: [requiredValidation],
}, {
  name: 'street_2',
  label: 'Address 2',
}, {
  name: 'city',
  label: 'City',
  required: true,
  validate: [requiredValidation],
}, {
  custom: (
    <FormControl margin="normal" key="state">
      <InputLabel className="required" htmlFor="state" required shrink>State</InputLabel>
      <Field
        name="state"
        component={SelectField}
        native
        validate={requiredValidation}
      >
        <option />
        {
          states.map(item => (
            <option key={item.abbreviation} value={item.abbreviation}>{item.name}</option>
          ))
        }
      </Field>
    </FormControl>
  ),
  name: 'state',
}, {
  name: 'zip',
  label: 'Zip',
  required: true,
  mask: zipMask,
  validate: [requiredValidation, zipCodeValidation],
}, {
  custom: (
    <FormControl margin="normal" key="label">
      <InputLabel className="required" htmlFor="label" required shrink>Type</InputLabel>
      <Field
        name="label"
        component={SelectField}
        validate={requiredValidation}
      >
        <MenuItem value="home">Home</MenuItem>
        <MenuItem value="work">Work</MenuItem>
      </Field>
    </FormControl>
  ),
  name: 'label',
}, {
  custom: (
    <FormControlLabel
      control={
        <Field
          name="default"
          component={Checkbox}
        />
      }
      label="Primary"
      key="default"
    />
  ),
  name: 'default',
}];

const protectedFields = fields.map((field, index) => {
  if (index < fields.length - 1) return field;
  return {
    custom: (
      <FormControlLabel
        control={
          <Field
            name="default"
            component={Checkbox}
          />
        }
        disabled
        label="Primary"
        key="default"
      />
    ),
    name: 'default',
  }
});

const mapStateToProps = (state, ownProps) => {
  /* Default to primary if no current address exists */
  let initValues;
  const addresses = getAddresses(state);
  let addressId = parseInt(ownProps.match.params.itemId, 10);
  if (Number.isNaN(addressId)) addressId = ownProps.match.params.itemId;
  if (!addresses || addresses.size === 0) initValues = { default: true, label: 'home' };
  /* Get Id of item, Try to convert Immutable to JS object or return null */
  else initValues = result(getAddress(state, addressId), 'toJS', null);

  // Disallow unchecking default, as this will allow them to delete the record.
  const displayFields = initValues && initValues.default ? protectedFields : fields;

  return ({
    fields: displayFields,
    initialValues: initValues,
    shrinkLabels: true,
  });
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators({
    create: createAddressThunk,
    update: updateAddressThunk,
    cancel: ownProps.onCancel,
  }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'addressForm',
})(GeneralForm)));

