import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Button,
  Popover,
  Tabs,
  Tab,
  Hidden,
  Avatar,
} from 'material-ui';
import {
  AccountCircle as AccountCircleIcon,
  AttachMoney,
  Settings as SettingsIcon,
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  ExpandMore,
  ExpandLess,
  Note,
  CreditCard,
  History,
  LiveHelp,
  Store,
  Home,
} from 'material-ui-icons';
import { withRouter } from 'react-router-dom';

import { getParentPath, routePropTypes } from '../../utils/routes';
import { logout } from '../../containers/Auth/reducer';
import {
  getLoggedIn,
  getMagicKey,
  getUserInfo,
} from '../../containers/Auth/selectors';
import {
  setDrawerOpen,
  setMapModalOpen,
  setReturnUrl,
} from '../../containers/AppWrapper/reducer';

const AccountIcon = styled(AccountCircleIcon)`
  margin-right: 10px;
`;

const styles = (theme) => ({
  appBar: {
    height: 48,
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
    position: 'relative',
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  flex: {
    flex: 1,
  },
  accountButton: {
    whiteSpace: 'nowrap',
  },
  homeButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabs: {
    flex: 1,
  },
  tabLabelIcon: {
    height: '48px',
  },
  tabWrapperIcon: {
    flexDirection: 'row-reverse',
  },
  tabLabelContainerIcon: {
    paddingRight: 0,
  },
  telephoneButton: {
    fontSize: '18px',
    fontWeight: 700,
    marginRight: '0.5em',

    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  title: {
    textDecoration: 'none',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  toolbarGrid: {
    display: 'grid',
    'grid-template-columns': 'auto 1fr auto auto',
  },
  newUnit: {
    width: 36,
    height: 36,
    marginRight: 8,
    marginLeft: -5,
  },
});

const TAB_CATEGORIES = ['/billing', '/contact-us'];

const menuOptions = [
  {
    text: 'My Account',
    icon: <AccountCircleIcon />,
    action: '/account',
  },
  {
    text: 'Reset Password',
    icon: <SettingsIcon />,
    action: '/account/settings',
  },
];

const billingMenuOptions = [
  {
    text: 'Current Bill',
    icon: <Note />,
    action: '/billing',
  },
  {
    text: 'Payment Methods',
    icon: <CreditCard />,
    action: '/billing/paymentMethods',
  },
  {
    text: 'Billing History',
    icon: <History />,
    action: '/billing/billingHistory',
  },
];

const contactUsOptions = [
  {
    text: 'Ask a Question',
    icon: <LiveHelp />,
    action: '/contact-us',
  },
  {
    text: 'Sell Your Facility',
    icon: <Store />,
    action: '/contact-us/sell-your-facility',
  },
];

/* const aboutUsOptions = [{
  text: 'About Us',
  url: 'https://www.reddotstorage.com/about-us',
}, {
  text: 'Careers',
  url: 'https://www.reddotstorage.com/careers',
}, {
  text: 'Investor Portal',
  url: 'https://services.sungarddx.com/LogOn/2218879/',
}]; */

class MenuAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountMenuAnchor: null,
      billingMenuAnchor: null,
      billingMenuAnchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      contactUsMenuAnchor: null,
      selected: getParentPath(props.path),
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ selected: getParentPath(props.path) });
  }

  handleAccountMenu = (event) => {
    if (!this.props.loggedIn) {
      if (this.props.path !== '/') {
        this.props.actions.setReturnUrl(true);
        this.props.history.push('/');
      }
      return;
    }

    this.setState({
      accountMenuAnchor: event.currentTarget,
      billingMenuAnchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    });
  };

  handleClose = () => {
    this.setState({
      accountMenuAnchor: null,
      billingMenuAnchor: null,
      billingMenuAnchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  handleChange = (event, selected) => {
    if (selected && !TAB_CATEGORIES.includes(selected)) {
      this.props.history.push(selected);
    }
  };

  handleBillingMenu = (event) => {
    this.setState({
      billingMenuAnchor: this.state.billingMenuAnchor
        ? null
        : event.currentTarget,
    });
  };

  handleBillingClose = () => {
    this.setState({ billingMenuAnchor: null });
  };

  handleContactUsMenu = (event) => {
    this.setState({
      contactUsMenuAnchor: this.state.contactUsMenuAnchor
        ? null
        : event.currentTarget,
    });
  };

  handleContactUsClose = () => {
    this.setState({ contactUsMenuAnchor: null });
  };

  countActiveUnits = (facility) => (
    facility.units.filter((u) => !u.moved_out).length
  )

  validTab = (path) =>
    [
      '/account',
      '/units',
      '/billing',
      '/specials',
      '/protection-plan',
      '/faq-tips',
      '/contact-us',
    ].indexOf(path) > -1;

  render() {
    const {
      appLocked, classes, loggedIn, magicKey, path, userInfo,
    } =
      this.props;
    const {
      accountMenuAnchor, billingMenuAnchor, contactUsMenuAnchor,
    } =
      this.state;
    const accountMenuOpen = Boolean(accountMenuAnchor);
    const billingMenuOpen = Boolean(billingMenuAnchor);
    const contactUsMenuOpen = Boolean(contactUsMenuAnchor);

    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbarGrid}>
          <IconButton alt="Home" href={appLocked ? path : '/home'}>
            <Avatar className={classes.homeButton}><Home /></Avatar>
          </IconButton>
          {magicKey ? (
            <span />
          ) : (
            <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => this.props.actions.setDrawerOpen(true)}
                className={classes.navIconHide}
              >
                <MenuIcon />
              </IconButton>
              <Hidden smDown>
                <Tabs
                  value={
                    this.validTab(this.state.selected)
                      ? this.state.selected
                      : false
                  }
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                  scrollable
                >
                  <Tab
                    disabled={appLocked || !loggedIn}
                    label="My Account"
                    value="/account"
                  />
                  <Tab
                    classes={{
                      rootLabelIcon: classes.tabLabelIcon,
                      wrapper: classes.tabWrapperIcon,
                      labelContainer: classes.tabLabelContainerIcon,
                    }}
                    disabled={appLocked || !loggedIn}
                    label="Billing"
                    icon={billingMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    onClick={this.handleBillingMenu}
                    value="/billing"
                  />
                  <Tab disabled={appLocked} label="My Units" value="/units" />
                  <Tab
                    disabled={appLocked}
                    label="Specials"
                    value="/specials"
                  />
                  <Tab
                    disabled={appLocked}
                    label="Protection Plan"
                    value="/protection-plan"
                  />
                  <Tab
                    disabled={appLocked}
                    label="FAQ / Tips"
                    value="/faq-tips"
                  />
                  <Tab
                    classes={{
                      rootLabelIcon: classes.tabLabelIcon,
                      wrapper: classes.tabWrapperIcon,
                      labelContainer: classes.tabLabelContainerIcon,
                    }}
                    disabled={appLocked}
                    label="Contact Us"
                    icon={contactUsMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    onClick={this.handleContactUsMenu}
                    value="/contact-us"
                  />
                </Tabs>
              </Hidden>
            </React.Fragment>
          )}
          <Button
            className={classes.telephoneButton}
            color="inherit"
            href="tel:9705106860"
            size="small"
          >
            970-510-6860
          </Button>
          <Button
            aria-owns={accountMenuOpen ? 'menu-appbar' : null}
            aria-haspopup="true"
            classes={{ label: classes.accountButton }}
            color="secondary"
            onClick={this.handleAccountMenu}
            raised
          >
            <AccountIcon />
            {loggedIn
              ? `${userInfo.get('first_name')} ${userInfo.get('last_name')}`
              : 'Login'}
          </Button>
          <Popover
            open={contactUsMenuOpen}
            anchorEl={contactUsMenuAnchor}
            onClose={this.handleContactUsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {contactUsOptions.map((opt) => {
              const isSelected = opt.action === path;
              return (
                <MenuItem
                  disabled={appLocked}
                  className={`drawer-menu-item-${isSelected}`}
                  selected={isSelected}
                  onClick={() => {
                    this.props.history.push(opt.action);
                    this.handleContactUsClose();
                  }}
                >
                  <ListItemIcon>{opt.icon}</ListItemIcon>
                  <ListItemText inset primary={opt.text} />
                </MenuItem>
              );
            })}
          </Popover>
          <Popover
            open={billingMenuOpen}
            anchorEl={billingMenuAnchor}
            onClose={this.handleBillingClose}
            anchorOrigin={this.state.billingMenuAnchorOrigin}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {billingMenuOptions.map((opt) => {
              const isSelected = opt.action === path;
              return (
                <MenuItem
                  disabled={appLocked}
                  className={`drawer-menu-item-${isSelected}`}
                  selected={isSelected}
                  onClick={() => {
                    this.props.history.push(opt.action);
                    this.handleClose();
                  }}
                >
                  <ListItemIcon>{opt.icon}</ListItemIcon>
                  <ListItemText inset primary={opt.text} />
                </MenuItem>
              );
            })}
          </Popover>
          <Popover
            open={accountMenuOpen}
            anchorEl={accountMenuAnchor}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {menuOptions.map((opt) => {
              const isSelected = opt.action === path;
              return (
                <MenuItem
                  disabled={appLocked}
                  className={`drawer-menu-item-${isSelected}`}
                  selected={isSelected}
                  onClick={() => {
                    this.props.history.push(opt.action);
                    this.handleClose();
                  }}
                >
                  <ListItemIcon>{opt.icon}</ListItemIcon>
                  <ListItemText inset primary={opt.text} />
                </MenuItem>
              );
            })}
            <MenuItem
              disabled={appLocked}
              className="drawer-menu-item-false"
              onClick={this.handleBillingMenu}
            >
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText inset primary="Billing" />
              {billingMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.props.actions.setReturnUrl(null);
                this.props.actions.logout();
                this.handleClose();
              }}
              className="drawer-menu-item-false"
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText inset primary="Log out" />
            </MenuItem>
          </Popover>
        </Toolbar>
      </AppBar>
    );
  }
}

MenuAppBar.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  appLocked: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  loggedIn: PropTypes.bool,
  magicKey: PropTypes.string,
  path: PropTypes.string.isRequired,
  userInfo: PropTypes.instanceOf(Map).isRequired,
  ...routePropTypes,
};

MenuAppBar.defaultProps = {
  appLocked: false,
  loggedIn: false,
  magicKey: null,
};

const mapStateToProps = (state, ownProps) => ({
  loggedIn: getLoggedIn(state),
  magicKey: getMagicKey(state),
  path: ownProps.location.pathname,
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logout,
      setDrawerOpen,
      setMapModalOpen,
      setReturnUrl,
    },
    dispatch,
  ),
});

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuAppBar)));

