import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
} from 'material-ui';
import { RadioGroup, TextField } from 'redux-form-material-ui';

import GeneralForm from '../../components/Form';
import { sendMessageThunk } from './reducer';
import {
  required as requiredValidation,
  email as emailValidation,
  minLength,
} from '../../utils/validations';
import MaskedTextField from '../../components/Form/maskedTextField';
import Captcha from '../../components/Form/Captcha';
import { phoneMask, phoneFormat } from '../../constants';

const phoneValidation = minLength(11, 'Phone Number must contain at least 10 digits', /\D/g);

const initialValues = {
  'p-g5-resident': 'Yes',
};

const fields = [{
  fullWidth: true,
  label: 'Name',
  name: 'name',
  required: true,
  validate: requiredValidation,
}, {
  custom: (
    <FormControl fullWidth key="p-g5-resident" margin="dense">
      <InputLabel htmlFor="p-g5-resident" shrink>Are you a current customer?</InputLabel>
      <Field
        className="horizontal"
        component={RadioGroup}
        name="p-g5-resident"
        validate={requiredValidation}
      >
        <FormControlLabel control={<Radio />} label="Yes" name="p-g5-resident" value="Yes" />
        <FormControlLabel control={<Radio />} label="No" name="p-g5-resident" value="No" />
      </Field>
    </FormControl>
  ),
}, {
  custom: (
    <Field
      component={TextField}
      fullWidth
      InputLabelProps={{ shrink: true }}
      key="p-g5-resident-2"
      label="If not, how did you hear about us?"
      margin="dense"
      name="p-g5-resident-2"
    />
  ),
  name: 'p-g5-resident-2',
}, {
  custom: (
    <Field
      component={MaskedTextField}
      fullWidth
      InputLabelProps={{ shrink: true }}
      key="tel"
      label="Phone Number"
      margin="dense"
      name="tel"
      props={{
        mask: phoneMask,
        pipe: phoneFormat,
      }}
      validate={phoneValidation}
    />
  ),
}, {
  fullWidth: true,
  label: 'Email',
  name: 'email',
  required: true,
  validate: [requiredValidation, emailValidation],
}, {
  fullWidth: true,
  inputProps: { inputProps: { maxLength: 250 } },
  label: 'Message (250 character limit)',
  multiline: true,
  name: 'e-content',
  rows: 5,
}, {
  custom: (
    <Field
      className="marginDense"
      component={Captcha}
      key="g-recaptcha-response"
      name="g-recaptcha-response"
      sitekey="6LetBq8cAAAAAB8efZPz-OkOrgWk6BXhj9ZARM03"
      validate={requiredValidation}
    />
  ),
}];

// Decorate with redux-form
const contactForm = reduxForm({ form: 'helpContactForm' })(GeneralForm);
const selector = formValueSelector('helpContactForm');

const mapStateToProps = (state) => {
  const isCustomer = selector(state, 'p-g5-resident') || initialValues['p-g5-resident'];
  return {
    buttonTitle: 'Send',
    fields,
    initialValues,
    isCreate: true,
    isVisible: {
      'p-g5-resident-2': isCustomer === 'No',
    },
    margin: 'dense',
    shrinkLabels: true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    create: sendMessageThunk,
  }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(contactForm));

