import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
// Prefered way to import "createBrowserHistory"
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';


import createGlobalReducer from './global-reducer';
import globalSagas from './global-sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  // Middleware for intercepting and dispatching navigation actions
  routerMiddleware(history),
  sagaMiddleware,
  thunk,
];

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createGlobalReducer(),
  /* preloadedState, */
  composeEnhancers(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(globalSagas);

export default store;
