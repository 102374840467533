import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from 'material-ui';
import UnitImage from './unitImage';
import colors from '../../styles/colors';
import './styles.css';

const UNIT_STATUSES = {
  occupied: 'Leased',
};

const styles = () => ({
  commandIcon: {
    marginRight: '8px',
    color: colors.red,
  },
  commandList: {
    width: '103px',
  },
  commandText: {
    padding: 0,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '8px',
    height: '100%',
  },
  cardContent: {
    alignSelf: 'center',
  },
});

const Unit = ({
  children,
  classes,
  className,
  commands,
  unit,
}) => {
  const isOverlocked = unit.system_status === 'overlocked';
  const statusText = unit.system_status && (UNIT_STATUSES[unit.system_status] || `${unit.system_status.charAt(0).toUpperCase()}${unit.system_status.substr(1)}`);
  const status = statusText ? (
    <Grid item>
      <Typography type="caption">{statusText}</Typography>
    </Grid>
  ) : null;

  const tooltipContent = useMemo(() => (
    <Typography color="primary">
      Your unit has been overlocked. To regain access to your possessions, please pay your bill.
    </Typography>
  ), []);

  const commandList = commands.length > 0 ? (
    <List className={classes.commandList} disablePadding>
      {
        commands.map((cmd) => (
          <ListItem button disableGutters key={cmd.id} onClick={() => cmd.callback(unit)}>
            {
              cmd.icon
                ? <ListItemIcon className={classes.commandIcon}>{cmd.icon}</ListItemIcon>
                : null
            }
            {
              cmd.label
                ? <ListItemText className={classes.commandText} secondary={cmd.label} />
                : null
            }
          </ListItem>
        ))
      }
    </List>
  ) : null;

  return (
    <Grid className={classes.container}>
      <Grid container className={className ? `${className} ${classes.cardContent}` : classes.cardContent} direction="column" spacing={8}>
        <Tooltip
          disableTriggerTouch
          title={isOverlocked ? tooltipContent : ''}
        >
          <Grid item className="unit-image">
            <UnitImage locked={isOverlocked} size="lg" unitType={unit.unit_type} />
          </Grid>
        </Tooltip>
        <Grid item>
          <Grid container className={className} direction="column" spacing={0}>
            <Grid item>
              <Typography type="subheading">Unit {unit.unit_number}</Typography>
            </Grid>
            {children}
            <Grid item>
              <Typography type="caption">{unit.unit_type_name || unit.unit_type}</Typography>
            </Grid>
            <Grid item>
              <Typography type="caption">{unit.width}&#180; x {unit.length}&#180;</Typography>
            </Grid>
            {status}
          </Grid>
        </Grid>
      </Grid>
      {commandList}
    </Grid>
  );
};

Unit.defaultProps = {
  className: undefined,
  commands: [],
};

Unit.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  unit: PropTypes.shape({
    id: PropTypes.string,
    facility_id: PropTypes.string,
    lease_envelope_id: PropTypes.string,
    length: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    move_out_date: PropTypes.string,
    system_status: PropTypes.string,
    unit_number: PropTypes.string,
    unit_type: PropTypes.string,
    unit_type_name: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  commands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.node,
    callback: PropTypes.func,
  })),
};

export default withStyles(styles)(Unit);
